import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'typedi';
import { UploadSpreadsheetDropzone } from '@app/components/atm.upload-spreadsheet-dropzone';
import { SPREADSHEET_TEMPLATE_GCLOUD_URL } from '@app/configure-app';
import { useUploadSpreadsheet } from '@app/domain/spreadsheet';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { BodySecondary, H2, H4, LinkSmall } from '@atomic/atm.typography';
import { FlashMessage } from '@atomic/mol.flash-message';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { uploadSpreadsheetCardStrings as strings } from './upload-spreadsheet-card.strings';
export var UploadSpreadsheetCard = function () {
    var _a = React.useState(), selectedFile = _a[0], setSelectedFile = _a[1];
    var _b = useState(), error = _b[0], setError = _b[1];
    var navigate = useNavigate();
    var _c = useUploadSpreadsheet({
        onCompleted: function () {
            navigate(HomeRoutes.baseRoot);
        },
        onError: function (err) {
            setError(err);
        },
    }), uploadSpreadsheet = _c.uploadSpreadsheet, loading = _c.loading;
    useEffect(function () {
        setError(undefined);
    }, [selectedFile]);
    var spreadsheetTemplateUrl = Container.get(SPREADSHEET_TEMPLATE_GCLOUD_URL);
    var handleUpload = function () { return uploadSpreadsheet({ data: selectedFile }); };
    return (React.createElement(Card, { allHeight: true, border: true },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(H2, { center: true, noMargin: true }, strings.title),
                React.createElement(Separator, { size: Spacing.Small }),
                React.createElement(H4, { noMargin: true }, strings.subtitle),
                React.createElement(BodySecondary, null,
                    strings.description.base,
                    React.createElement(LinkSmall, { href: spreadsheetTemplateUrl }, strings.description.link),
                    strings.description.exclamationMark),
                React.createElement(Separator, { size: Spacing.Small }),
                React.createElement(UploadSpreadsheetDropzone, { onFileUpload: setSelectedFile, uploadedFile: selectedFile, hasError: !!error, loading: loading }),
                React.createElement(Separator, { size: Spacing.Small }),
                selectedFile && !!error && (React.createElement(FlashMessage, { type: 'alert', dismissible: true, mb: true }, error.message)),
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { hAlign: 'center' },
                        React.createElement(Hbox.Separator, null),
                        React.createElement(Button, { variant: 'primary', disabled: !selectedFile || !!error, onClick: handleUpload, loading: loading }, strings.button),
                        React.createElement(Hbox.Separator, null)))))));
};
