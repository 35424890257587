var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Breakpoint, Color, FontFamily, FontWeight } from '@atomic/obj.constants';
var BREAKPOINTS_PERCENT = {
    fourTwelfths: 33.33,
    threeTwelfths: 25,
    twoTwelfths: 16.67,
};
export var NavbarWrapper = styled.aside(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 0 ", " ", " 0;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  @media all and (min-width: ", "px) {\n    position: fixed;\n  }\n  @media all and (min-width: ", "px) and (max-width: ", "px) {\n    width: ", "%;\n  }\n\n  @media all and (min-width: ", "px) and (max-width: ", "px) {\n    width: ", "%;\n  }\n\n  @media all and (min-width: ", "px) {\n    width: ", "%;\n  }\n"], ["\n  background-color: ", ";\n  border-radius: 0 ", " ", " 0;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  @media all and (min-width: ", "px) {\n    position: fixed;\n  }\n  @media all and (min-width: ", "px) and (max-width: ", "px) {\n    width: ", "%;\n  }\n\n  @media all and (min-width: ", "px) and (max-width: ", "px) {\n    width: ", "%;\n  }\n\n  @media all and (min-width: ", "px) {\n    width: ", "%;\n  }\n"])), Color.PrimaryDark, Border.RadiusLarge, Border.RadiusLarge, Breakpoint.md, Breakpoint.md, Breakpoint.lg, BREAKPOINTS_PERCENT.fourTwelfths, Breakpoint.lg, Breakpoint.xxl, BREAKPOINTS_PERCENT.threeTwelfths, Breakpoint.xxl, BREAKPOINTS_PERCENT.twoTwelfths);
export var AccountWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  user-select: none;\n\n  & *:hover {\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  user-select: none;\n\n  & *:hover {\n    cursor: pointer;\n  }\n"])));
var PROFILE_PICTURE_SIZE = 30;
export var ProfilePicture = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", "px;\n  height: ", "px;\n  border-radius: ", ";\n  background-color: ", ";\n  color: ", ";\n  line-height: ", "px;\n  text-align: center;\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  width: ", "px;\n  height: ", "px;\n  border-radius: ", ";\n  background-color: ", ";\n  color: ", ";\n  line-height: ", "px;\n  text-align: center;\n  font-family: ", ";\n  font-weight: ", ";\n"])), PROFILE_PICTURE_SIZE, PROFILE_PICTURE_SIZE, Border.Circular, Color.White, Color.PrimaryDark, PROFILE_PICTURE_SIZE, FontFamily.Primary, FontWeight.Bold);
var templateObject_1, templateObject_2, templateObject_3;
