var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Dropzone from 'react-dropzone';
import { UploadFileIcon } from '@assets/icons/upload-file.icon';
import { UploadInvalidIcon } from '@assets/icons/upload-invalid.icon';
import { UploadedFileIcon } from '@assets/icons/uploaded-file.icon';
import { BodySecondary } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { uploadSpreadsheetDropzoneStrings as strings } from './upload-spreadsheet-dropzone.strings';
import { DropzoneWrapper } from './upload-spreadsheet-dropzone.style';
var ACCEPTED_FILE_TYPES = {
    xls: { mimeType: 'application/vnd.ms-excel', extension: '.xls' },
    xlsx: { mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', extension: '.xlsx' },
};
export var UploadSpreadsheetDropzone = function (_a) {
    var _b;
    var onFileUpload = _a.onFileUpload, uploadedFile = _a.uploadedFile, large = _a.large, hasError = _a.hasError, loading = _a.loading;
    var _c = React.useState(false), isDragging = _c[0], setIsDragging = _c[1];
    return (React.createElement(Dropzone, { maxFiles: 1, onDragEnter: function () { return setIsDragging(true); }, onDragLeave: function () { return setIsDragging(false); }, onDropAccepted: function (files) { return onFileUpload(files[0]); }, disabled: loading, accept: (_b = {},
            _b[ACCEPTED_FILE_TYPES.xls.mimeType] = [ACCEPTED_FILE_TYPES.xls.extension],
            _b[ACCEPTED_FILE_TYPES.xlsx.mimeType] = [ACCEPTED_FILE_TYPES.xlsx.extension],
            _b) }, function (_a) {
        var getInputProps = _a.getInputProps, getRootProps = _a.getRootProps;
        return (React.createElement(DropzoneWrapper, __assign({}, getRootProps(), { isDragging: isDragging, large: large }),
            React.createElement(Hbox.Item, { hAlign: 'center' },
                React.createElement(Separator, { size: Spacing.XSmall }),
                hasError ? React.createElement(UploadInvalidIcon, null) : !!uploadedFile ? React.createElement(UploadedFileIcon, null) : React.createElement(UploadFileIcon, null),
                React.createElement(Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
                    React.createElement(Hbox.Item, { noGrow: true }, hasError && !large ? (React.createElement(BodySecondary, { center: true, color: 'AlertDark', strong: true }, strings.uploadError)) : !!uploadedFile && !large ? (React.createElement(BodySecondary, { center: true, color: 'Success', strong: true },
                        strings.selectedFile,
                        mapFilename(uploadedFile.name))) : (React.createElement(BodySecondary, { center: true }, strings.description))),
                    React.createElement(Hbox.Separator, { size: Spacing.XSmall })),
                React.createElement(Separator, { size: Spacing.XSmall })),
            React.createElement("input", __assign({}, getInputProps()))));
    }));
};
var MAX_NUMBER_OF_CHARACTERS_DISPLAYED = 20;
var mapFilename = function (filename) {
    if (filename.length >= MAX_NUMBER_OF_CHARACTERS_DISPLAYED) {
        var mappedFilename = filename.slice(0, MAX_NUMBER_OF_CHARACTERS_DISPLAYED).concat('...');
        return mappedFilename;
    }
    return filename;
};
