var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Select from 'react-select';
import styled from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Border, Color, ControlSizes, ZIndex } from '@atomic/obj.constants';
import { fieldCss } from '@atomic/obj.form/field.component.styled';
export var BaseSelectStyled = styled(Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  appearance: none;\n  border: 0;\n  min-width: 100px;\n  outline: none;\n  ", ";\n"], ["\n  appearance: none;\n  border: 0;\n  min-width: 100px;\n  outline: none;\n  ", ";\n"])), fieldCss);
export var SelectIconStyled = styled(FaIcon.CaretDown)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  pointer-events: none;\n"], ["\n  color: ", ";\n  pointer-events: none;\n"])), function (props) { return (props.$disabled ? Color.Gray : Color.Primary); });
export var SelectStyle = function (controlSize) { return ({
    container: function (baseStyles) { return (__assign(__assign({}, baseStyles), { paddingLeft: 0, backgroundColor: 'transparent' })); },
    control: function (baseStyles, _a) {
        var isDisabled = _a.isDisabled, isFocused = _a.isFocused;
        return (__assign(__assign({}, baseStyles), { height: '100%', minHeight: CONTROLLED_SELECT_FIELD_HEIGHT, borderRadius: Border.Radius, backgroundColor: isDisabled ? Color.GrayXLight : Color.White, color: isDisabled ? Color.Gray : undefined, width: controlSize !== null && controlSize !== void 0 ? controlSize : ControlSizes.Medium, border: isFocused ? "".concat(Border.Width, " solid ").concat(Color.Primary) : "".concat(Border.Width, " solid ").concat(Color.GrayLight), boxShadow: isFocused && 'none', borderEndEndRadius: Border.Radius, paddingRight: 8, ':hover': __assign(__assign({}, baseStyles[':hover']), { borderColor: Color.Gray }) }));
    },
    indicatorSeparator: function () { return ({}); },
    option: function (baseStyles, _a) {
        var isFocused = _a.isFocused;
        return (__assign(__assign({}, baseStyles), { backgroundColor: isFocused ? Color.CallToActionXLight : undefined, color: Color.GrayXDark, ':active': __assign(__assign({}, baseStyles[':active']), { backgroundColor: Color.CallToActionLight }) }));
    },
    menu: function (baseStyles) { return (__assign(__assign({}, baseStyles), { zIndex: ZIndex.top })); },
}); };
var CONTROLLED_SELECT_FIELD_HEIGHT = '48px';
export var LoadingIndicatorWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  left: 50%;\n  transform: translate(-50%, 0%);\n"], ["\n  position: absolute;\n  left: 50%;\n  transform: translate(-50%, 0%);\n"])));
var templateObject_1, templateObject_2, templateObject_3;
