var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { ButtonStyled } from '@atomic/atm.button/button.component.style';
import { Separator } from '@atomic/obj.box';
import { Border, Color, FontFamily, FontSize, FontWeight, LineHeight, Shadow, Spacing } from '@atomic/obj.constants';
export var TableStyled = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: ", ";\n  border: none;\n  border-collapse: separate;\n  overflow: hidden;\n  width: 100%;\n  background-color: ", ";\n  box-shadow: ", ";\n"], ["\n  border-radius: ", ";\n  border: none;\n  border-collapse: separate;\n  overflow: hidden;\n  width: 100%;\n  background-color: ", ";\n  box-shadow: ", ";\n"])), Border.Radius, Color.White, Shadow.Medium);
export var TableHeader = styled.thead(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var TABLE_CELL_SIZE = {
    width: 150,
    height: 32,
};
export var TableRowStyled = styled.tr(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", " ", ";\n  display: flex;\n"], ["\n  background-color: ", ";\n  padding: ", " ", ";\n  display: flex;\n"])), function (props) { return (props.isGrey ? Color.GrayXXLight : Color.White); }, Spacing.XXSmall, Spacing.XSmall);
export var TableHeaderCell = styled.th(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  height: ", "px;\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n"], ["\n  flex: 1;\n  height: ", "px;\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n"])), TABLE_CELL_SIZE.height, function (props) { return (props.contentStart ? 'flex-start' : 'center'); });
export var TableHeaderTypography = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: ", "px;\n  font-weight: ", ";\n  line-height: ", ";\n  color: ", ";\n"], ["\n  font-family: ", ";\n  font-size: ", "px;\n  font-weight: ", ";\n  line-height: ", ";\n  color: ", ";\n"])), FontFamily.Secondary, FontSize.XSmall, FontWeight.Medium, LineHeight.Small, Color.GrayXDark);
export var TableSeparator = styled(Separator)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-bottom: ", " ", " solid;\n"], ["\n  border-bottom: ", " ", " solid;\n"])), Color.Gray, Border.Width);
export var TableBody = styled.tbody(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: block;\n  max-height: 500px;\n  overflow-y: auto;\n  overflow-x: hidden;\n"], ["\n  display: block;\n  max-height: 500px;\n  overflow-y: auto;\n  overflow-x: hidden;\n"])));
export var TableBodyCell = styled.td(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n  flex: 1;\n  height: ", "px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n  flex: 1;\n  height: ", "px;\n"])), function (props) { return (props.contentStart ? 'flex-start' : 'center'); }, TABLE_CELL_SIZE.height);
export var TableBodyTypography = styled.label(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: ", "px;\n  font-weight: ", ";\n  line-height: ", ";\n  color: ", ";\n"], ["\n  font-family: ", ";\n  font-size: ", "px;\n  font-weight: ", ";\n  line-height: ", ";\n  color: ", ";\n"])), FontFamily.Primary, FontSize.XXSmall, FontWeight.Medium, LineHeight.Small, Color.GrayDark);
export var TableButton = styled(ButtonStyled)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: ", ";\n  ", ";\n"], ["\n  font-size: ", ";\n  ", ";\n"])), FontSize.XSmall, function (props) { return "color:".concat(!props.alert && Color.PrimaryXDark); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
