export var cultureFiltered = function (data, selectedConty) { var _a, _b, _c; return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.bigActInitialSelection) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.idCounty === selectedConty; })) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.cultureOptions; };
export var mapCultureOptions = function (cultureOptions) {
    return ((cultureOptions === null || cultureOptions === void 0 ? void 0 : cultureOptions.map(function (item) {
        return { label: item.name, value: item.id };
    })) || []);
};
export var countyMapped = function (data) {
    var _a;
    return (_a = data === null || data === void 0 ? void 0 : data.bigActInitialSelection) === null || _a === void 0 ? void 0 : _a.map(function (item) {
        return {
            label: item.nameCounty,
            value: item.idCounty,
        };
    });
};
export var getUniqueCyclesOptions = function (finalSelectionData) {
    var _a;
    var uniqueCyclesSet = new Set();
    var uniqueCyclesOptions = [];
    (_a = finalSelectionData === null || finalSelectionData === void 0 ? void 0 : finalSelectionData.bigActFinalSelection) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
        var cycle = item.cycle;
        if (!uniqueCyclesSet.has(cycle)) {
            uniqueCyclesSet.add(cycle);
            uniqueCyclesOptions.push({ label: "".concat(cycle), value: cycle });
        }
    });
    return uniqueCyclesOptions;
};
export var getClosestDateToToday = function (finalSelectionData) {
    var _a;
    var currentDate = new Date();
    var dateArray = (_a = finalSelectionData === null || finalSelectionData === void 0 ? void 0 : finalSelectionData.bigActFinalSelection) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new Date(item.sowingDate); });
    if (!dateArray || dateArray.length === 0) {
        return null;
    }
    var closestDate = dateArray.reduce(function (closest, current) {
        var closestDifference = Math.abs(currentDate.getTime() - closest.getTime());
        var currentDifference = Math.abs(currentDate.getTime() - current.getTime());
        return currentDifference < closestDifference ? current : closest;
    }, dateArray[0]);
    return closestDate;
};
export var unityFederativeList = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
];
