export var uploadSpreadsheetPagesStrings = {
    title: function (username) { return "Ol\u00E1, ".concat(username, "! \uD83D\uDC4B"); },
    subtitle: 'Importe os seus dados para alimentar suas consultas.',
    or: 'ou',
    inforcard: {
        base: 'Caso não tenha uma planilha com as coordenadas, ',
        link: 'entre em contato conosco!',
    },
    back: 'Voltar',
};
