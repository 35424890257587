import React from 'react';
import BigDataIcon1 from '@assets/icons/ag-dash1.svg';
import BigDataIcon3 from '@assets/icons/ag-dash2.svg';
import RightArrow from '@assets/icons/right-arrow.icon.svg';
import BigDataIcon2 from '@assets/img/big-data-icon-2.svg';
import { Card } from '@atomic/atm.card';
import { LazyLoadImage } from '@atomic/atm.lazy-load-image';
import { Body, H1, H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Shadow, Spacing } from '@atomic/obj.constants';
import { bigDataPlaceholderStrings as strings } from './big-data-placeholder.strings';
var RightArrowComponent = function () { return React.createElement(LazyLoadImage, { src: RightArrow }); };
export var BigDataPlaceholder = function () { return (React.createElement(Card, { center: true, shadow: Shadow.Medium, allHeight: true },
    React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(Separator, { size: Spacing.XLarge }),
            React.createElement(H2, { noMargin: true, center: true }, strings.placeholder.title))),
    React.createElement(Separator, { size: Spacing.Small }),
    React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(LazyLoadImage, { src: BigDataIcon1 }),
            React.createElement(Separator, { size: Spacing.XSmall }),
            React.createElement(H1, { noMargin: true, center: true }, strings.placeholder.one),
            React.createElement(Body, { center: true }, strings.placeholder.firstStep)),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
            React.createElement(RightArrowComponent, null)),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(LazyLoadImage, { src: BigDataIcon2 }),
            React.createElement(Separator, { size: Spacing.XSmall }),
            React.createElement(H1, { noMargin: true, center: true }, strings.placeholder.two),
            React.createElement(Body, { center: true }, strings.placeholder.secondStep)),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
            React.createElement(RightArrowComponent, null)),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(LazyLoadImage, { src: BigDataIcon3 }),
            React.createElement(Separator, { size: Spacing.XSmall }),
            React.createElement(H1, { noMargin: true, center: true }, strings.placeholder.three),
            React.createElement(Body, { center: true }, strings.placeholder.thirdStep),
            React.createElement(Separator, { size: Spacing.XLarge }))))); };
