export var bigDataPlaceholderStrings = {
    placeholder: {
        title: 'Comece a visualizar seus dados:',
        one: '1',
        firstStep: 'Selecione uma das bases de dados disponíveis.',
        two: '2',
        secondStep: 'Escolha uma de suas planilhas importadas na plataforma.',
        three: '3',
        thirdStep: 'Aplique a latitude/longitude para começar a ver os gráficos.',
    },
};
