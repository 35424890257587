import React from 'react';
import { DocsModal } from '@app/components/mol.docs-modal';
import { WelcomeModal } from '@app/components/mol.welcome-modal';
import { useDocs } from '@app/domain';
import PrivacyPolicyImg from '@assets/icons/privacy-policy.icon.svg';
import TermsOfUseImg from '@assets/icons/terms-of-use.icon.svg';
import VideoPlayerImg from '@assets/icons/video-player.icon.svg';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { Divider } from '@atomic/atm.divider';
import { LazyLoadImage } from '@atomic/atm.lazy-load-image';
import { useMatchMedia } from '@atomic/atm.match-media';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { useModalState } from '@atomic/obj.modal/modal.component.hook';
import { strings } from './quick-access-section.strings';
export var QuickAccessSection = function () {
    var _a, _b;
    var data = useDocs().data;
    var matchBreakpoint = useMatchMedia('lg').matchBreakpoint;
    var _c = useModalState(), openModal = _c.openModal, closeModal = _c.closeModal, opened = _c.opened;
    var _d = useModalState(), openTermsOfUse = _d.openModal, closeTermsOfUse = _d.closeModal, stateTermsOfUse = _d.opened;
    var _e = useModalState(), openPrivacyPolicy = _e.openModal, closePrivacyPolicy = _e.closeModal, statePrivacyPolicy = _e.opened;
    return (React.createElement(Hbox, { "$wrap": true },
        React.createElement(WelcomeModal, { opened: opened, onCloseModal: closeModal, small: !matchBreakpoint }),
        React.createElement(DocsModal, { onCloseModal: closeTermsOfUse, opened: stateTermsOfUse, title: strings.modalTitles.termsOfUse, docUrl: (_a = data === null || data === void 0 ? void 0 : data.docs) === null || _a === void 0 ? void 0 : _a.termsOfUse }),
        React.createElement(DocsModal, { onCloseModal: closePrivacyPolicy, opened: statePrivacyPolicy, title: strings.modalTitles.privacyPolice, docUrl: (_b = data === null || data === void 0 ? void 0 : data.docs) === null || _b === void 0 ? void 0 : _b.privacyPolicy }),
        React.createElement(Hbox.Item, null,
            React.createElement(Card, { allHeight: true },
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { hAlign: 'center' },
                        React.createElement(LazyLoadImage, { src: VideoPlayerImg }),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(H3, { center: true, noMargin: true }, strings.videoPlayer.title),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Divider, { small: true }),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Body, { center: true }, strings.videoPlayer.description),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Button, { variant: 'primary', onClick: openModal }, strings.videoPlayer.button)))),
            React.createElement(Separator, { size: Spacing.XSmall })),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, null,
            React.createElement(Card, { allHeight: true },
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { hAlign: 'center' },
                        React.createElement(LazyLoadImage, { src: TermsOfUseImg }),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(H3, { center: true, noMargin: true }, strings.termsOfUse.title),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Divider, { small: true }),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Body, { center: true }, strings.termsOfUse.description),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Button, { variant: 'primary', onClick: openTermsOfUse }, strings.termsOfUse.button)))),
            React.createElement(Separator, { size: Spacing.XSmall })),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, null,
            React.createElement(Card, { allHeight: true },
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { hAlign: 'center' },
                        React.createElement(LazyLoadImage, { src: PrivacyPolicyImg }),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(H3, { center: true, noMargin: true }, strings.privacyPolicy.title),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Divider, { small: true }),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Body, { center: true }, strings.privacyPolicy.description),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Button, { variant: 'primary', onClick: openPrivacyPolicy }, strings.privacyPolicy.button)))),
            React.createElement(Separator, { size: Spacing.XSmall }))));
};
