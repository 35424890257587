var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BigActRoutes } from '@app/modules/big-act/big-act.routes';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { SpreadsheetRoutes } from '@app/modules/spreadsheet/routes';
import { useUserStore } from '@app/stores';
import { NavbarAgDashNonSelectedIcon } from '@assets/icons/navbar-ag-dash-non-selected.icon';
import { NavbarAgDashSelected } from '@assets/icons/navbar-ag-dash-selected.icon';
import { NavbarBigActNonSelectedIcon } from '@assets/icons/navbar-big-act-non-selected.icon';
import { NavbarBigActSelectedIcon } from '@assets/icons/navbar-big-act-selected.icon';
import { NavbarBigDataNonSelectedIcon } from '@assets/icons/navbar-big-data-non-selected.icon';
import { NavbarBigDataSelected } from '@assets/icons/navbar-big-data-selected.icon';
import { NavbarSpreadsheetNonSelectedIcon } from '@assets/icons/navbar-spreadsheets-non-selected.icon';
import { NavbarSpreadsheetsSelected } from '@assets/icons/navbar-spreadsheets-selected.icon';
import { UserDropdownIcon } from '@assets/icons/user-dropdown.icon';
import IMBRAgroLogo from '@assets/img/imbr-agro-logo.png';
import { useMatchMedia } from '@atomic/atm.match-media';
import { BodySecondary, H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { navbarMenuStrings as strings } from './navbar-menu.strings';
import { AccountWrapper, NavbarWrapper, ProfilePicture } from './navbar-menu.style';
import { NavbarOption } from './navbar-option.component';
import { UserDropdown } from './user-dropdown.component';
import { UserDropdownWrapper } from './user-dropdown.styled';
var IMAGE_SIZE = {
    width: 140,
    height: 65,
};
var AppLogo = function () { return React.createElement("img", { width: IMAGE_SIZE.width, height: IMAGE_SIZE.height, src: IMBRAgroLogo }); };
var navbarOptionsData = [
    {
        name: strings.options.spreadsheets,
        to: SpreadsheetRoutes.spreadshhetAdmin,
        icon: { selected: React.createElement(NavbarSpreadsheetsSelected, null), nonSelected: React.createElement(NavbarSpreadsheetNonSelectedIcon, null) },
    },
    {
        name: strings.options.bigAct,
        to: BigActRoutes.bigAct,
        icon: {
            selected: React.createElement(NavbarBigActSelectedIcon, null),
            nonSelected: React.createElement(NavbarBigActNonSelectedIcon, null),
        },
    },
    {
        name: strings.options.bigData,
        to: HomeRoutes.bigData,
        icon: { selected: React.createElement(NavbarBigDataSelected, null), nonSelected: React.createElement(NavbarBigDataNonSelectedIcon, null) },
    },
    {
        name: strings.options.agDash,
        to: HomeRoutes.agDash,
        icon: { selected: React.createElement(NavbarAgDashSelected, null), nonSelected: React.createElement(NavbarAgDashNonSelectedIcon, null) },
    },
];
var mappedOptions = function (currentLocation) {
    return navbarOptionsData.map(function (option, index) {
        var isCurrentLocation = currentLocation === option.to;
        return (React.createElement(React.Fragment, { key: index },
            React.createElement(NavbarOption, __assign({}, option, { selected: isCurrentLocation })),
            React.createElement(Separator, { size: Spacing.XXSmall })));
    });
};
export var NavbarMenu = function () {
    var user = useUserStore()[0];
    var location = useLocation();
    var isXxl = useMatchMedia('xxl').matchBreakpoint;
    var _a = React.useState(false), isOpened = _a[0], setIsOpened = _a[1];
    var dropdownRef = React.useRef(null);
    var accountWrapperRef = React.useRef(null);
    React.useEffect(function () {
        var handleClickOutside = function (event) {
            var _a;
            if (dropdownRef.current &&
                event.target instanceof Node &&
                !dropdownRef.current.contains(event.target) &&
                !((_a = accountWrapperRef === null || accountWrapperRef === void 0 ? void 0 : accountWrapperRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
                setIsOpened(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return function () { return document.removeEventListener('mousedown', handleClickOutside); };
    }, [dropdownRef, accountWrapperRef]);
    var names = user.name.split(' ');
    return (React.createElement(NavbarWrapper, null,
        React.createElement(Hbox, null,
            React.createElement(Hbox.Separator, { size: Spacing.Small }),
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(Separator, { size: Spacing.Large }),
                React.createElement(AppLogo, null))),
        React.createElement(Separator, { size: isXxl ? Spacing.Large : Spacing.XLarge }),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Separator, { size: Spacing.Small }),
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(H2, { color: 'White' }, strings.options.title)),
            React.createElement(Hbox.Separator, null)),
        mappedOptions(location.pathname),
        React.createElement(UserDropdownWrapper, null,
            React.createElement(UserDropdown, { isOpened: isOpened, innerRef: dropdownRef }),
            React.createElement(AccountWrapper, { onClick: function () { return setIsOpened(function (prev) { return !prev; }); }, ref: accountWrapperRef },
                React.createElement(Hbox.Item, { noGrow: true },
                    React.createElement(ProfilePicture, null, user.name[0])),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                    React.createElement(BodySecondary, { color: 'White' }, "".concat(names[0], " ").concat(names[names.length - 1]))),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                    React.createElement(UserDropdownIcon, { active: isOpened })))),
        React.createElement(Separator, null)));
};
