import React from 'react';
import { BackButton } from '@app/components/atm.back-button';
import { ImageBackground } from '@app/components/atm.image-background';
import { useFlashMessage } from '@app/components/obj.flash-message/flash-message.hook';
import { useParams } from '@app/core/route/query-params-hook';
import { useForgotPassword } from '@app/domain';
import { AuthRoutes } from '@app/modules/auth/routes';
import BackgroundLoginImage from '@assets/img/background-login.jpg';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { Body, H1, InputLabel } from '@atomic/atm.typography';
import { FlashMessage } from '@atomic/mol.flash-message';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { strings } from './forgot-password.strings';
var Screen;
(function (Screen) {
    Screen[Screen["form"] = 0] = "form";
    Screen[Screen["verifyCode"] = 1] = "verifyCode";
})(Screen || (Screen = {}));
export var ForgotPasswordPage = function () {
    var showSuccess = useFlashMessage().showSuccess;
    var _a = React.useState(Screen.form), screen = _a[0], setScreen = _a[1];
    var _b = React.useState(), userEmail = _b[0], setUserEmail = _b[1];
    var _c = useForgotPassword({
        onCompleted: function (data) {
            showSuccess(data.forgotPassword);
            setScreen(Screen.verifyCode);
        },
    }), forgotPassword = _c.forgotPassword, loading = _c.loading, error = _c.error;
    var handleSubmit = function (formData) {
        if (formData.hasError) {
            return;
        }
        setUserEmail(formData.data.email);
        forgotPassword({ data: formData.data });
    };
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, { nogutter: true },
            React.createElement(Col, { md: 6 },
                React.createElement(BackButton, { to: AuthRoutes.login, text: strings.buttons.back }),
                React.createElement(Row, { justify: 'center', align: 'center', "$allHeight": true },
                    React.createElement(Col, { md: 10, lg: 8, xxl: 6 }, screen === Screen.form ? (React.createElement(FormScreen, { onSubmit: handleSubmit, loading: loading, error: error })) : (React.createElement(VerifyCodeScreen, { email: userEmail }))))),
            React.createElement(Col, { md: 6 },
                React.createElement(ImageBackground, { src: BackgroundLoginImage })))));
};
export var FormScreen = function (props) {
    var email = useParams().email;
    return (React.createElement(Grid, { fluid: true },
        React.createElement(H1, null, strings.title),
        React.createElement(Body, null, strings.subtitle),
        React.createElement(Separator, { size: Spacing.XSmall }),
        React.createElement(Form, { onSubmit: props.onSubmit },
            React.createElement(Form.Field, { name: Fields.email, label: strings.formLabel.email, initialValue: email, validators: [Validators.Required(), Validators.EmailRegex()] },
                React.createElement(InputLabel, null,
                    React.createElement(TextField, { inputLabel: true, type: 'email' }))),
            React.createElement(Separator, null),
            !!props.error && (React.createElement(FlashMessage, { type: 'alert', dismissible: true, mb: true }, props.error.message)),
            React.createElement(Button, { variant: 'primary', type: 'submit', loading: props.loading }, strings.buttons.sendCode))));
};
var Fields = {
    email: 'email',
};
export var VerifyCodeScreen = function (props) { return (React.createElement(React.Fragment, null,
    React.createElement(H1, null, strings.verifyCode.title),
    React.createElement(Body, { color: 'GrayXDark' }, props.email),
    React.createElement(Separator, { size: Spacing.XSmall }),
    React.createElement(Body, null, strings.verifyCode.instruction))); };
