var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Color, Shadow, Spacing } from '@atomic/obj.constants';
export var ColorPallet = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  width: ", ";\n  height: ", ";\n"], ["\n  background-color: ", ";\n  width: ", ";\n  height: ", ";\n"])), function (_a) {
    var bgColor = _a.bgColor;
    return bgColor;
}, Spacing.XSmall, Spacing.XSmall);
export var ChartInfoStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n"], ["\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n"])), Color.GrayXLight, Spacing.XXSmall, Border.SmallRadius, Shadow.Small);
export var ChartOptionWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 6px ", ";\n  background-color: ", ";\n  border-radius: ", ";\n"], ["\n  padding: 6px ", ";\n  background-color: ", ";\n  border-radius: ", ";\n"])), Spacing.XXSmall, Color.GrayXXLight, Border.SmallRadius);
var templateObject_1, templateObject_2, templateObject_3;
