import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
export function useUpdateSpreadsheet(params) {
    var _a = useMutation('UpdateSpreadsheetMutation', params), updateSpreadsheet = _a[0], _b = _a[1], data = _b.data, error = _b.error, loading = _b.loading;
    return {
        updateSpreadsheet: updateSpreadsheet,
        data: data,
        error: error,
        loading: loading,
    };
}
