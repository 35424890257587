export var strings = {
    title: 'Boas-vindas a plataforma!',
    description: 'Caso tenha dúvidas sobre o uso da plataforma, assista o nosso vídeo ou entre em contato conosco!',
    buttons: {
        watchLater: 'Ver depois',
        contact: 'Entrar em contato',
    },
    aboutVideo: 'Video introdutório da plataforma IMBR',
    videoUrl: 'https://www.youtube.com/embed/mPRRMR2yD_U?si=8E4W5aFtY0U_eCIa',
};
