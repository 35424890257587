var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { SpreadsheetRoutes } from '@app/modules/spreadsheet/routes';
import { useUserStore } from '@app/stores';
export function useLogin() {
    var navigate = useNavigate();
    var _a = useUserStore(), setUser = _a[1];
    var _b = useMutation('LoginMutation', {
        onCompleted: function (loginResult) {
            var _a, _b, _c;
            setUser(__assign(__assign({}, (_a = loginResult === null || loginResult === void 0 ? void 0 : loginResult.login) === null || _a === void 0 ? void 0 : _a.user), { token: (_b = loginResult === null || loginResult === void 0 ? void 0 : loginResult.login) === null || _b === void 0 ? void 0 : _b.token, retoken: (_c = loginResult === null || loginResult === void 0 ? void 0 : loginResult.login) === null || _c === void 0 ? void 0 : _c.retoken }));
            navigate(SpreadsheetRoutes.spreadshhetAdmin);
        },
    }), login = _b[0], _c = _b[1], data = _c.data, error = _c.error, loading = _c.loading;
    return {
        login: login,
        data: data,
        error: error,
        loading: loading,
    };
}
