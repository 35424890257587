export var bigDataTableStrings = {
    headers: [
        'Data',
        'Temp. Máx °C',
        'Temp. Méd °C',
        'Temp. Mín °C',
        'P.A.R',
        'Umidade Relativa',
        'Veloc. Vento m/s',
        'Precip. Acum. (mm)',
    ],
    infocard: 'Os dados disponibilidades acima respeitam a seleção realizada. De toda forma, é importante destacar que os dados se referem ao grid mais próximo das coordenadas selecionadas da planilha.',
};
