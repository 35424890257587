import * as React from 'react';
import { InView } from 'react-intersection-observer';
import { LazyLoadErrorImageStyled, LazyLoadImageStyled, LazyLoadPlaceholderImageStyled, LazyLoadPlaceholderStyled, } from './lazy-load-image.component.style';
var Status;
(function (Status) {
    Status[Status["Loading"] = 0] = "Loading";
    Status[Status["Error"] = 1] = "Error";
    Status[Status["Loaded"] = 2] = "Loaded";
})(Status || (Status = {}));
export var LazyLoadImage = function (props) {
    var imageWrapper = React.useRef({});
    var _a = React.useState(Status.Loading), status = _a[0], setStatus = _a[1];
    var _b = React.useState(0), imgHeight = _b[0], setImgHeight = _b[1];
    var handleError = function () {
        setStatus(Status.Error);
    };
    var handleLoad = function () {
        if (props.aspectRatio && imageWrapper.current) {
            var height = props.aspectRatio * imageWrapper.current.clientWidth;
            setImgHeight(height);
            setStatus(Status.Loaded);
        }
        else {
            setStatus(Status.Loaded);
        }
    };
    var referImageWrapper = function (image) {
        imageWrapper.current = image;
    };
    return (React.createElement(InView, { triggerOnce: true }, function (_a) {
        var _b, _c, _d;
        var inView = _a.inView, ref = _a.ref;
        return (React.createElement("div", { ref: ref },
            status === Status.Loading && (React.createElement(LazyLoadPlaceholderStyled, { aspectRatio: props.aspectRatio },
                React.createElement(LazyLoadPlaceholderImageStyled, null))),
            status === Status.Error && (React.createElement(LazyLoadPlaceholderStyled, { aspectRatio: props.aspectRatio },
                React.createElement(LazyLoadErrorImageStyled, null))),
            ((inView && status === Status.Loading) || status === Status.Loaded) && (React.createElement("div", { ref: referImageWrapper },
                React.createElement(LazyLoadImageStyled, { onClick: props.onClick, src: props.src, alt: props.alt, onLoad: handleLoad, onError: handleError, loaded: status === Status.Loaded, round: props.round, height: (_b = props.height) !== null && _b !== void 0 ? _b : imgHeight, srcSet: (_c = props.responsive) === null || _c === void 0 ? void 0 : _c.srcset, sizes: (_d = props.responsive) === null || _d === void 0 ? void 0 : _d.sizes })))));
    }));
};
