var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import ReactPDF, { View, Page, Document, Image } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import html2Canvas from 'html2canvas';
import { useCreateExport } from '@app/domain/export';
import LogoImage from '@assets/img/imbr-agro-logo.png';
import BackImage from '@assets/img/imbr-pdf-back.jpg';
import CoverImage from '@assets/img/imbr-pdf-cover.jpg';
import { Color, Spacing } from '@atomic/obj.constants';
var HEADER_FOOTER_SIZE = {
    width: '100%',
    height: '36px',
};
var LOGO_IMAGE_SIZE = {
    width: '43px',
    height: '20px',
};
export var ExportPDF = function (props) {
    var _a = React.useState(), chart = _a[0], setChart = _a[1];
    var ref = React.useRef(null);
    var createExport = useCreateExport({
        onCompleted: function () {
            exportPDFDownload(chart, props.filename);
            props.onReadyToExport(false);
        },
        onError: function () {
            props.openModal();
            props.onReadyToExport(false);
        },
    }).createExport;
    var createDocument = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var canvas, imgData, document;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, html2Canvas(ref.current)];
                case 1:
                    canvas = _a.sent();
                    imgData = canvas.toDataURL('image/png');
                    document = (React.createElement(Document, null,
                        React.createElement(Page, { size: 'A4', wrap: false },
                            React.createElement(View, null,
                                React.createElement(Image, { src: CoverImage }))),
                        React.createElement(Page, { size: 'A4', wrap: false },
                            React.createElement(View, { style: {
                                    backgroundColor: Color.PrimaryDark,
                                    width: HEADER_FOOTER_SIZE.width,
                                    height: HEADER_FOOTER_SIZE.height,
                                } },
                                React.createElement(Image, { src: LogoImage, style: { height: LOGO_IMAGE_SIZE.height, width: LOGO_IMAGE_SIZE.width, margin: Spacing.XXSmall } })),
                            React.createElement(View, null,
                                React.createElement(Image, { src: imgData })),
                            React.createElement(View, { style: {
                                    backgroundColor: Color.PrimaryDark,
                                    width: HEADER_FOOTER_SIZE.width,
                                    height: HEADER_FOOTER_SIZE.height,
                                } },
                                React.createElement(Image, { src: LogoImage, style: { height: LOGO_IMAGE_SIZE.height, width: LOGO_IMAGE_SIZE.width, margin: Spacing.XXSmall } }))),
                        React.createElement(Page, { size: 'A4', wrap: false },
                            React.createElement(View, null,
                                React.createElement(Image, { src: BackImage })))));
                    setChart(document);
                    createExport({ data: { exportType: props.exportType } });
                    return [2 /*return*/];
            }
        });
    }); }, [createExport, props.exportType]);
    React.useEffect(function () {
        if (props.readyToExport) {
            (function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, createDocument()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            })();
        }
    }, [createDocument, props.readyToExport]);
    return React.createElement("div", { ref: ref }, props.children);
};
function exportPDFDownload(chart, fileName) {
    return __awaiter(this, void 0, void 0, function () {
        var blob;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ReactPDF.pdf(chart).toBlob()];
                case 1:
                    blob = _a.sent();
                    saveAs(blob, fileName);
                    return [2 /*return*/];
            }
        });
    });
}
