var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { Service } from 'typedi';
var ApolloClientBuilderService = /** @class */ (function () {
    function ApolloClientBuilderService() {
    }
    ApolloClientBuilderService.prototype.build = function (url, middlewares) {
        var httpLink = createUploadLink({ uri: url });
        var link = middlewares ? from(__spreadArray(__spreadArray([], middlewares, true), [httpLink], false)) : httpLink;
        var client = new ApolloClient({
            link: link,
            cache: new InMemoryCache(),
        });
        return client;
    };
    ApolloClientBuilderService = __decorate([
        Service()
    ], ApolloClientBuilderService);
    return ApolloClientBuilderService;
}());
export { ApolloClientBuilderService };
