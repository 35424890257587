var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputValue } from '@atomic/atm.typography';
import { clickableFocusCss } from '@atomic/mol.focus/focus.style';
import { Color, Spacing } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
export var checkboxHeight = 24;
export var marginBetweenCheckboxItems = Spacing.XSmall;
export var CheckboxFieldStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  pointer-events: ", ";\n  cursor: pointer;\n  & ~ * {\n    margin-top: ", ";\n  }\n  display: flex;\n  align-items: flex-start;\n\n  &:focus-within {\n    ", "\n  }\n"], ["\n  pointer-events: ", ";\n  cursor: pointer;\n  & ~ * {\n    margin-top: ", ";\n  }\n  display: flex;\n  align-items: flex-start;\n\n  &:focus-within {\n    ", "\n  }\n"])), function (props) { return (props.disabled ? 'none' : 'auto'); }, marginBetweenCheckboxItems, clickableFocusCss);
export var CheckboxFieldTextStyled = styled(InputValue)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 28px;\n  line-height: 28px;\n  z-index: 2;\n  ", ";\n"], ["\n  padding-left: 28px;\n  line-height: 28px;\n  z-index: 2;\n  ", ";\n"])), highlightStyle);
export var CheckboxFieldBulletWrapperStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var CheckboxCheckedStyled = styled(FaIcon.CheckSquare)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  font-size: ", "px !important;\n  color: ", ";\n  z-index: 1;\n  opacity: 0;\n  top: 2px;\n"], ["\n  position: absolute;\n  font-size: ", "px !important;\n  color: ", ";\n  z-index: 1;\n  opacity: 0;\n  top: 2px;\n"])), checkboxHeight, function (props) { return (props.disabled ? Color.GrayLight : Color.Primary); });
export var CheckboxUncheckedStyled = styled(FaIcon.Square)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  font-size: ", "px !important;\n  color: ", ";\n  z-index: 0;\n  opacity: 1;\n  top: 2px;\n"], ["\n  position: absolute;\n  font-size: ", "px !important;\n  color: ", ";\n  z-index: 0;\n  opacity: 1;\n  top: 2px;\n"])), checkboxHeight, function (props) { return (props.disabled ? Color.GrayLight : Color.Black); });
export var CheckboxFieldBulletStyled = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  line-height: 28px;\n  width: 0;\n  opacity: 0; /* keeping the input available on the accessibility tree  */\n  &:checked + ", " {\n    opacity: 1;\n    + ", " {\n      opacity: 0;\n    }\n  }\n"], ["\n  line-height: 28px;\n  width: 0;\n  opacity: 0; /* keeping the input available on the accessibility tree  */\n  &:checked + ", " {\n    opacity: 1;\n    + ", " {\n      opacity: 0;\n    }\n  }\n"])), CheckboxCheckedStyled, CheckboxUncheckedStyled);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
