var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { refreshTokenIfNeeded } from '@app/data/graphql/interceptors/refresh-token';
import { AuthenticationDataSource } from '@app/data/local/authentication.datasource';
import { AppError, ErrorType } from '@app/model';
var refreshTokenIfNeededMiddleware = setContext(function (request, context) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (request.operationName === 'retoken') {
                    return [2 /*return*/, context];
                }
                return [4 /*yield*/, refreshTokenIfNeeded()];
            case 1:
                _a.sent();
                return [2 /*return*/, context];
        }
    });
}); });
var saveAuthTokenMiddleWare = new ApolloLink(function (operation, forward) {
    return forward(operation).map(function (response) {
        var _a, _b, _c, _d;
        var token = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.login) === null || _b === void 0 ? void 0 : _b.token;
        var signUpToken = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.signUp) === null || _d === void 0 ? void 0 : _d.token;
        if (token) {
            AuthenticationDataSource.update({ token: token });
        }
        if (signUpToken) {
            AuthenticationDataSource.update({ token: signUpToken });
        }
        return response;
    });
});
var unathourizedErrorMiddleware = onError(function (_a) {
    var graphQLErrors = _a.graphQLErrors;
    if (graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.some(function (error) {
        var _a;
        return ((_a = error.extensions) === null || _a === void 0 ? void 0 : _a.code) === 401;
    })) {
        console.warn(new AppError(ErrorType.Authorization));
    }
});
var setAuthTokenMiddleware = setContext(function (_, _a) {
    var _b;
    var headers = _a.headers;
    var authUser = AuthenticationDataSource.get();
    return {
        headers: __assign(__assign({}, headers), { authorization: (_b = authUser === null || authUser === void 0 ? void 0 : authUser.token) !== null && _b !== void 0 ? _b : '' }),
    };
});
export var graphQlMiddlewares = [
    refreshTokenIfNeededMiddleware,
    setAuthTokenMiddleware,
    saveAuthTokenMiddleWare,
    unathourizedErrorMiddleware,
];
