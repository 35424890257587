import React, { useEffect } from 'react';
import { ContactUsCard } from '@app/components/mol.contact-us-card';
import { SpreadsheetsTable } from '@app/components/mol.spreadsheets-table';
import { UploadSpreadsheetSection } from '@app/components/mol.upload-spreadsheet-section';
import { useSpreadsheets } from '@app/domain';
import { Body, H1, H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { strings } from './spreadsheet-admin.strings';
export var SpreadsheetAdminPage = function () {
    var _a;
    var _b = useSpreadsheets(), data = _b.data, loading = _b.loading, refetch = _b.refetch, error = _b.error;
    useEffect(function () {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.spreadsheets) === null || _a === void 0 ? void 0 : _a.length) && !loading && !error) {
            window.location.reload();
        }
    }, [data, loading, error, refetch]);
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(H1, null, strings.title),
                React.createElement(Separator, { size: Spacing.Large }),
                React.createElement(UploadSpreadsheetSection, { onRefetch: refetch }),
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { noGrow: true },
                        React.createElement(H2, { noMargin: true }, strings.subtitle)),
                    React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, { vAlign: 'center' },
                        React.createElement(Body, null, strings.countArchieves((_a = data === null || data === void 0 ? void 0 : data.spreadsheets) === null || _a === void 0 ? void 0 : _a.length)))),
                React.createElement(Separator, { size: Spacing.XXSmall }),
                React.createElement(SpreadsheetsTable, { spreadsheets: data === null || data === void 0 ? void 0 : data.spreadsheets, loading: loading, onRefetch: refetch }),
                React.createElement(Separator, { size: Spacing.Medium }),
                React.createElement(ContactUsCard, null),
                React.createElement(Separator, { size: Spacing.Large })))));
};
