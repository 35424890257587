export var strings = {
    filterLabel: 'Filtrar por:',
    exportButton: 'Exportar',
    placeholder: {
        county: 'Município',
        culture: 'Cultura',
        cycle: 'Ciclo',
        seeding: 'Semeadura',
        uf: 'UF',
    },
};
export var Inputs = {
    uf: 'UnityFederative',
    county: 'county',
    culture: 'culture',
    cycle: 'cycle',
    seeding: 'seeding',
};
