import * as React from 'react';
import { useMutation as useApolloMutation } from '@apollo/client';
import { getMutation } from '@app/data/graphql/graphql-docs.service';
import { mapApolloError } from './graphql-error.mapper';
export function useMutation(document, options) {
    if (options === void 0) { options = {}; }
    var query = getMutation(document);
    var _a = useApolloMutation(query, {
        onCompleted: options.onCompleted,
        onError: function (error) { var _a; return (_a = options.onError) === null || _a === void 0 ? void 0 : _a.call(options, mapApolloError(error)); },
    }), apolloMutate = _a[0], res = _a[1];
    var mutate = React.useCallback(function (variables) {
        apolloMutate({ variables: variables });
    }, [apolloMutate]);
    return [
        mutate,
        {
            called: res.called,
            loading: res.loading,
            data: res.data,
            error: res.error ? mapApolloError(res.error) : undefined,
        },
    ];
}
