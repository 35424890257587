import React from 'react';
import { FlashMessage } from '@atomic/mol.flash-message';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Grid, Row, Col } from '@atomic/obj.grid';
import { FlashMessageContext } from './flash-message-context';
import { FlashMessageWrapperStyled } from './flash-message.style';
export var FlashMessageProvider = function (props) {
    var _a = React.useState({ hidden: true }), message = _a[0], setMessage = _a[1];
    var showMessage = function (type, content) {
        var newMessage = { type: type, content: content, hidden: false };
        setMessage(newMessage);
    };
    var hideMessage = function () {
        var newMessage = { hidden: true };
        setMessage(newMessage);
    };
    return (React.createElement(FlashMessageContext.Provider, { value: { message: message, showMessage: showMessage, hideMessage: hideMessage } },
        props.children,
        !message.hidden && message.content && (React.createElement(FlashMessageWrapperStyled, null,
            React.createElement(Grid, { fluid: true },
                React.createElement(Row, { justify: 'center' },
                    React.createElement(Col, { md: 8, xl: 6, offset: { md: 4, lg: 3, xxl: 2 } },
                        React.createElement(FlashMessage, { type: message.type, dismissible: message.dismissible, onClose: hideMessage }, message.content),
                        React.createElement(Separator, { size: Spacing.Medium }))))))));
};
