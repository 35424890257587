import { ApolloClient } from '@apollo/client';
import { setConfiguration } from 'react-grid-system';
import { Container, Token } from 'typedi';
import { ApolloClientBuilderService } from '@app/core/graphql';
import { GridSettings } from '@atomic/obj.grid';
import { graphQlMiddlewares } from './data/graphql/middlewares';
export var SPREADSHEET_TEMPLATE_GCLOUD_URL = new Token('SPREADSHEET_TEMPLATE_GCLOUD_URL');
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function configure(frontendEnvironmentVariables) {
    var baseUrl = frontendEnvironmentVariables.BASE_URL;
    var spreadsheetTemplateUrl = frontendEnvironmentVariables.SPREADSHEET_TEMPLATE_GCLOUD_URL;
    Container.set(SPREADSHEET_TEMPLATE_GCLOUD_URL, spreadsheetTemplateUrl);
    configApolloClient(baseUrl);
    configureGrid();
}
function configureGrid() {
    setConfiguration(GridSettings);
}
function configApolloClient(url) {
    var client = Container.get(ApolloClientBuilderService).build(url, graphQlMiddlewares);
    Container.set(ApolloClient, client);
}
