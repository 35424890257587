var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { PageDisabledStyled, PageStyled, PaginationStyled } from './pagination.component.style';
var BOUNDARY_COUNT = 2; // Edge item plus possible separator
var SEPARATOR = '...';
var START_PAGE = 1;
var PaginationItem = function (props) {
    if (props.page === SEPARATOR) {
        return React.createElement(PageDisabledStyled, null, props.page);
    }
    var first = props.first, last = props.last, active = props.active;
    var handleClick = function () {
        props.onClick(parseInt(props.page));
    };
    return (React.createElement(PageStyled, { variant: 'link', outlined: true, onClick: handleClick, first: first, last: last, active: active }, props.page));
};
export var PaginationComponent = function (props) {
    var constructPaginationArray = function () {
        var paginationItems = [];
        var window = props.siblingCount * 2 + 1;
        var totalItems = window + BOUNDARY_COUNT * 2;
        if (totalItems >= props.total) {
            return __spreadArray([], new Array(props.total), true).map(function (_value, index) { return (index + 1).toString(); });
        }
        var untiedFirstSibling = props.current - props.siblingCount;
        var canTieWindowAndLeftBoundary = untiedFirstSibling <= START_PAGE + BOUNDARY_COUNT;
        var canTieWindowAndRightBoundary = untiedFirstSibling >= props.total - BOUNDARY_COUNT - window + 1;
        var firstSibling = canTieWindowAndLeftBoundary
            ? START_PAGE + BOUNDARY_COUNT
            : canTieWindowAndRightBoundary
                ? props.total - BOUNDARY_COUNT - window + 1
                : untiedFirstSibling;
        paginationItems.push(START_PAGE.toString());
        if (canTieWindowAndLeftBoundary) {
            paginationItems.push((START_PAGE + 1).toString());
        }
        else {
            paginationItems.push(SEPARATOR);
        }
        for (var page = firstSibling; page < firstSibling + window; page++) {
            paginationItems.push(page.toString());
        }
        if (canTieWindowAndRightBoundary) {
            paginationItems.push((props.total - 1).toString());
        }
        else {
            paginationItems.push(SEPARATOR);
        }
        paginationItems.push(props.total.toString());
        return paginationItems;
    };
    var constructedPaginationArray = constructPaginationArray();
    return (React.createElement(PaginationStyled, null, constructedPaginationArray.map(function (val, index) { return (React.createElement(PaginationItem, { first: index === 0 || constructedPaginationArray[index - 1] === SEPARATOR, last: val === props.total.toString() || constructedPaginationArray[index + 1] === SEPARATOR, key: 'name' + index, active: val === props.current.toString(), onClick: props.onPageChange, page: val })); })));
};
