var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Color, Spacing, FontFamily, FontSize, FontWeight } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
var flashMessageTypes = {
    warning: {
        bg: Color.WarningLight,
        color: Color.WarningDark,
    },
    success: {
        bg: Color.SuccessLight,
        color: Color.SuccessDark,
    },
    alert: {
        bg: Color.AlertLight,
        color: Color.AlertDark,
    },
};
export var FlashMessageContentStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n  margin: 0;\n"], ["\n  flex-grow: 1;\n  margin: 0;\n"])));
export var FlashMessageCloseStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  margin-left: ", ";\n\n  &:hover > span {\n    color: ", ";\n  }\n"], ["\n  ", "\n  margin-left: ", ";\n\n  &:hover > span {\n    color: ", ";\n  }\n"])), highlightStyle, Spacing.XSmall, function (props) { return flashMessageTypes[props.type].color; });
export var FlashMessageStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: ", ";\n  display: flex;\n  align-items: center;\n  padding: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  transition: opacity 0.3s ease-in-out;\n  ", ";\n\n  p,\n  svg {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border-radius: ", ";\n  display: flex;\n  align-items: center;\n  padding: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  transition: opacity 0.3s ease-in-out;\n  ", ";\n\n  p,\n  svg {\n    color: ", ";\n  }\n"])), function (props) { return flashMessageTypes[props.type].bg; }, Border.Radius, Spacing.XSmall, FontFamily.Primary, FontWeight.Normal, FontSize.Small, function (props) { return props.mb && "margin-bottom:".concat(Spacing.Small); }, function (props) { return flashMessageTypes[props.type].color; });
var templateObject_1, templateObject_2, templateObject_3;
