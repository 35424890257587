var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Color } from '@atomic/obj.constants';
var LINE_MARGIN = {
    LEFT_AND_RIGHT: '5px',
    TOP_AND_BOTTOM: '0px',
};
export var LineStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n  background-color: ", ";\n  margin: ", " ", ";\n"], ["\n  height: ", ";\n  background-color: ", ";\n  margin: ", " ", ";\n"])), Border.Width, Color.GrayLight, LINE_MARGIN.TOP_AND_BOTTOM, LINE_MARGIN.LEFT_AND_RIGHT);
var templateObject_1;
