import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ChartTooltipStyled } from '@app/components/atm.chart-tooltip-styled';
import { ColorPallet } from '@app/components/atm.color-pallet';
import { InfoCard } from '@app/components/atm.info-card';
import { AgDashPlaceholderBackground } from '@assets/icons/ag-dash-placeholder-background';
import { AgDashPlaceholderIcon } from '@assets/icons/ag-dash-placeholder.icon';
import { Card } from '@atomic/atm.card';
import { Body, H2, H3 } from '@atomic/atm.typography';
import { AbsoluteBox } from '@atomic/obj.absolute-box';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color, Shadow, Spacing, ZIndex } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { AgDashChartLoading } from '../mol.ag-dash-agroclimate-evolution-chart/ag-dash-chart-placeholder.component';
import { strings } from './big-act-chart.strings';
import { PLaceholderCard } from './big-act-chart.styled';
export var BigActChart = function (props) {
    var _a, _b, _c, _d, _e;
    return (React.createElement(LoadingState, { loading: props.loading, data: !!((_c = (_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.bigActResult) === null || _b === void 0 ? void 0 : _b.result) === null || _c === void 0 ? void 0 : _c.length) },
        React.createElement(LoadingState.Shimmer, null,
            React.createElement(AgDashChartLoading, null)),
        React.createElement(LoadingState.NoData, null,
            React.createElement(PlaceholderChart, null)),
        React.createElement(Card, { shadow: Shadow.Medium },
            React.createElement(H3, { center: true }, strings.title),
            React.createElement(ResponsiveContainer, { width: '100%', height: CHART_CONTAINER_HEIGHT },
                React.createElement(BarChart, { data: (_e = (_d = props.data) === null || _d === void 0 ? void 0 : _d.bigActResult) === null || _e === void 0 ? void 0 : _e.result },
                    React.createElement(CartesianGrid, { strokeDasharray: '3 3' }),
                    React.createElement(XAxis, { dataKey: 'harvest', height: X_AXIS_HEIGHT }),
                    React.createElement(YAxis, null),
                    React.createElement(Tooltip, { content: React.createElement(CustomTooltip, null) }),
                    React.createElement(Legend, { content: React.createElement(CustomLegend, null) }),
                    React.createElement(Bar, { dataKey: 'potentialProductivity', fill: Color.AccessoryMagenta, fillOpacity: 0.5, barSize: 30 }),
                    React.createElement(Bar, { dataKey: 'attainableProductivity', fill: Color.AccessoryMagenta, barSize: 30 }))),
            React.createElement(Separator, { size: Spacing.Small }),
            React.createElement(InfoCard, { content: strings.ibmrInfo }))));
};
var CustomLegend = function () {
    return (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(Separator, { size: Spacing.Small }),
            React.createElement(Hbox, null,
                React.createElement(ColorPallet, { bgColor: MAGENTA_WITH_OPACITY_COLOR }),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                    React.createElement(Body, null, strings.legend.potentialProductivity)),
                React.createElement(Hbox.Separator, null),
                React.createElement(ColorPallet, { bgColor: Color.AccessoryMagenta }),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                    React.createElement(Body, null, strings.legend.attainableProductivity))))));
};
var CustomTooltip = function (_a) {
    var active = _a.active, payload = _a.payload;
    if (active && payload && payload.length) {
        var dataPoint = payload[0].payload;
        return (React.createElement(ChartTooltipStyled, { noGutter: true },
            React.createElement(Body, { center: true }, dataPoint.harvest),
            React.createElement(Body, null,
                strings.tooltip.potentialProductivity,
                " ",
                dataPoint.potentialProductivity.toFixed(2)),
            React.createElement(Body, null,
                strings.tooltip.attainableProductivity,
                " ",
                dataPoint.attainableProductivity.toFixed(2))));
    }
    return null;
};
var PlaceholderChart = function () { return (React.createElement(PLaceholderCard, { shadow: Shadow.Medium },
    React.createElement(AgDashPlaceholderBackground, null),
    React.createElement(AbsoluteBox, { zIndex: ZIndex.top, top: ICON_ALIGMENT },
        React.createElement(AgDashPlaceholderIcon, null)),
    React.createElement(Separator, { size: Spacing.Small }),
    React.createElement(H2, { noMargin: true }, strings.noData.titile),
    React.createElement(Separator, { size: Spacing.XXSmall }),
    React.createElement(Body, null, strings.noData.instruction))); };
var CHART_CONTAINER_HEIGHT = 648;
var MAGENTA_WITH_OPACITY_COLOR = 'rgba(181, 27, 118, 0.5)';
var ICON_ALIGMENT = '28%';
var X_AXIS_HEIGHT = 48;
