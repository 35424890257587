export var strings = {
    title: 'Carregar nova planilha',
    loadingTitle: 'Carregando arquivo...',
    uploaded: 'Arquivo carregado!',
    uploadInvalid: 'Erro no arquivo',
    descriptionInvalid: 'A planilha importada é inválida. Para saber se sua planilha se encaixa em nosso padrão, ',
    spreadsheetTemplateLink: 'baixe o nosso template',
    infoCard: {
        base: 'Para saber se sua planilha se encaixa em nosso padrão, ',
        exclamation: '!',
    },
};
