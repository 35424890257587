import * as React from 'react';
import { Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
export var GuardElement = function (props) {
    var location = useLocation();
    var authorized = props.useGuard();
    var oldParams = new URLSearchParams(location.search);
    var targetUrl = oldParams.get('nextPath');
    if (!targetUrl) {
        targetUrl = props.redirectPath;
        if (props.sendPreviousPath) {
            var newParams = new URLSearchParams({
                nextPath: location.pathname + location.search,
            });
            targetUrl += '?' + newParams.toString();
        }
    }
    return authorized ? React.createElement(Outlet, null) : React.createElement(Navigate, { to: targetUrl });
};
