import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
export function useDeleteSpreadsheet(params) {
    var _a = useMutation('DeleteSpreadsheetMutation', params), deleteSpreadsheet = _a[0], _b = _a[1], data = _b.data, error = _b.error, loading = _b.loading;
    return {
        deleteSpreadsheet: deleteSpreadsheet,
        data: data,
        error: error,
        loading: loading,
    };
}
