export var strings = {
    title: 'Informações pessoais',
    buttons: {
        edit: 'Editar',
        noEdit: 'Cancelar',
        submit: 'Salvar alterações',
        delete: 'Excluir conta',
    },
    label: {
        name: 'Nome',
        lastName: 'Último nome',
        email: 'Email',
        company: 'Empresa',
    },
    feedbackSuccess: 'Seus dados foram atualizados com sucesso',
    validatorFullName: 'Por favor, insira seu nome completo',
};
