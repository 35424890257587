var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Buffer } from 'buffer';
import { addMonths, isSameMonth } from 'date-fns';
import { saveAs } from 'file-saver';
import { mean, sum } from 'lodash';
import { AgDashChartLoading } from '@app/components/mol.ag-dash-agroclimate-evolution-chart/ag-dash-chart-placeholder.component';
import { BigDataFiltersSection } from '@app/components/mol.big-data-filters-section';
import { BigDataPlaceholder } from '@app/components/mol.big-data-placeholder';
import { BigDataTable } from '@app/components/mol.big-data-table';
import { ContactUsCard } from '@app/components/mol.contact-us-card';
import { ExportPdfWarningModal } from '@app/components/mol.export-pdf-warning-modal';
import { LimitCallsModal } from '@app/components/mol.limit-calls-modal/limit-calls-modal.component';
import { useBigData } from '@app/domain';
import { useExportBigDataSpreadsheet } from '@app/domain/big-data/export-big-data-spreadsheet.use.case';
import { useCreateExport } from '@app/domain/export';
import { H1 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { useModalState } from '@atomic/obj.modal/modal.component.hook';
import { bigDataPageStrings as strings } from './big-data.page.strings';
export var BigDataPage = function () {
    var _a;
    var _b = React.useState(), filtersData = _b[0], setFiltersData = _b[1];
    var _c = useModalState(), openModal = _c.openModal, closeModal = _c.closeModal, opened = _c.opened;
    var _d = useModalState(), openLimitCallsModal = _d.openModal, closeLimitCallsModal = _d.closeModal, openedLimitCallsModal = _d.opened;
    var _e = useBigData({
        onError: function () {
            openLimitCallsModal();
        },
    }), getBigData = _e[0], _f = _e[1], data = _f.data, loading = _f.loading;
    var tableData = data && aggregateData(data.bigData, filtersData.dateFilter);
    var _g = useExportBigDataSpreadsheet({
        onCompleted: function (_a) {
            var exportBigDataSpreadsheet = _a.exportBigDataSpreadsheet;
            return downloadFile(exportBigDataSpreadsheet, "big-data-".concat(filtersData.initialDate, "-").concat(filtersData.dataBase, ".xlsx"));
        },
    }), exportBigDataSpreadsheetMutation = _g[0], exportSpreadsheetLoading = _g[1].loading;
    var createExport = useCreateExport({
        onCompleted: function () { return exportBigDataSpreadsheetMutation({ data: { bigData: tableData } }); },
        onError: function () { return openModal(); },
    }).createExport;
    var handleExportClick = function () {
        createExport({ data: { exportType: 'bigData' } });
    };
    var handleRequest = function () {
        return getBigData({
            data: {
                initialDate: filtersData === null || filtersData === void 0 ? void 0 : filtersData.initialDate,
                dataBase: filtersData === null || filtersData === void 0 ? void 0 : filtersData.dataBase,
                latitude: filtersData === null || filtersData === void 0 ? void 0 : filtersData.latitude,
                longitude: filtersData === null || filtersData === void 0 ? void 0 : filtersData.longitude,
            },
        });
    };
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(ExportPdfWarningModal, { opened: opened, onClose: closeModal }),
                React.createElement(LimitCallsModal, { opened: openedLimitCallsModal, onClose: closeLimitCallsModal, continueRequest: handleRequest }),
                React.createElement(Separator, { size: Spacing.XXSmall }),
                React.createElement(H1, null, strings.title),
                React.createElement(Separator, null),
                React.createElement(BigDataFiltersSection, { onFiltersHandleChange: setFiltersData, onExportSpreadsheet: handleExportClick, exportSpreadsheetLoading: exportSpreadsheetLoading, hasBigDataResult: !!((_a = data === null || data === void 0 ? void 0 : data.bigData) === null || _a === void 0 ? void 0 : _a.length) && !loading, onDataChange: handleRequest }),
                React.createElement(Separator, null)),
            React.createElement(Col, { md: 12 },
                React.createElement(LoadingState, { data: !!data, loading: loading },
                    React.createElement(LoadingState.NoData, null,
                        React.createElement(BigDataPlaceholder, null)),
                    React.createElement(LoadingState.Shimmer, null,
                        React.createElement(AgDashChartLoading, null)),
                    React.createElement(BigDataTable, { data: tableData, key: "big-data-table-".concat(filtersData === null || filtersData === void 0 ? void 0 : filtersData.dateFilter) }))),
            React.createElement(Col, { md: 12 },
                React.createElement(Separator, null),
                React.createElement(ContactUsCard, null),
                React.createElement(Separator, null)))));
};
function downloadFile(base64String, fileName) {
    var buffer = Buffer.from(base64String, 'base64');
    var blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
}
function aggregateData(data, dataFilter) {
    if (dataFilter === 'month') {
        var initialDate = new Date(data[0].date);
        var finalDate = new Date(data[data.length - 1].date);
        var result = [];
        var _loop_1 = function (i) {
            var filterByMonth = data.filter(function (row) { return isSameMonth(new Date(row.date), i); });
            // https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
            var offset = i.getTimezoneOffset();
            var dateWithHandledTimezone = new Date(i.getTime() - offset * 60 * 1000);
            var formatedMonth = dateWithHandledTimezone.toISOString().split('T')[0].substring(0, 7);
            result.push(__assign({ date: formatedMonth }, createAgregatedRow(filterByMonth)));
        };
        for (var i = initialDate; i.getTime() <= finalDate.getTime(); i = addMonths(i, 1)) {
            _loop_1(i);
        }
        return result;
    }
    return data.map(function (item) {
        return {
            accumulatedPrecipitation: item.accumulatedPrecipitation,
            date: item.date,
            par: item.par,
            relativeHumity: item.relativeHumity,
            temperatureMax: item.temperatureMax,
            temperatureMed: item.temperatureMed,
            temperatureMin: item.temperatureMin,
            windVelocity: item.windVelocity,
        };
    });
}
function precisionSum(data) {
    var numberOfDecimals = 8;
    return parseFloat(sum(data).toPrecision(numberOfDecimals));
}
function precisionMean(data) {
    var numberOfDecimals = 6;
    return parseFloat(mean(data).toPrecision(numberOfDecimals));
}
function createAgregatedRow(data) {
    return {
        par: precisionSum(data.map(function (value) { return value['par']; })),
        accumulatedPrecipitation: precisionSum(data.map(function (value) { return value['accumulatedPrecipitation']; })),
        relativeHumity: precisionMean(data.map(function (value) { return value['relativeHumity']; })),
        temperatureMax: precisionMean(data.map(function (value) { return value['temperatureMax']; })),
        temperatureMed: precisionMean(data.map(function (value) { return value['temperatureMed']; })),
        temperatureMin: precisionMean(data.map(function (value) { return value['temperatureMin']; })),
        windVelocity: precisionMean(data.map(function (value) { return value['windVelocity']; })),
    };
}
