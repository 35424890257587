import { isBefore, addMinutes } from 'date-fns';
import { decodeJwt } from 'jose';
import { useSyncTabLocalStorage } from '@app/core/storage';
import { useUserStore, userStoreInstance } from '@app/stores';
var isTokenValid = function (token) {
    var tokenData = token && decodeJwt(token);
    return isBefore(addMinutes(new Date(), 1), new Date(tokenData.exp * 1000));
};
export var useAuthorized = function () {
    var user = useUserStore()[0];
    var isAuthenticated = user === null || user === void 0 ? void 0 : user.token;
    var isRetokenValid = (user === null || user === void 0 ? void 0 : user.retoken) && isTokenValid(user.retoken);
    useSyncTabLocalStorage({
        storageKey: localStorage.User,
        onChange: function () { return userStoreInstance.refreshState(); },
    });
    return isRetokenValid && isAuthenticated && !!(user === null || user === void 0 ? void 0 : user.email);
};
