import React from 'react';
import { Outlet } from 'react-router-dom';
import { NavbarMenu } from '@app/components/org.navbar-menu/navbar-menu.component';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { ContentWrapper, PageLayoutWrapper } from './page-layout.component.style';
export var PageLayout = function () { return (React.createElement(PageLayoutWrapper, null,
    React.createElement(Grid, { fluid: true },
        React.createElement(Row, { nogutter: true },
            React.createElement(Col, { md: 4, lg: 3, xxl: 2 },
                React.createElement(NavbarMenu, null)),
            React.createElement(Col, { md: 8, lg: 9, xxl: 10 },
                React.createElement(ContentWrapper, null,
                    React.createElement(Outlet, null))))))); };
