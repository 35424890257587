import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { Document } from 'react-pdf';
import { Scroll } from '@app/components/atm.scroll';
import { Button } from '@atomic/atm.button';
import { Divider } from '@atomic/atm.divider';
import { H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Modal } from '@atomic/obj.modal';
import { strings } from './docs-modal.strings';
import { DocsContentWrapper, PageStyled } from './docs-modal.style';
export var DocsModal = function (props) {
    var _a = useState(null), totalPages = _a[0], setTotalPages = _a[1];
    var onDocumentLoadSuccess = function (_a) {
        var numPages = _a.numPages;
        setTotalPages(numPages);
    };
    var handleDownload = function () {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', props.docUrl, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (xhr.status === 200) {
                var blob = xhr.response;
                var fileName = props.title;
                saveAs(blob, fileName);
            }
        };
        xhr.send();
    };
    return (React.createElement(Modal, { opened: props.opened, onClose: props.onCloseModal, noGutter: true, maxWidth: MODAL_WIDTH },
        React.createElement(DocsContentWrapper, null,
            React.createElement(H2, { noMargin: true }, props.title)),
        React.createElement(Divider, null),
        React.createElement(DocsContentWrapper, null,
            React.createElement(Scroll, { height: SCROLL_HEIGHT },
                React.createElement(Document, { file: props.docUrl, onLoadSuccess: onDocumentLoadSuccess }, Array.from(new Array(totalPages), function (_, index) { return (React.createElement(PageStyled, { key: "page_".concat(index + 1), pageNumber: index + 1, renderTextLayer: false, renderAnnotationLayer: false })); })))),
        React.createElement(Divider, null),
        React.createElement(DocsContentWrapper, null,
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, { vAlign: 'center' },
                    React.createElement(Button, { expanded: true, variant: 'secondary', onClick: props.onCloseModal }, strings.buttons.close)),
                React.createElement(Hbox.Separator, null),
                React.createElement(Hbox.Item, null,
                    React.createElement(Button, { expanded: true, variant: 'primary', onClick: handleDownload }, strings.buttons.download))))));
};
var SCROLL_HEIGHT = '50vh';
var MODAL_WIDTH = '650px';
