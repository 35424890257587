var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useWhatsappUrlRedirect } from '@app/utils/whatsapp.redirect';
import WarningImage from '@assets/img/export-pdf-warning.png';
import { Button } from '@atomic/atm.button';
import { LazyLoadImage } from '@atomic/atm.lazy-load-image';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { exportPdfWarningModalStrings as strings } from './export-pdf-warning-modal.strings';
var Warning = function () { return React.createElement(LazyLoadImage, { src: WarningImage }); };
export var ExportPdfWarningModal = function (props) { return (React.createElement(Modal, __assign({}, props, { small: true }),
    React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(Separator, { size: Spacing.XSmall }),
            React.createElement(Warning, null))),
    React.createElement(Separator, { size: Spacing.Small }),
    React.createElement(Hbox, null,
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(H2, { center: true, noMargin: true }, strings.title),
            React.createElement(Body, { center: true, noMargin: true }, strings.description)),
        React.createElement(Hbox.Separator, { size: Spacing.Small })),
    React.createElement(Separator, { size: Spacing.Small }),
    React.createElement(Hbox, null,
        React.createElement(Hbox.Item, null,
            React.createElement(Button, { onClick: props.onClose, variant: 'secondary', expanded: true }, strings.buttons.close)),
        React.createElement(Hbox.Separator, { size: Spacing.Small }),
        React.createElement(Hbox.Item, null,
            React.createElement("a", { href: useWhatsappUrlRedirect(), target: '_blank', rel: 'noreferrer' },
                React.createElement(Button, { variant: 'primary', expanded: true, onClick: props.onClose }, strings.buttons.contactUs)))))); };
