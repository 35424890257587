import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
export function useAlterPassword(params) {
    var _a = useMutation('AlterPasswordMutation', params), alterPassword = _a[0], _b = _a[1], data = _b.data, error = _b.error, loading = _b.loading;
    return {
        alterPassword: alterPassword,
        data: data,
        error: error,
        loading: loading,
    };
}
