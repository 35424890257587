export var agDashProductivityChartStrings = {
    title: 'Previsão de produtividade',
    harvest: 'Safra',
    tooltip: {
        yeldGap: 'Yield Gap + Manejo: ',
        potentialProductivity: 'PP: ',
        attainableProductivity: 'PE: ',
    },
    legend: {
        yeldGap: 'Yield Gap + Manejo',
        potentialProductivity: 'PP (Produtividade Potencial)',
        attainableProductivity: 'PE (Produtividade Estimada)',
    },
    infoCard: 'A IMBR Agro disponibiliza valores estimados para a produtividade potencial e atingível do município eleito. A produtividade potencial é uma referência que considera fatores como genótipo da cultura e radiação. Já a produtividade atingível leva em conta fatores adicionais, como água e nutriente. Ambos os resultados são obtidos por modelos da IMBR Agro.',
};
