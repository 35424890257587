export var strings = {
    title: 'Entre ou Cadastre-se!',
    subtitle: 'Para entrar na plataforma é necessário entrar ou criar uma conta.',
    formlabel: {
        email: 'E-mail',
        password: 'Senha',
    },
    buttons: {
        signIn: 'Entrar',
        signUp: 'Cadastre-se!',
        forgotPassword: 'Esqueceu a senha?',
    },
    dividerConnective: 'OU',
    signUpPrefix: 'Ainda não tem uma conta? ',
    validatorMessage: {
        email: 'Endereço de e-mail inválido. Por favor forneça um e-mail válido',
    },
};
