import React from 'react';
export var ChatBubblesIcon = function () { return (React.createElement("svg", { width: '26vw', height: 'auto', viewBox: '0 0 396 207', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M265.599 1.17188H294.18C343.483 1.17188 383.496 41.4681 383.496 91.1188V204.812L358.488 181.066H265.599C216.297 181.066 176.284 140.77 176.284 91.1188C176.284 41.4681 216.297 1.17188 265.599 1.17188ZM308.471 94.7167C310.257 94.7167 312.044 92.9177 312.044 91.1188C312.044 89.3199 310.257 87.5209 308.471 87.5209C306.685 87.5209 304.898 89.3199 304.898 91.1188C304.898 92.9177 306.685 94.7167 308.471 94.7167ZM279.89 94.7167C281.676 94.7167 283.463 92.9177 283.463 91.1188C283.463 89.3199 281.676 87.5209 279.89 87.5209C278.104 87.5209 276.317 89.3199 276.317 91.1188C276.317 92.9177 278.104 94.7167 279.89 94.7167ZM251.309 94.7167C253.095 94.7167 254.882 92.9177 254.882 91.1188C254.882 89.3199 253.095 87.5209 251.309 87.5209C249.523 87.5209 247.736 89.3199 247.736 91.1188C247.736 92.9177 249.523 94.7167 251.309 94.7167Z', fill: '#034224' }),
    React.createElement("path", { d: 'M265.599 1.17188H294.18C343.483 1.17188 383.496 41.4681 383.496 91.1188V204.812L358.488 181.066H265.599C216.297 181.066 176.284 140.77 176.284 91.1188C176.284 41.4681 216.297 1.17188 265.599 1.17188ZM308.471 94.7167C310.257 94.7167 312.044 92.9177 312.044 91.1188C312.044 89.3199 310.257 87.5209 308.471 87.5209C306.685 87.5209 304.898 89.3199 304.898 91.1188C304.898 92.9177 306.685 94.7167 308.471 94.7167ZM279.89 94.7167C281.676 94.7167 283.463 92.9177 283.463 91.1188C283.463 89.3199 281.676 87.5209 279.89 87.5209C278.104 87.5209 276.317 89.3199 276.317 91.1188C276.317 92.9177 278.104 94.7167 279.89 94.7167ZM251.309 94.7167C253.095 94.7167 254.882 92.9177 254.882 91.1188C254.882 89.3199 253.095 87.5209 251.309 87.5209C249.523 87.5209 247.736 89.3199 247.736 91.1188C247.736 92.9177 249.523 94.7167 251.309 94.7167Z', fill: '#034224' }),
    React.createElement("rect", { x: '245.95', y: '66.5488', width: '8.93157', height: '49.1186', rx: '4.46579', fill: 'white' }),
    React.createElement("rect", { x: '274.531', y: '83.9746', width: '8.93157', height: '31.6894', rx: '4.46579', fill: 'white' }),
    React.createElement("rect", { x: '304.898', y: '74.4707', width: '8.93157', height: '41.1962', rx: '4.46579', fill: 'white' }),
    React.createElement("path", { d: 'M130.401 1.97852H101.82C52.5177 1.97852 12.5043 42.2747 12.5043 91.9255V205.618L37.5127 181.872H130.401C179.703 181.872 219.717 141.576 219.717 91.9255C219.717 42.2747 179.703 1.97852 130.401 1.97852ZM87.5294 95.5233C85.7431 95.5233 83.9568 93.7244 83.9568 91.9255C83.9568 90.1265 85.7431 88.3276 87.5294 88.3276C89.3158 88.3276 91.1021 90.1265 91.1021 91.9255C91.1021 93.7244 89.3158 95.5233 87.5294 95.5233ZM116.11 95.5233C114.324 95.5233 112.538 93.7244 112.538 91.9255C112.538 90.1265 114.324 88.3276 116.11 88.3276C117.897 88.3276 119.683 90.1265 119.683 91.9255C119.683 93.7244 117.897 95.5233 116.11 95.5233ZM144.691 95.5233C142.905 95.5233 141.119 93.7244 141.119 91.9255C141.119 90.1265 142.905 88.3276 144.691 88.3276C146.478 88.3276 148.264 90.1265 148.264 91.9255C148.264 93.7244 146.478 95.5233 144.691 95.5233Z', fill: '#FEFFFC' }),
    React.createElement("rect", { width: '8.93157', height: '49.1186', rx: '4.46579', transform: 'matrix(-1 0 0 1 150.05 67.3574)', fill: '#034224' }),
    React.createElement("rect", { width: '8.93157', height: '31.6894', rx: '4.46579', transform: 'matrix(-1 0 0 1 121.469 84.7871)', fill: '#034224' }),
    React.createElement("rect", { width: '8.93157', height: '41.1962', rx: '4.46579', transform: 'matrix(-1 0 0 1 91.1018 75.2793)', fill: '#034224' }))); };
