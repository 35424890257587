var FakeStorage = /** @class */ (function () {
    function FakeStorage() {
        this.storage = {};
    }
    FakeStorage.prototype.getItem = function (key) {
        return this.storage[key];
    };
    FakeStorage.prototype.setItem = function (key, data) {
        this.storage[key] = data;
    };
    return FakeStorage;
}());
export { FakeStorage };
