var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '@app/components/atm.back-button';
import { ImageBackground } from '@app/components/atm.image-background';
import { useFlashMessage } from '@app/components/obj.flash-message/flash-message.hook';
import { useParams } from '@app/core/route/query-params-hook';
import { useConfirmSignUp } from '@app/domain';
import { useSendConfirmationEmailMutation } from '@app/domain/user/send-confirmation-email.use-case';
import { AuthRoutes } from '@app/modules/auth/routes';
import { SpreadsheetRoutes } from '@app/modules/spreadsheet/routes';
import { useUserStore } from '@app/stores';
import BackgroundLoginImage from '@assets/img/background-login.jpg';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { Body, H1, H3, InputLabel } from '@atomic/atm.typography';
import { FlashMessage } from '@atomic/mol.flash-message';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Form, FormField, Validators } from '@atomic/obj.form';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { strings } from './signup-confirm.strings';
var TIMER_INITIAL_VALUE = 60;
export var SignUpConfirmPage = function () {
    var _a = useParams(), email = _a.email, code = _a.code;
    var _b = useUserStore(), setUser = _b[1];
    var _c = React.useState(false), disableResend = _c[0], setDisableResend = _c[1];
    var _d = React.useState(TIMER_INITIAL_VALUE), timer = _d[0], setTimer = _d[1];
    var showError = useFlashMessage().showError;
    var navigate = useNavigate();
    var _e = useConfirmSignUp({
        onCompleted: function (confirmSignUpResult) {
            var _a, _b, _c;
            setUser(__assign({ token: (_a = confirmSignUpResult === null || confirmSignUpResult === void 0 ? void 0 : confirmSignUpResult.confirmSignUp) === null || _a === void 0 ? void 0 : _a.token, retoken: (_b = confirmSignUpResult === null || confirmSignUpResult === void 0 ? void 0 : confirmSignUpResult.confirmSignUp) === null || _b === void 0 ? void 0 : _b.retoken }, (_c = confirmSignUpResult === null || confirmSignUpResult === void 0 ? void 0 : confirmSignUpResult.confirmSignUp) === null || _c === void 0 ? void 0 : _c.user));
            navigate("".concat(SpreadsheetRoutes.uploadSpreadsheet, "?showWelcomeModal=true"));
        },
    }), confirmSignUpMutation = _e.confirmSignUpMutation, loading = _e.loading, error = _e.error;
    React.useEffect(function () {
        if (timer <= 0) {
            setDisableResend(false);
            setTimer(60);
        }
        if (disableResend) {
            var interval_1 = setInterval(function () {
                setTimer(function (prev) { return (prev = prev - 1); });
            }, 1000);
            return function () { return clearInterval(interval_1); };
        }
    }, [disableResend, timer]);
    var _f = useSendConfirmationEmailMutation({
        onCompleted: function () {
            setDisableResend(true);
        },
        onError: function () {
            setDisableResend(true);
            showError(strings.feedback.error.sendConfirmationEmail);
        },
    }), sendConfirmationEmailMutation = _f.sendConfirmationEmailMutation, resendLoading = _f.loading;
    var handleResendEmail = function () { return sendConfirmationEmailMutation({ data: { email: email } }); };
    var handleSubmit = function (formData) {
        if (formData.hasError) {
            return;
        }
        confirmSignUpMutation({ data: { email: email, code: formData.data.code } });
    };
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, { nogutter: true },
            React.createElement(Col, { md: 6 },
                React.createElement(BackButton, { to: AuthRoutes.signup, text: strings.button.back }),
                React.createElement(Row, { justify: 'center', align: 'center', "$allHeight": true },
                    React.createElement(Col, { md: 10, lg: 8, xxl: 6 },
                        React.createElement(H1, null, strings.title),
                        !code ? React.createElement(Body, null, strings.subtitle) : null,
                        React.createElement(H3, null, email),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Form, { onSubmit: handleSubmit },
                            React.createElement(FormField, { label: strings.formLabel.code, name: Fields.code, validators: [Validators.Required()], initialValue: code },
                                React.createElement(InputLabel, null,
                                    React.createElement(TextField, { inputLabel: true }))),
                            React.createElement(Separator, null),
                            !!error && (React.createElement(FlashMessage, { type: 'alert', dismissible: true, mb: true }, error.message)),
                            React.createElement(Hbox, null,
                                React.createElement(Hbox.Item, { noGrow: true },
                                    React.createElement(Button, { variant: 'primary', type: 'submit', loading: loading }, strings.button.submitForm)),
                                React.createElement(Hbox.Item, { hAlign: 'flex-end', vAlign: 'center' },
                                    React.createElement(Button, { variant: 'link', onClick: handleResendEmail, loading: resendLoading, disabled: disableResend },
                                        strings.button.resendEmail,
                                        disableResend && " (".concat(timer, ")")))))))),
            React.createElement(Col, { md: 6 },
                React.createElement(ImageBackground, { src: BackgroundLoginImage })))));
};
var Fields = {
    code: 'code',
};
