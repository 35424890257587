export var strings = {
    title: 'Ative sua conta',
    subtitle: "Um c\u00F3digo foi enviado para o endere\u00E7o de email.",
    instruction: 'Insira abaixo o código enviado ao seu email',
    button: {
        submitForm: 'Enviar',
        resendEmail: 'Reenviar e-mail',
        back: 'Voltar para o cadastro',
    },
    formLabel: {
        code: 'Código',
    },
    feedback: {
        error: {
            sendConfirmationEmail: 'Erro ao enviar email',
        },
    },
};
