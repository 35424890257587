import { useNavigate } from 'react-router-dom';
import { useFlashMessage } from '@app/components/obj.flash-message/flash-message.hook';
import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { AuthRoutes } from '@app/modules/auth/routes';
import { useUserStore } from '@app/stores';
export function useDeleteAccount() {
    var navigate = useNavigate();
    var _a = useUserStore(), removeUser = _a[2];
    var _b = useFlashMessage(), showError = _b.showError, showSuccess = _b.showSuccess;
    var _c = useMutation('DeleteAccountMutation', {
        onCompleted: function (deleteResult) {
            removeUser(), showSuccess(deleteResult.deleteAccount);
            navigate(AuthRoutes.login);
        },
        onError: function (err) { return showError(err.message); },
    }), deleteAccountMutation = _c[0], _d = _c[1], data = _d.data, error = _d.error, loading = _d.loading;
    return {
        deleteAccountMutation: deleteAccountMutation,
        data: data,
        error: error,
        loading: loading,
    };
}
