import React from 'react';
import Lottie from 'lottie-react';
import agDashLoadingAnimation from '@assets/animations/ag-dash-loading.json';
import AgDash1 from '@assets/icons/ag-dash1.svg';
import AgDash2 from '@assets/icons/ag-dash2.svg';
import AgDash3 from '@assets/icons/ag-dash3.svg';
import RightArrow from '@assets/icons/right-arrow.icon.svg';
import { Card } from '@atomic/atm.card';
import { LazyLoadImage } from '@atomic/atm.lazy-load-image';
import { Body, H1, H2, HDisplay } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Shadow, Spacing } from '@atomic/obj.constants';
import { placeholderStrings, strings } from './ag-dash-agroclimate-evolution-chart.strings';
export var AgDashChartPlaceholder = function () { return (React.createElement(Card, { shadow: Shadow.Medium, center: true, allHeight: true },
    React.createElement(H2, { noMargin: true }, placeholderStrings.title),
    React.createElement(Separator, { size: Spacing.Small }),
    React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(LazyLoadImage, { src: AgDash1 }),
            React.createElement(Separator, { size: Spacing.XSmall }),
            React.createElement(H1, { noMargin: true, center: true }, placeholderStrings.instructions.one.title),
            React.createElement(Body, { center: true }, placeholderStrings.instructions.one.description)),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
            React.createElement(RightArrowComponent, null)),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(LazyLoadImage, { src: AgDash2 }),
            React.createElement(Separator, { size: Spacing.XSmall }),
            React.createElement(H1, { noMargin: true, center: true }, placeholderStrings.instructions.two.title),
            React.createElement(Body, { center: true }, placeholderStrings.instructions.two.description),
            React.createElement(Separator, { size: Spacing.XLarge })),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
            React.createElement(RightArrowComponent, null)),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(LazyLoadImage, { src: AgDash3 }),
            React.createElement(Separator, { size: Spacing.XSmall }),
            React.createElement(H1, { noMargin: true, center: true }, placeholderStrings.instructions.three.title),
            React.createElement(Body, { center: true }, placeholderStrings.instructions.two.description),
            React.createElement(Separator, { size: Spacing.XLarge }))))); };
var ANIMATION_SIZE = '400px';
export var AgDashChartLoading = function () { return (React.createElement(React.Fragment, null,
    React.createElement(Card, { shadow: Shadow.Medium },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { hAlign: 'center' },
                React.createElement(Lottie, { animationData: agDashLoadingAnimation, loop: true, style: { width: ANIMATION_SIZE, height: ANIMATION_SIZE } }))),
        React.createElement(Separator, { size: Spacing.Medium }),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { hAlign: 'center' },
                React.createElement(HDisplay, { center: true, color: 'PrimaryDark' }, strings.loading)))))); };
var RightArrowComponent = function () { return React.createElement(LazyLoadImage, { src: RightArrow }); };
