import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
export function useDemoSpreadsheet(params) {
    var _a = useMutation('DemoSpreadsheetMutation', params), demoSpreadsheet = _a[0], _b = _a[1], data = _b.data, error = _b.error, loading = _b.loading;
    return {
        demoSpreadsheet: demoSpreadsheet,
        data: data,
        error: error,
        loading: loading,
    };
}
