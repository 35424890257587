import { useNavigate } from 'react-router-dom';
import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { AuthRoutes } from '@app/modules/auth/routes';
import { useUserStore } from '@app/stores';
export function useLogout() {
    var navigate = useNavigate();
    var _a = useUserStore(), removeUser = _a[2];
    var _b = useMutation('LogoutMutation', {
        onCompleted: function () {
            removeUser();
            navigate(AuthRoutes.login);
        },
        onError: function () {
            removeUser();
            navigate(AuthRoutes.login);
        },
    }), logout = _b[0], _c = _b[1], data = _c.data, error = _c.error, loading = _c.loading;
    return {
        logout: logout,
        data: data,
        error: error,
        loading: loading,
    };
}
