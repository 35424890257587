var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Container, Col as FlexCol, Row as FlexRow } from 'react-grid-system';
import styled from 'styled-components';
import { Breakpoint, Spacing } from '@atomic/obj.constants';
var RowStyled = styled(FlexRow)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  margin-top: ", ";\n  ", "\n  ", "\n"], ["\n  margin-bottom: ", ";\n  margin-top: ", ";\n  ", "\n  ", "\n"])), function (props) { return (props.mb ? Spacing.Medium : '0px'); }, function (props) { return (props.mt ? Spacing.Medium : '0px'); }, function (props) { return props.$allHeight && 'height: 100%;'; }, function (props) {
    if (props.nogutter) {
        return "overflow: hidden;\n              margin-left: -".concat(rowMargin, "px !important;\n              margin-right: -").concat(rowMargin, "px !important;");
    }
    else {
        return '';
    }
});
export var Grid = Container;
export var Col = FlexCol;
export var Row = RowStyled;
export var GridSettings = {
    gridColumns: 12,
    // Defaults
    gutterWidth: 48,
    breakpoints: [Breakpoint.sm, Breakpoint.md, Breakpoint.lg, Breakpoint.xl, Breakpoint.xxl],
};
export var rowMargin = GridSettings.gutterWidth / 2;
var templateObject_1;
