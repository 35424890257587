var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Page } from 'react-pdf';
import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';
export var DocsContentWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), Spacing.Small);
export var PageStyled = styled(Page)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .react-pdf__Page__canvas {\n    width: 100% !important;\n    height: 100% !important;\n  }\n"], ["\n  .react-pdf__Page__canvas {\n    width: 100% !important;\n    height: 100% !important;\n  }\n"])));
var templateObject_1, templateObject_2;
