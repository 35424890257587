import * as React from 'react';
import { useLazyQuery as useApolloLazyQuery, useQuery as useApolloQuery, } from '@apollo/client';
import { getQuery } from '@app/data/graphql/graphql-docs.service';
import { mapApolloError } from './graphql-error.mapper';
var DEFAULT_CACHE_POLICY = 'cache-and-network';
export function useQuery(document, variables, options) {
    if (options === void 0) { options = {}; }
    if (!options.fetchPolicy) {
        options.fetchPolicy = DEFAULT_CACHE_POLICY;
    }
    var query = getQuery(document);
    var res = useApolloQuery(query, {
        variables: variables,
        onCompleted: options.onCompleted,
        onError: function (error) { var _a; return (_a = options.onError) === null || _a === void 0 ? void 0 : _a.call(options, mapApolloError(error)); },
        fetchPolicy: options.fetchPolicy,
        errorPolicy: options.errorPolicy,
        skip: options === null || options === void 0 ? void 0 : options.skip,
        notifyOnNetworkStatusChange: options.notifyOnNetworkStatusChange,
    });
    var fetchMore = React.useCallback(function (vars) {
        res.fetchMore(vars);
    }, [res]);
    var refetch = React.useCallback(function (vars) {
        res.refetch(vars);
    }, [res]);
    var updateQuery = React.useCallback(function (data) {
        res.updateQuery(function () { return data; });
    }, [res]);
    return {
        called: res.called,
        loading: res.loading,
        data: res.data,
        error: res.error && mapApolloError(res.error),
        fetchMore: fetchMore,
        refetch: refetch,
        updateQuery: updateQuery,
    };
}
export function useLazyQuery(document, options) {
    if (options === void 0) { options = {}; }
    if (!options.fetchPolicy) {
        options.fetchPolicy = DEFAULT_CACHE_POLICY;
    }
    var query = getQuery(document);
    var _a = useApolloLazyQuery(query, {
        onCompleted: options.onCompleted,
        onError: function (error) { var _a; return (_a = options.onError) === null || _a === void 0 ? void 0 : _a.call(options, mapApolloError(error)); },
        fetchPolicy: options.fetchPolicy,
        errorPolicy: options.errorPolicy,
    }), apolloQuery = _a[0], res = _a[1];
    var fetchMore = React.useCallback(function (variables) {
        res.fetchMore(variables);
    }, [res]);
    var refetch = React.useCallback(function (variables) {
        res.refetch(variables);
    }, [res]);
    var updateQuery = React.useCallback(function (data) {
        res.updateQuery(function () { return data; });
    }, [res]);
    var performQuery = React.useCallback(function (vars) {
        apolloQuery({ variables: vars });
    }, [apolloQuery]);
    return [
        performQuery,
        {
            called: res.called,
            loading: res.loading,
            data: res.data,
            error: res.error && mapApolloError(res.error),
            fetchMore: fetchMore,
            refetch: refetch,
            updateQuery: updateQuery,
        },
    ];
}
