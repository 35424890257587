var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { OptionWrapper } from '@app/components/atm.option-wrapper';
import { useFinalSelection, useInitialSelection } from '@app/domain';
import { FileExportIcon } from '@assets/icons/file-export';
import { Button } from '@atomic/atm.button';
import { ControlledSelect } from '@atomic/atm.controlled-select';
import { DatePicker } from '@atomic/atm.date-picker';
import { Body } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { ControlSizes, Spacing } from '@atomic/obj.constants';
import { countyMapped, cultureFiltered, getClosestDateToToday, getUniqueCyclesOptions, mapCultureOptions, unityFederativeList, } from './big-act-filter.mapper';
import { Inputs, strings } from './big-act-filter.strings';
export var BigActFilter = function (props) {
    var _a, _b;
    var _c = useInitialSelection(), getInitialOptions = _c[0], _d = _c[1], data = _d.data, loading = _d.loading;
    var _e = useState(), finalSelectionData = _e[0], setFinalSelectionData = _e[1];
    var _f = useState(), unityFederative = _f[0], setUnityFederative = _f[1];
    var _g = useState(), countyValue = _g[0], setCountyValue = _g[1];
    var _h = useState(), cultureValue = _h[0], setCultureValue = _h[1];
    var _j = useState(), cycleValue = _j[0], setCycleValue = _j[1];
    var _k = useState({
        primaryKey: new Date(),
        secoundaryKey: new Date(),
        thirdKey: new Date(),
        fourthKey: new Date(),
    }), keys = _k[0], setKeys = _k[1];
    var _l = useFinalSelection({
        onCompleted: function (selectionData) {
            setFinalSelectionData(selectionData);
        },
    }), getFinalOptions = _l[0], finalOptionsLoading = _l[1].loading;
    var handleUnityFederativeChange = function (value) {
        setUnityFederative(value);
        setCountyValue(undefined);
        setCultureValue(undefined);
        setCycleValue(undefined);
        setKeys(__assign(__assign({}, keys), { primaryKey: new Date() }));
        getInitialOptions({
            data: { uf: value },
        });
    };
    var handleCountyChange = function (value) {
        setCountyValue(value);
        setCultureValue(undefined);
        setCycleValue(undefined);
        props.onFiltersHandleChange(function () {
            return { idCounty: value };
        });
        setKeys(__assign(__assign({}, keys), { secoundaryKey: new Date() }));
    };
    var handleCultureChange = function (value) {
        setCultureValue(value);
        setCycleValue(undefined);
        setKeys(__assign(__assign({}, keys), { thirdKey: new Date() }));
        getFinalOptions({
            data: {
                idCounty: countyValue,
                idCulture: value,
            },
        });
        props.onFiltersHandleChange(function (prev) {
            return __assign(__assign({}, prev), { idCulture: value });
        });
    };
    var handleCycleChange = function (value) {
        setKeys(__assign(__assign({}, keys), { fourthKey: new Date() }));
        setCycleValue(value);
        props.onFiltersHandleChange(function (prev) {
            return __assign(__assign({}, prev), { cycle: value });
        });
    };
    var handleSwoingDateChange = function (value) {
        props.onFiltersHandleChange(function (prev) {
            return __assign(__assign({}, prev), { sowingDate: format(value, 'dd-MMM', { locale: ptBR }) });
        });
        props.onDataChange();
    };
    var mappedCultureOptions = mapCultureOptions(cultureFiltered(data, countyValue));
    return (React.createElement(Hbox, { "$wrap": true },
        React.createElement(Hbox.Item, null,
            React.createElement(Hbox, { "$wrap": true },
                React.createElement(Hbox.Item, { vAlign: 'center', noGrow: true },
                    React.createElement(Body, null, strings.filterLabel),
                    React.createElement(Separator, { size: Spacing.XSmall })),
                React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
                React.createElement(OptionWrapper, null,
                    React.createElement(Hbox.Item, { noGrow: true },
                        React.createElement(ControlledSelect, { name: Inputs.uf, options: unityFederativeList.map(function (item) {
                                return { label: item, value: item };
                            }), placeholder: strings.placeholder.uf, controlSize: ControlSizes.XSmall, onValueChange: handleUnityFederativeChange }),
                        React.createElement(Separator, { size: Spacing.XSmall })),
                    React.createElement(Hbox.Separator, { size: Spacing.XSmall })),
                React.createElement(OptionWrapper, null,
                    React.createElement(Hbox.Item, { noGrow: true },
                        React.createElement(ControlledSelect, { name: Inputs.county, options: countyMapped(data), placeholder: strings.placeholder.county, loading: loading, disabled: !unityFederative, onValueChange: handleCountyChange, key: "county-key-".concat(keys.primaryKey) }),
                        React.createElement(Separator, { size: Spacing.XSmall })),
                    React.createElement(Hbox.Separator, { size: Spacing.XSmall })),
                React.createElement(OptionWrapper, null,
                    React.createElement(Hbox.Item, { noGrow: true },
                        React.createElement(ControlledSelect, { name: Inputs.culture, options: mappedCultureOptions, placeholder: strings.placeholder.culture, disabled: !countyValue, onValueChange: handleCultureChange, controlSize: ControlSizes.XSmall, key: "culture-key-".concat(keys.primaryKey, "-").concat(keys.secoundaryKey) }),
                        React.createElement(Separator, { size: Spacing.XSmall })),
                    React.createElement(Hbox.Separator, { size: Spacing.XSmall })),
                React.createElement(OptionWrapper, null,
                    React.createElement(Hbox.Item, { noGrow: true },
                        React.createElement(ControlledSelect, { name: Inputs.cycle, options: !!finalSelectionData && !finalOptionsLoading ? getUniqueCyclesOptions(finalSelectionData) : [], loading: finalOptionsLoading, placeholder: strings.placeholder.cycle, disabled: !cultureValue, onValueChange: handleCycleChange, controlSize: ControlSizes.XSmall, key: "cycle-key-".concat(keys.primaryKey, "-").concat(keys.secoundaryKey, "-").concat(keys.thirdKey) }),
                        React.createElement(Separator, { size: Spacing.XSmall })),
                    React.createElement(Hbox.Separator, { size: Spacing.XSmall })),
                React.createElement(OptionWrapper, null,
                    React.createElement(Hbox.Item, { noGrow: true },
                        React.createElement(DatePicker, { name: Inputs.seeding, placeholder: strings.placeholder.seeding, disabled: !cycleValue, enableDates: (_b = (_a = finalSelectionData === null || finalSelectionData === void 0 ? void 0 : finalSelectionData.bigActFinalSelection) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new Date(item.sowingDate); })) !== null && _b !== void 0 ? _b : [], initialDateValue: getClosestDateToToday(finalSelectionData), key: "seeding-key-".concat(keys.primaryKey, "-").concat(keys.secoundaryKey, "-").concat(keys.thirdKey, "-").concat(keys.fourthKey), onValueChange: handleSwoingDateChange }),
                        React.createElement(Separator, { size: Spacing.XSmall })),
                    React.createElement(Hbox.Separator, { size: Spacing.Medium })))),
        React.createElement(Hbox.Item, { hAlign: 'flex-end', noGrow: true },
            React.createElement(Button, { variant: 'primary', disabled: !props.hasBigActResult, onClick: function () { return props.onExportPDF(true); }, loading: props.exportLoading },
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { vAlign: 'center' },
                        React.createElement(FileExportIcon, null)),
                    React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, { vAlign: 'center' }, strings.exportButton))),
            React.createElement(Separator, { size: Spacing.XSmall }))));
};
