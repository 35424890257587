import React from 'react';
import { Button } from '@atomic/atm.button';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { NavbarOptionStyled, NavbarOptionText } from './navbar-option.styled';
export var NavbarOption = function (props) { return (React.createElement(Hbox, null,
    React.createElement(Hbox.Item, null,
        React.createElement(Button, { variant: 'link', to: props.to },
            React.createElement(NavbarOptionStyled, { selected: props.selected },
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { noGrow: true }, props.selected ? props.icon.selected : props.icon.nonSelected),
                    React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, null,
                        React.createElement(NavbarOptionText, { color: 'White', noMargin: true, selected: props.selected }, props.name)))))),
    React.createElement(Hbox.Separator, { size: Spacing.Small }))); };
