import React from 'react';
import { TooltipBoldIcon } from '@assets/icons/tooltip-bold.icon';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { InfoCardStyled } from './info-card.styled';
export var InfoCard = function (_a) {
    var content = _a.content, error = _a.error;
    return (React.createElement(InfoCardStyled, { error: error },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(TooltipBoldIcon, null)),
            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
            React.createElement(Hbox.Item, null,
                React.createElement(Body, null, content)))));
};
