import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDemoSpreadsheet } from '@app/domain/spreadsheet';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { DemoSpreadsheetIcon } from '@assets/icons/demo-spreadsheet.icon';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { BodySecondary, H2, H4 } from '@atomic/atm.typography';
import { FlashMessage } from '@atomic/mol.flash-message';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { demoSpreadsheetCardStrings as strings } from './demo-spreadsheet-card.strings';
export var DemoSpreadsheetCard = function () {
    var navigate = useNavigate();
    var _a = useDemoSpreadsheet({
        onCompleted: function () {
            navigate(HomeRoutes.baseRoot);
        },
    }), demoSpreadsheet = _a.demoSpreadsheet, loading = _a.loading, error = _a.error;
    var handleSubmit = function () {
        demoSpreadsheet();
    };
    return (React.createElement(Card, { allHeight: true, border: true },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(H2, { center: true, noMargin: true }, strings.title),
                React.createElement(Separator, { size: Spacing.Small }),
                React.createElement(H4, { noMargin: true }, strings.subtitle),
                React.createElement(BodySecondary, null, strings.description),
                React.createElement(Separator, null),
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { hAlign: 'center' },
                        React.createElement(Separator, null),
                        React.createElement(DemoSpreadsheetIcon, null),
                        React.createElement(Separator, null))),
                React.createElement(Separator, null),
                !!error && (React.createElement(FlashMessage, { type: 'alert', dismissible: true, mb: true }, error.message)),
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { hAlign: 'center' },
                        React.createElement(Hbox.Separator, null),
                        React.createElement(Button, { variant: 'secondary', onClick: handleSubmit, loading: loading }, strings.button),
                        React.createElement(Hbox.Separator, null)))))));
};
