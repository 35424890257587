export var strings = {
    videoPlayer: {
        title: 'Apresentação da Plataforma  ',
        description: 'Reveja o vídeo de apresentação da plataforma!',
        button: 'Acessar vídeo',
    },
    termsOfUse: {
        title: 'Termos de Uso',
        description: 'Leia novamente o nosso termos de uso.',
        button: 'Acessar documento',
    },
    privacyPolicy: {
        title: 'Política de Privacidades',
        description: 'Leia novamente a nossa política de privacidade.',
        button: 'Acessar documento',
    },
    modalTitles: {
        termsOfUse: 'Termos de Uso do Serviço IMBR Agro',
        privacyPolice: 'Política de Privacidade do Serviço IMBR Agro',
    },
};
