import React from 'react';
import { ContactUsCard } from '@app/components/mol.contact-us-card';
import { QuickAccessSection } from '@app/components/mol.quick-access-section';
import { H1, H2 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { strings } from './help-center.page.strings';
export var HelpCenterPage = function () {
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(H1, null, strings.title),
                React.createElement(Separator, { size: Spacing.Large }),
                React.createElement(ContactUsCard, { large: true }),
                React.createElement(Separator, null),
                React.createElement(H2, { noMargin: true }, strings.subtitle),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(QuickAccessSection, null),
                React.createElement(Separator, { size: Spacing.Large })))));
};
