export var agDashCardsSectionString = {
    referenceProductivity: {
        title: 'Prod de referência',
        tooltip: {
            highlighted: 'Produtividade de referência: ',
            base: 'a produtividade de referência é a produtividade com máxima probabilidade de ocorrer no local, de acordo com o modelo de machine learning da IMBR Agro.',
        },
        units: 'sc/ha',
        description: 'Produtividade esperada para o local.',
    },
    latestPrediction: {
        title: 'Última previsão',
        tooltip: {
            highlighted: 'Última previsão: ',
            base: 'nosso modelo se baseia no balanço hídrico sequencial diário da cultura. Comparamos o balanço hídrico do dia com os valores históricos para realizar previsão diária.',
        },
        units: 'sc/ha',
        description: 'Previsão de acordo com o histórico do local.',
    },
    predominantSoil: {
        title: 'Solo predominante',
        tooltip: {
            highlighted: 'Solo: ',
            base: 'avaliamos os tipos de solo presentes dentro do município e apontamos como predominante aquele que aparece com maior frequência.',
        },
        units: 'mm/cm',
        defaultDescription: 'Solo de maior frequência no município e água disponível.',
        soilDescription: function (soil) { return "O ".concat(soil, " \u00E9 o solo predominante."); },
    },
    imbrScore: {
        title: 'Score IMBR',
        tooltip: {
            highlighted: 'Score IMBR: ',
            base: 'o score IMBR é o indicador de risco da IMBR Agro. Seu cálculo é baseado no histórico de produtividade atingível da simulação consultada.',
        },
        description: 'Valor varia de 0 (maior risco) até 100 (menor risco).',
    },
};
