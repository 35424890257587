export var bigDataFiltersSectionStrings = {
    placeholder: {
        base: 'Base:',
        spreadsheet: 'Fonte:',
        latitudeAndLongitude: 'Latitude/Longitude:',
        dataFilter: 'Data',
        initialDate: 'Data inicial',
    },
    filterBy: 'Agrupar por:',
    exportButton: 'Exportar',
};
