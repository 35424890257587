var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var VideoPlayerStyled = styled.iframe(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  border: 0;\n"], ["\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  border: 0;\n"])));
// 16:9 Aspect Ratio
var ASPECT_RATIO = '56.25%';
export var VideoPlayerWrapperStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  width: 100%;\n  padding-top: ", ";\n"], ["\n  position: relative;\n  overflow: hidden;\n  width: 100%;\n  padding-top: ", ";\n"])), ASPECT_RATIO);
var templateObject_1, templateObject_2;
