import React from 'react';
import { BigNumberCard } from '@app/components/mol.big-number-card';
import { useMatchMedia } from '@atomic/atm.match-media';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { bigActCardsSectionStrings as strings } from './big-act-cards-section.strings';
export var BigActCardsSection = function (props) {
    var isTablet = useMatchMedia('xxl').matchBreakpoint;
    var isSmall = useMatchMedia('lg').matchBreakpoint;
    return (React.createElement(Hbox, { "$wrap": true },
        React.createElement(Hbox.Item, null,
            React.createElement(BigNumberCard, { title: strings.averageProduction.title, toolTip: strings.averageProduction.tooltip, description: strings.averageProduction.description, units: strings.averageProduction.units, bigNumber: props.mediaProdutivity, loading: props.loading, rightTooltip: isSmall }),
            React.createElement(Separator, { size: Spacing.XSmall })),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, null,
            React.createElement(BigNumberCard, { title: strings.predominantSoil.title, toolTip: strings.predominantSoil.tooltip, description: strings.predominantSoil.description, units: strings.predominantSoil.units, bigNumber: props.predominantSoil, loading: props.loading, rightTooltip: isTablet }),
            React.createElement(Separator, { size: Spacing.XSmall })),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, null,
            React.createElement(BigNumberCard, { title: strings.imbrScore.title, toolTip: strings.imbrScore.tooltip, description: strings.imbrScore.description, bigNumber: props.riskScore, loading: props.loading, rightTooltip: true }),
            React.createElement(Separator, { size: Spacing.XSmall }))));
};
