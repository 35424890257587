import React from 'react';
import { MobileDisclaimer } from '@app/components/atm.mobile-disclaimer/mobile-disclaimer.component';
import { FlashMessageProvider } from '@app/components/obj.flash-message';
import { RootRouter } from './root.router';
function App() {
    return (React.createElement(FlashMessageProvider, null,
        React.createElement(RootRouter, null),
        React.createElement(MobileDisclaimer, null)));
}
export default App;
