export var uploadSpreadsheetCardStrings = {
    title: 'Opção 1',
    subtitle: 'Upload de planilha',
    description: {
        base: 'Para saber se sua planilha se encaixa em nosso padrão, ',
        link: 'baixe o nosso template',
        exclamationMark: '!',
    },
    button: 'Continuar com arquivo local',
};
