import React from 'react';
export var AgDashPlaceholderIcon = function () { return (React.createElement("svg", { width: '128', height: '111', viewBox: '0 0 128 111', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("g", { id: 'Gr\u00C3\u00A1fico Ag Dash' },
        React.createElement("g", { id: 'Charts' },
            React.createElement("path", { id: 'Vector', d: 'M110.146 13.3909H5.80617C3.04133 13.3909 0.799988 15.5506 0.799988 18.2146V105.427C0.799988 108.091 3.04133 110.251 5.80617 110.251H110.146C112.91 110.251 115.152 108.091 115.152 105.427V18.2146C115.152 15.5506 112.91 13.3909 110.146 13.3909Z', fill: 'white' }),
            React.createElement("path", { id: 'Vector_2', d: 'M7.994 94.7084H6.79999V98.6085H7.994V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_3', d: 'M17.7012 94.7084H16.5072V98.6085H17.7012V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_4', d: 'M27.4086 94.7084H26.2146V98.6085H27.4086V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_5', d: 'M37.1158 94.7084H35.9218V98.6085H37.1158V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_6', d: 'M46.8232 94.7084H45.6292V98.6085H46.8232V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_7', d: 'M56.531 94.7084H55.337V98.6085H56.531V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_8', d: 'M66.2383 94.7084H65.0443V98.6085H66.2383V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_9', d: 'M75.9456 94.7084H74.7516V98.6085H75.9456V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_10', d: 'M85.6528 94.7084H84.4588V98.6085H85.6528V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_11', d: 'M95.3601 94.7084H94.1661V98.6085H95.3601V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_12', d: 'M105.067 94.7084H103.873V98.6085H105.067V94.7084Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_13', d: 'M100.423 43.433C100.423 41.9327 98.832 40.9666 97.5006 41.6582L91.6794 44.6823C90.6987 45.1918 89.4907 44.8093 88.9819 43.8282L82.8284 31.9615C82.1627 30.6778 80.3966 30.5031 79.4922 31.6315L71.7705 41.2673C71.0677 42.1443 69.7805 42.2702 68.921 41.546L60.7812 34.6869L52.9769 26.1825C52.1759 25.3096 50.7959 25.3204 50.0087 26.2058L42.025 35.1852C41.8582 35.3729 41.7281 35.5903 41.6418 35.8261L33.0985 59.1444C32.6072 60.4854 30.9092 60.8941 29.8615 59.9236L22.734 53.321C22.0412 52.6792 20.994 52.6095 20.2223 53.1537L11.886 59.0319C11.3546 59.4066 11.0385 60.0162 11.0385 60.6664V82.1809C11.0385 83.2854 11.9339 84.1809 13.0385 84.1809H98.4226C99.5272 84.1809 100.423 83.2854 100.423 82.1809V43.433Z', fill: '#022B18' }),
            React.createElement("path", { id: 'Vector_14', d: 'M100.423 64.5605C100.423 63.1779 99.0532 62.2123 97.7509 62.6767L91.618 64.8633C90.646 65.2099 89.57 64.7601 89.1338 63.8249L82.7256 50.0862C82.111 48.7687 80.3406 48.5263 79.3945 49.6302L71.3534 59.0123C70.8295 59.6237 69.9899 59.8612 69.2233 59.615L62.4447 57.4378C61.4919 57.1318 60.4596 57.5771 60.0283 58.4802L53.514 72.1217C52.7528 73.7156 50.4502 73.6125 49.8346 71.957L43.6471 55.3178C43.0011 53.5806 40.5439 53.5806 39.8979 55.3178L33.106 73.5822C32.6099 74.9165 30.918 75.3199 29.8731 74.3531L23.1073 68.0929C22.2609 67.3098 20.9295 67.4027 20.2001 68.2956L11.4896 78.9584C11.1979 79.3155 11.0385 79.7625 11.0385 80.2237V86.8526C11.0385 87.9571 11.9339 88.8526 13.0385 88.8526H98.4226C99.5272 88.8526 100.423 87.9571 100.423 86.8526V64.5605Z', fill: '#80B735' }),
            React.createElement("path", { id: 'Vector_15', d: 'M126.375 40.0463C126.375 39.6823 126.341 39.3191 126.275 38.9607L128 37.4751C127.874 37.1124 127.721 36.7589 127.542 36.4174L125.219 36.5322C124.784 35.9341 124.24 35.4151 123.614 35L123.734 32.7824C123.377 32.6107 123.006 32.4645 122.626 32.3451L121.07 33.992C120.318 33.8635 119.548 33.8635 118.796 33.992L117.239 32.3451C116.859 32.4645 116.489 32.6107 116.131 32.7824L116.248 35C115.623 35.4161 115.081 35.9349 114.647 36.5322L112.323 36.4174C112.144 36.7589 111.991 37.1124 111.865 37.4751L113.591 38.9607C113.456 39.6787 113.456 40.4138 113.591 41.1318L111.865 42.6174C111.99 42.9803 112.144 43.3338 112.323 43.6751L114.647 43.5634C115.082 44.1591 115.624 44.6767 116.248 45.0925L116.131 47.3101C116.489 47.4818 116.859 47.628 117.239 47.7474L118.796 46.1005C119.548 46.229 120.318 46.229 121.07 46.1005L122.626 47.7474C123.006 47.6274 123.376 47.4813 123.734 47.3101L123.614 45.0925C124.24 44.6776 124.783 44.1599 125.219 43.5634L127.542 43.6751C127.722 43.3338 127.875 42.9803 128 42.6174L126.275 41.1318C126.341 40.7735 126.375 40.4102 126.375 40.0463ZM119.933 43.5324C119.211 43.5318 118.505 43.3268 117.905 42.9434C117.305 42.5599 116.837 42.0153 116.561 41.3782C116.285 40.7411 116.213 40.0403 116.355 39.3642C116.496 38.6882 116.844 38.0673 117.355 37.5801C117.866 37.0928 118.516 36.7611 119.225 36.6269C119.933 36.4926 120.667 36.5618 121.334 36.8258C122.001 37.0897 122.571 37.5366 122.973 38.1098C123.374 38.683 123.588 39.3569 123.588 40.0463C123.587 40.9711 123.201 41.8578 122.516 42.5115C121.831 43.1652 120.902 43.5324 119.933 43.5324Z', fill: '#034224' }),
            React.createElement("path", { id: 'Vector_16', d: 'M119.949 16.4616C119.949 15.7154 119.879 14.9707 119.743 14.2359L123.28 11.19C123.022 10.4465 122.708 9.72176 122.341 9.02158L117.578 9.25686C116.686 8.03052 115.572 6.9666 114.287 6.11553L114.534 1.56886C113.801 1.21685 113.042 0.917158 112.262 0.672241L109.071 4.04886C107.529 3.78531 105.951 3.78531 104.409 4.04886L101.218 0.672241C100.438 0.917158 99.6792 1.21685 98.9463 1.56886L99.1861 6.11553C97.905 6.96855 96.7931 8.03221 95.9021 9.25686L91.1393 9.02158C90.7717 9.72176 90.4578 10.4465 90.2 11.19L93.7372 14.2359C93.4611 15.708 93.4611 17.2152 93.7372 18.6872L90.2 21.7332C90.4566 22.4771 90.7705 23.2019 91.1393 23.9016L95.9021 23.6727C96.7951 24.8939 97.9068 25.9552 99.1861 26.8077L98.9463 31.3543C99.6792 31.7063 100.438 32.006 101.218 32.2509L104.409 28.8743C105.951 29.1379 107.529 29.1379 109.071 28.8743L112.262 32.2509C113.041 32.0049 113.8 31.7052 114.534 31.3543L114.287 26.8077C115.57 25.9571 116.684 24.8956 117.578 23.6727L122.341 23.9016C122.71 23.2019 123.024 22.4771 123.28 21.7332L119.743 18.6872C119.88 17.9525 119.949 17.2078 119.949 16.4616ZM106.74 23.6091C105.259 23.6078 103.813 23.1876 102.582 22.4014C101.352 21.6153 100.393 20.4986 99.8274 19.1924C99.2617 17.8863 99.1144 16.4493 99.4041 15.0633C99.6937 13.6772 100.407 12.4043 101.455 11.4053C102.502 10.4063 103.836 9.72625 105.288 9.45097C106.741 9.1757 108.246 9.31761 109.614 9.85877C110.981 10.3999 112.15 11.3161 112.973 12.4913C113.795 13.6666 114.234 15.0482 114.234 16.4616C114.232 18.3578 113.442 20.1758 112.037 21.516C110.632 22.8562 108.726 23.6091 106.74 23.6091Z', fill: '#034224' }))))); };
