var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Select from 'react-select';
import styled from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Border, Color, FontSize, Spacing } from '@atomic/obj.constants';
import { fieldCss } from '@atomic/obj.form/field.component.styled';
export var DatePickerSelectBaseStyled = styled(Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  appearance: none;\n  border: none;\n  outline: none;\n  ", ";\n  font-size: ", ";\n"], ["\n  appearance: none;\n  border: none;\n  outline: none;\n  ", ";\n  font-size: ", ";\n"])), fieldCss, FontSize.XXSmall);
var SELECT_MARGIN_TOP = '14px';
export var SelectIconStyled = styled(FaIcon.CaretDown)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  color: ", ";\n  pointer-events: none;\n"], ["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  color: ", ";\n  pointer-events: none;\n"])), SELECT_MARGIN_TOP, Spacing.XSmall, function (props) { return (props.$disabled ? Color.Gray : Color.GrayXDark); });
export var SelectStyle = function () { return ({
    container: function (baseStyles) { return (__assign(__assign({}, baseStyles), { paddingLeft: 0 })); },
    control: function (baseStyles, _a) {
        var isDisabled = _a.isDisabled;
        return (__assign(__assign({}, baseStyles), { height: '100%', borderRadius: Border.Radius, backgroundColor: isDisabled ? Color.GrayXLight : undefined, color: isDisabled ? Color.Gray : undefined, border: 'none', boxShadow: 'transparent' }));
    },
    indicatorSeparator: function () { return ({}); },
    indicatorsContainer: function () { return ({}); },
    option: function (baseStyles, _a) {
        var isFocused = _a.isFocused;
        return (__assign(__assign({}, baseStyles), { backgroundColor: isFocused ? Color.CallToActionXLight : undefined, color: Color.GrayXDark, ':active': __assign(__assign({}, baseStyles[':active']), { backgroundColor: Color.CallToActionLight }) }));
    },
    valueContainer: function (baseStyles) { return (__assign(__assign({}, baseStyles), { padding: 0 })); },
    input: function (baseStyles) { return (__assign(__assign({}, baseStyles), { padding: 0, margin: 0, outline: 'none' })); },
}); };
var templateObject_1, templateObject_2;
