var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/display-name */
import React from 'react';
import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faBan, faBus, faCheck, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faTimes, faUpload, faTimesCircle, faInfoCircle, faCheckCircle, faExclamationCircle, faHeart, faImage, faBars, faMicrophone, faStar, faStarHalf, faMinus, faPlus, faExclamationTriangle, faSearch, faCheckSquare, faDotCircle, faCaretDown, faEye, faEyeSlash, faFileArrowDown, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export var FaIcon = {
    Ban: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBan })); },
    Bus: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBus })); },
    Circle: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCircle })); },
    Check: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCheck })); },
    CheckSquare: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCheckSquare })); },
    ChevronDown: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronDown })); },
    ChevronLeft: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronLeft })); },
    ChevronRight: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronRight })); },
    ChevronUp: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronUp })); },
    CaretDown: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCaretDown })); },
    Close: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faTimes })); },
    DotCircle: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faDotCircle })); },
    FileExport: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faFileArrowDown })); },
    Eye: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faEye })); },
    EyeSlash: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faEyeSlash })); },
    FileUpload: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faUpload })); },
    FlashAlert: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faTimesCircle })); },
    FlashInfo: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faInfoCircle })); },
    FlashSuccess: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCheckCircle })); },
    FlashWarning: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faExclamationCircle })); },
    Heart: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faHeart })); },
    Image: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faImage })); },
    Menu: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBars })); },
    Microphone: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faMicrophone })); },
    Search: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faSearch })); },
    Square: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faSquare })); },
    Star: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faStar })); },
    StarHalf: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faStarHalf })); },
    StepperMinus: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faMinus })); },
    StepperPlus: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faPlus })); },
    Warning: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faExclamationTriangle })); },
};
