var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Spacing } from '@atomic/obj.constants';
import { TableBodyCell, TableHeaderCell, TableHeader, TableRowStyled, TableStyled, TableBody, TableHeaderTypography, TableBodyTypography, TableSeparator, TableButton, } from './table.component.style';
export var TR = TableRowStyled;
export var TD = TableBodyCell;
export var THead = TableHeader;
export var TH = TableHeaderCell;
export var THeadTypography = TableHeaderTypography;
export var TBodyTypography = TableBodyTypography;
export var TButton = TableButton;
var Table = /** @class */ (function (_super) {
    __extends(Table, _super);
    function Table() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Table.prototype.render = function () {
        var _a = this.getTypedChildren(), header = _a.header, body = _a.body;
        return (React.createElement(TableStyled, { cellSpacing: 0 },
            header,
            React.createElement(TableSeparator, { size: Spacing.XXSmall }),
            React.createElement(TableBody, null, body)));
    };
    Table.prototype.getTypedChildren = function () {
        var header;
        var body = [];
        React.Children.forEach(this.props.children, function (child) {
            if (child === null || child === void 0 ? void 0 : child.type) {
                switch (child.type) {
                    case THead:
                        header = child;
                        break;
                    default:
                        body.push(child);
                }
            }
            else {
                body.push(child);
            }
        });
        return { header: header, body: body };
    };
    return Table;
}(React.Component));
export { Table };
