var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { components } from 'react-select';
import { SelectIconStyled, SelectStyle, DatePickerSelectBaseStyled } from './date-picker-select.component.style';
export var DatePickerSelect = function (props) {
    var _a;
    return (React.createElement(DatePickerSelectBaseStyled, { isSearchable: false, isDisabled: props.disabled, options: props.options, onChange: props.onValueChange, components: { DropdownIndicator: DropdownIndicator }, styles: SelectStyle(), defaultValue: props.initialvalue, placeholder: (_a = props.placeholder) !== null && _a !== void 0 ? _a : 'Select' }));
};
var DropdownIndicator = function (props) {
    return (React.createElement(components.DropdownIndicator, __assign({}, props),
        React.createElement(SelectIconStyled, { "$disabled": props.isDisabled })));
};
