import React, { useState } from 'react';
import { InputCaptionWarning } from '@app/components/atm.input-caption-warning';
import { useFlashMessage } from '@app/components/obj.flash-message/flash-message.hook';
import { useAlterPassword } from '@app/domain/user/alter-password.use-case';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { TextField } from '@atomic/atm.text-field';
import { H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Shadow, Spacing } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { strings } from './alter-password-section.strings';
export var AlterPasswordSection = function () {
    var _a = React.useState(''), password = _a[0], setPassword = _a[1];
    var _b = useFlashMessage(), showSuccess = _b.showSuccess, showError = _b.showError;
    var _c = useState(), formKey = _c[0], setFormKey = _c[1];
    var _d = useAlterPassword({
        onCompleted: function (data) {
            showSuccess(data.alterPassword);
            setFormKey(new Date());
        },
        onError: function (err) {
            showError(err.message);
        },
    }), alterPassword = _d.alterPassword, loading = _d.loading;
    var handleSubmit = function (formData) {
        if (formData.hasError) {
            return;
        }
        alterPassword({
            data: { currentPassword: formData.data.currentPassword, newPassword: formData.data.newPassword },
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(H2, null, strings.title),
        React.createElement(Card, { shadow: Shadow.Medium },
            React.createElement(Form, { onSubmit: handleSubmit, key: "alter-password-form-".concat(formKey) },
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, null,
                        React.createElement(Form.Field, { name: Fields.currentPassword, label: strings.labels.currentPassword, validators: [Validators.Required(), Validators.PasswordRegex()] },
                            React.createElement(TextField, { type: 'password', inputLabel: true, hasIconEnd: true })),
                        React.createElement(Separator, { size: Spacing.Small }),
                        React.createElement(Form.Field, { name: Fields.newPassword, label: strings.labels.newPassword, validators: [Validators.Required(), Validators.PasswordRegex()], onValueChange: setPassword },
                            React.createElement(TextField, { type: 'password', inputLabel: true, hasIconEnd: true }),
                            React.createElement(InputCaptionWarning, null, strings.captionWarning)),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(Form.Field, { name: Fields.confirmNewPassword, label: strings.labels.confirmNewPassword, validators: [
                                Validators.Required(),
                                Validators.IsNotEqualToField(password, strings.validatorMessage.confirmPassword),
                            ] },
                            React.createElement(TextField, { type: 'password', inputLabel: true, hasIconEnd: true })))),
                React.createElement(Separator, { size: Spacing.Small }),
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { hAlign: 'center' },
                        React.createElement(Button, { variant: 'primary', type: 'submit', loading: loading }, strings.submitButton)))))));
};
var Fields = {
    currentPassword: 'currentPassword',
    newPassword: 'newPassword',
    confirmNewPassword: 'confirmNewPassword',
};
