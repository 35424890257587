var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import ColorFunc from 'color';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
// todo: import { Link } from '@app/core/route/link.component';
import { clickableFocusCss } from '@atomic/mol.focus/focus.style';
import { Border, Color, Spacing, FontFamily, FontSize, FontWeight } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
var btnColors = function (variant) {
    var solidColors = {
        primary: {
            default: { bg: Color.Primary, border: Color.Primary, text: Color.White },
            hover: { bg: Color.PrimaryDark, border: Color.PrimaryDark, text: Color.White },
            selected: { bg: Color.PrimaryXDark, border: Color.PrimaryXDark, text: Color.White },
            focus: { bg: Color.Primary, border: Color.CallToActionLight, text: Color.White },
            alert: { bg: Color.Alert, border: Color.Alert, text: Color.White },
        },
        secondary: {
            default: { bg: 'transparent', border: Color.Primary, text: Color.Primary },
            hover: { bg: 'transparent', border: Color.PrimaryDark, text: Color.PrimaryDark },
            selected: { bg: 'transparent', border: Color.PrimaryXDark, text: Color.PrimaryXDark },
            focus: { bg: 'transparent', border: Color.Primary, text: Color.Primary },
            alert: { bg: 'trasnparent', border: Color.Alert, text: Color.Alert },
        },
        callToAction: {
            default: { bg: Color.CallToActionLight, border: Color.CallToActionLight, text: Color.GrayXDark },
            hover: { bg: Color.CallToAction, border: Color.CallToAction, text: Color.GrayXDark },
            selected: { bg: Color.CallToActionDark, border: Color.CallToActionDark, text: Color.GrayXXDark },
            focus: { bg: Color.CallToActionLight, border: Color.PrimaryDark, text: Color.GrayXDark },
        },
        link: {
            default: { bg: 'transparent', border: 'transparent', text: Color.Primary },
            hover: { bg: 'transparent', border: 'transparent', text: Color.PrimaryDark },
            selected: { bg: 'transparent', border: 'transparent', text: Color.PrimaryXDark },
            focus: { bg: 'transparent', border: Color.PrimaryDark, text: Color.PrimaryDark },
            alert: { bg: 'transparent', border: 'transparent', text: Color.AlertDark },
        },
    };
    return solidColors[variant];
};
export var buttonHeight = '40px';
export var linkButtonHeight = '24px';
export var ButtonStyledCss = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  display: inline-block;\n  min-height: ", ";\n  line-height: ", ";\n  padding: 0 ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  text-decoration: none;\n  text-align: ", ";\n  ", "\n  cursor: ", ";\n  opacity: ", ";\n\n  /* this is useful when its father is pointer-events: none */\n  pointer-events: auto;\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n  border-style: solid;\n  border-width: ", ";\n  border-radius: ", ";\n\n  &:focus-visible {\n    ", "\n  }\n\n  ", "\n\n  ", "\n"], ["\n  position: relative;\n  width: ", ";\n  display: inline-block;\n  min-height: ", ";\n  line-height: ", ";\n  padding: 0 ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  text-decoration: none;\n  text-align: ", ";\n  ", "\n  cursor: ", ";\n  opacity: ", ";\n\n  /* this is useful when its father is pointer-events: none */\n  pointer-events: auto;\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n  border-style: solid;\n  border-width: ", ";\n  border-radius: ", ";\n\n  &:focus-visible {\n    ", "\n  }\n\n  ", "\n\n  ", "\n"])), function (props) { return (props.expanded ? '100%' : 'auto'); }, buttonHeight, buttonHeight, Spacing.Large, FontFamily.Primary, FontWeight.SemiBold, FontSize.Small, function (props) { return (props.expanded ? 'center' : 'left'); }, highlightStyle, function (props) { return (props.disabled ? 'default' : 'pointer'); }, function (props) { return (props.disabled || props.loading ? 0.5 : 1); }, function (props) { return (!props.alert ? btnColors(props.variant).default.text : btnColors(props.variant).alert.text); }, function (props) {
    return !props.alert ? btnColors(props.variant).default.bg : btnColors(props.variant).alert.bg;
}, function (props) {
    return !props.alert ? btnColors(props.variant).default.border : btnColors(props.variant).alert.border;
}, Border.Width, Border.Radius, clickableFocusCss, function (props) {
    return !props.disabled &&
        !props.alert &&
        "\n    &:hover {\n      color: ".concat(ColorFunc(btnColors(props.variant).hover.text), ";\n      background-color: ").concat(ColorFunc(btnColors(props.variant).hover.bg), ";\n      border-color: ").concat(btnColors(props.variant).hover.border, ";\n    }\n\n    &:active {\n      color: ").concat(ColorFunc(btnColors(props.variant).selected.text), ";\n      background-color: ").concat(ColorFunc(btnColors(props.variant).selected.bg), ";\n      border-color: ").concat(btnColors(props.variant).selected.border, ";\n    }\n\n    &:focus-visible {\n      color: ").concat(ColorFunc(btnColors(props.variant).focus.text), ";\n      background-color: ").concat(ColorFunc(btnColors(props.variant).focus.bg), ";\n      border-color: ").concat(btnColors(props.variant).focus.border, ";\n    }\n  ");
}, function (props) {
    return props.variant === 'link' &&
        "\n    min-height: ".concat(linkButtonHeight, ";\n    line-height: ").concat(linkButtonHeight, ";\n    padding:0;\n    &:hover {\n      text-decoration:underline;\n    }\n  ");
});
export var LinkButtonStyled = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ButtonStyledCss);
export var AnchorButtonStyled = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ButtonStyledCss);
export var ButtonStyled = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ButtonStyledCss);
export var ButtonContentStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  transition: all 0.2s ease-in-out;\n  opacity: ", ";\n  font-stretch: 100%;\n  vertical-align: middle;\n"], ["\n  transition: all 0.2s ease-in-out;\n  opacity: ", ";\n  font-stretch: 100%;\n  vertical-align: middle;\n"])), function (props) { return (props.processing ? 0 : 1); });
export var ButtonSpinnerStyled = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: ", " !important;\n  position: absolute;\n  right: calc(50% - 7px);\n  top: 0;\n"], ["\n  display: ", " !important;\n  position: absolute;\n  right: calc(50% - 7px);\n  top: 0;\n"])), function (props) { return (props.processing ? 'inline-block' : 'none'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
