export var strings = {
    title: 'Evolução da Produtividade por Safra',
    legend: {
        potentialProductivity: 'PP (Produtividade Potencial)',
        attainableProductivity: 'PA (Produtividade Atingível)',
    },
    tooltip: {
        potentialProductivity: 'PP: ',
        attainableProductivity: 'PA: ',
    },
    noData: {
        titile: 'Comece a visualizar seus dados:',
        instruction: 'Aplique os filtros no topo da página para começar a ver os gráficos!',
    },
    ibmrInfo: "A IMBR Agro disponibiliza seus valores hist\u00F3ricos de produtividade para a simula\u00E7\u00E3o do munic\u00EDpio eleito.\n                A produtividade potencial \u00E9 um valor de refer\u00EAncia que considera fatores como gen\u00F3tipo da cultura e\n                radia\u00E7\u00E3o, enquanto a produtividade ating\u00EDvel considera tamb\u00E9m fatores limitantes como \u00E1gua e sais\n                minerais. Ambos os resultados s\u00E3o fruto de modelos desenvolvidos pela IMBR Agro.",
};
