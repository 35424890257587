var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { addYears, subDays } from 'date-fns';
import { OptionWrapper } from '@app/components/atm.option-wrapper';
import { useSpreadsheet, useSpreadsheets } from '@app/domain';
import { FileExportIcon } from '@assets/icons/file-export';
import { Button } from '@atomic/atm.button';
import { ControlledSelect } from '@atomic/atm.controlled-select';
import { DatePicker } from '@atomic/atm.date-picker';
import { Body } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { ControlSizes, Spacing } from '@atomic/obj.constants';
import { bigDataFiltersSectionStrings as strings } from './big-data-filters-section.strings';
var UNAVAILABLE_DAYS_POWER = 4;
var UNAVAILABLE_DAYS_ERAS_5 = 7;
var ERAS_5_FIRST_YEAR = 1979;
export var BigDataFiltersSection = function (props) {
    var _a = React.useState(), selectedSpreadsheetId = _a[0], setSelectedSpreadsheetId = _a[1];
    var _b = React.useState(), selectedCoordinateId = _b[0], setSelectedCoordinatesId = _b[1];
    var _c = React.useState(), dataBase = _c[0], setDatabase = _c[1];
    var _d = useSpreadsheets(), spreadsheetsData = _d.data, spreadsheetsLoading = _d.loading;
    var _e = useSpreadsheet(), getSpreadsheet = _e[0], _f = _e[1], spreadsheetData = _f.data, spreadsheetLoading = _f.loading;
    var handleDataBaseChange = function (value) {
        setSelectedSpreadsheetId(undefined);
        setSelectedCoordinatesId(undefined);
        setDatabase(value.toString());
        props.onFiltersHandleChange(function () {
            return { dataBase: value.toString() === 'era5' ? 'era5' : 'power' };
        });
    };
    var handleSpreadsheetIdChange = function (value) {
        setSelectedCoordinatesId(undefined);
        setSelectedSpreadsheetId(value.toString());
    };
    var handleCoordinateIdChange = function (value) {
        var coordinateId = value.toString();
        setSelectedCoordinatesId(coordinateId);
        var latitude = parseFloat(spreadsheetData.spreadsheet.spreadsheetInfos.find(function (spreadsheet) { return spreadsheet.coordinateId === coordinateId; })
            .latitude);
        var longitude = parseFloat(spreadsheetData.spreadsheet.spreadsheetInfos.find(function (spreadsheet) { return spreadsheet.coordinateId === coordinateId; })
            .longitude);
        props.onFiltersHandleChange(function (prev) {
            return __assign(__assign({}, prev), { latitude: latitude, longitude: longitude });
        });
    };
    var handleDateChange = function (date) {
        // https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
        var offset = date.getTimezoneOffset();
        var dateWithHandledTimezone = new Date(date.getTime() - offset * 60 * 1000);
        var dateToYYYYMMDD = dateWithHandledTimezone.toISOString().split('T')[0];
        props.onFiltersHandleChange(function (prev) {
            return __assign(__assign({}, prev), { initialDate: dateToYYYYMMDD });
        });
        props.onDataChange();
    };
    var handleDateFilterChange = function (value) {
        return props.onFiltersHandleChange(function (prev) {
            return __assign(__assign({}, prev), { dateFilter: value.toString() });
        });
    };
    React.useEffect(function () {
        if (selectedSpreadsheetId) {
            getSpreadsheet({ data: { id: selectedSpreadsheetId } });
        }
    }, [getSpreadsheet, selectedCoordinateId, selectedSpreadsheetId, spreadsheetData]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Hbox, { "$wrap": true },
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(ControlledSelect, { options: baseOptions, loading: spreadsheetsLoading, placeholder: strings.placeholder.base, name: 'base', onValueChange: handleDataBaseChange }),
                React.createElement(Separator, null)),
            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
            React.createElement(OptionWrapper, null,
                React.createElement(Hbox.Item, { noGrow: true },
                    React.createElement(ControlledSelect, { options: spreadsheetsData && mapSpreadsheets(spreadsheetsData.spreadsheets), placeholder: strings.placeholder.spreadsheet, loading: spreadsheetsLoading && !!dataBase, name: 'spreadsheet', onValueChange: handleSpreadsheetIdChange, disabled: !dataBase, key: "big-data-spreadsheet-".concat(dataBase) }),
                    React.createElement(Separator, null)),
                React.createElement(Hbox.Separator, { size: Spacing.XSmall })),
            React.createElement(OptionWrapper, null,
                React.createElement(Hbox.Item, { noGrow: true },
                    React.createElement(ControlledSelect, { options: spreadsheetData && mapSpreadsheetInfo(spreadsheetData.spreadsheet.spreadsheetInfos), disabled: !selectedSpreadsheetId, placeholder: strings.placeholder.latitudeAndLongitude, name: 'latitude-and-longitude', onValueChange: handleCoordinateIdChange, key: "big-data-coordinate-id-".concat(selectedSpreadsheetId), controlSize: ControlSizes.Large, loading: spreadsheetLoading }),
                    React.createElement(Separator, null)),
                React.createElement(Hbox.Separator, { size: Spacing.XSmall })),
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(DatePicker, { placeholder: strings.placeholder.initialDate, disabled: !selectedCoordinateId, name: 'initial-date', onValueChange: handleDateChange, key: "big-data-initial-data-".concat(dataBase, "-").concat(selectedCoordinateId, "-").concat(selectedSpreadsheetId), excludeDateInterval: [
                        {
                            start: subDays(new Date(), dataBase === 'power' ? UNAVAILABLE_DAYS_POWER : UNAVAILABLE_DAYS_ERAS_5),
                            end: addYears(new Date(), 1),
                        },
                    ], firstYear: dataBase === 'era5' && ERAS_5_FIRST_YEAR }),
                React.createElement(Separator, null))),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(Body, null, strings.filterBy)),
            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
            React.createElement(OptionWrapper, null,
                React.createElement(Hbox.Item, { noGrow: true },
                    React.createElement(ControlledSelect, { options: filterOptions, disabled: !selectedCoordinateId, placeholder: strings.placeholder.dataFilter, name: 'date-filter', onValueChange: handleDateFilterChange, initialValue: filterOptions[1], key: "big-data-fiter-".concat(dataBase, "-").concat(selectedCoordinateId, "-").concat(selectedSpreadsheetId) })),
                React.createElement(Hbox.Separator, null)),
            React.createElement(Hbox.Item, { hAlign: 'flex-end' },
                React.createElement(Button, { variant: 'primary', onClick: props.onExportSpreadsheet, loading: props.exportSpreadsheetLoading, disabled: !props.hasBigDataResult },
                    React.createElement(Hbox, null,
                        React.createElement(Hbox.Item, { vAlign: 'center' },
                            React.createElement(FileExportIcon, null)),
                        React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                        React.createElement(Hbox.Item, { vAlign: 'center' }, strings.exportButton)))))));
};
function mapSpreadsheets(data) {
    return data.map(function (spreadsheet) {
        return {
            value: spreadsheet.id,
            label: spreadsheet.name,
        };
    });
}
function mapSpreadsheetInfo(data) {
    return data.map(function (spreadsheetInfo) {
        return {
            value: spreadsheetInfo.coordinateId,
            label: spreadsheetInfo.coordinateId,
        };
    });
}
var baseOptions = [
    { label: 'ERA5', value: 'era5' },
    { label: 'POWER', value: 'power' },
];
var filterOptions = [
    { label: 'Mês', value: 'month' },
    { label: 'Dia', value: 'day' },
];
