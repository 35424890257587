import { parseDate } from '@app/utils/date.parser';
var safeSaveInStorage = function (key, value) {
    if (!key || !value) {
        throw new Error('undefined key or value');
    }
    localStorage.setItem(key, JSON.stringify(value));
    return value;
};
export var LocalDataSource = {
    set: function (key, value) { return safeSaveInStorage(key, value); },
    get: function (key) { return JSON.parse(localStorage.getItem(key), jsonReviver); },
    remove: function (key) { return localStorage.removeItem(key); },
};
var jsonDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/gm;
var jsonReviver = function (_key, value) {
    if (typeof value === 'string' && jsonDateFormat.test(value)) {
        return parseDate(value);
    }
    return value;
};
