import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DemoSpreadsheetCard } from '@app/components/mol.demo-spreadsheet-card';
import { UploadSpreadsheetCard } from '@app/components/mol.upload-spreadsheet-card';
import { WelcomeModal } from '@app/components/mol.welcome-modal';
import { useParams } from '@app/core/route/query-params-hook';
import { SpreadsheetRoutes } from '@app/modules/spreadsheet/routes';
import { useUserStore } from '@app/stores';
import { useWhatsappUrlRedirect } from '@app/utils/whatsapp.redirect';
import { InfoMessageIcon } from '@assets/icons/info-message.icon';
import { WhatsappCtaIcon } from '@assets/icons/whatsapp-cta.icon';
import { Button } from '@atomic/atm.button';
import { useMatchMedia } from '@atomic/atm.match-media';
import { Body, BodySecondary, HDisplay, LinkSmall } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Grid, Row, Col } from '@atomic/obj.grid';
import { useModalState } from '@atomic/obj.modal/modal.component.hook';
import { uploadSpreadsheetPagesStrings as strings } from './upload-spreadsheet.page.strings';
import { InfoMessageBanner, UploadSpreadSheetPageWrapper } from './upload-spreadsheet.page.style';
export var UploadSpreadsheetPage = function () {
    var navigate = useNavigate();
    var matchBreakpoint = useMatchMedia('lg').matchBreakpoint;
    var user = useUserStore()[0];
    var showWelcomeModal = useParams().showWelcomeModal;
    var _a = useModalState(), openModal = _a.openModal, closeModal = _a.closeModal, opened = _a.opened;
    React.useEffect(function () {
        if (showWelcomeModal === 'true') {
            openModal();
            navigate(SpreadsheetRoutes.uploadSpreadsheet, { replace: true });
        }
    }, [navigate, openModal, showWelcomeModal]);
    return (React.createElement(UploadSpreadSheetPageWrapper, null,
        React.createElement(Grid, { fluid: true },
            React.createElement(Row, { justify: 'center', nogutter: true },
                React.createElement(WelcomeModal, { opened: opened, onCloseModal: closeModal, small: !matchBreakpoint }),
                React.createElement(Col, { md: 6, lg: 6, xxl: 6 },
                    React.createElement(HDisplay, { center: true }, strings.title(user.name)))),
            React.createElement(Row, { justify: 'center', nogutter: true },
                React.createElement(Col, { md: 6, lg: 6, xxl: 6 },
                    React.createElement(Body, { center: true }, strings.subtitle),
                    React.createElement(Separator, { size: Spacing.Large }))),
            React.createElement(Row, { justify: 'center', nogutter: true },
                React.createElement(Col, { md: 10, xxl: 9, xxxl: 6 },
                    React.createElement(Hbox, null,
                        React.createElement(Hbox.Item, null,
                            React.createElement(UploadSpreadsheetCard, null)),
                        React.createElement(Hbox.Separator, { size: Spacing.Large }),
                        React.createElement(Hbox.Item, { vAlign: 'center', hAlign: 'center', noGrow: true },
                            React.createElement(Body, null, strings.or)),
                        React.createElement(Hbox.Separator, { size: Spacing.Large }),
                        React.createElement(Hbox.Item, null,
                            React.createElement(DemoSpreadsheetCard, null))))),
            React.createElement(Row, { justify: 'center', nogutter: true },
                React.createElement(Col, { md: 10, xxl: 9, xxxl: 6 },
                    React.createElement(Separator, { size: Spacing.XSmall }),
                    React.createElement(InfoMessageBanner, null,
                        React.createElement(Hbox, null,
                            React.createElement(Hbox.Item, { noGrow: true },
                                React.createElement(InfoMessageIcon, null)),
                            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
                            React.createElement(Hbox.Item, { noGrow: true },
                                React.createElement(BodySecondary, null,
                                    strings.inforcard.base,
                                    React.createElement(LinkSmall, { href: useWhatsappUrlRedirect(), target: '_blank' }, strings.inforcard.link))))),
                    React.createElement(Separator, { size: Spacing.Large }))),
            React.createElement(Row, { nogutter: true, justify: 'center' },
                React.createElement(Col, { md: 10, xxl: 9, xxxl: 6 },
                    React.createElement(Separator, { size: Spacing.XSmall }),
                    React.createElement(Hbox, null,
                        React.createElement(Hbox.Item, { hAlign: 'flex-end' },
                            React.createElement(Button, { variant: 'link', to: useWhatsappUrlRedirect(), target: '_blank' },
                                React.createElement(WhatsappCtaIcon, null)))),
                    React.createElement(Separator, { size: Spacing.Large }))))));
};
