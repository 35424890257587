var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { InfoCard } from '@app/components/atm.info-card';
import { useAcceptDirectCommunication } from '@app/domain/user/accept-direct-communication.use-case';
import { useWhatsappUrlRedirect } from '@app/utils/whatsapp.redirect';
import WarningImage from '@assets/img/export-pdf-warning.png';
import { Button } from '@atomic/atm.button';
import { LazyLoadImage } from '@atomic/atm.lazy-load-image';
import { Body } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { limitCallsModalStrings as strings } from './limit-calls-modal.strings';
var Warning = function () { return React.createElement(LazyLoadImage, { src: WarningImage }); };
export var LimitCallsModal = function (props) {
    var acceptDirectCommunication = useAcceptDirectCommunication({
        onCompleted: function () {
            props.onClose();
            props.continueRequest();
        },
    }).acceptDirectCommunication;
    var whatsappRedirectLink = useWhatsappUrlRedirect();
    var handleClick = function () {
        acceptDirectCommunication();
    };
    return (React.createElement(Modal, __assign({}, props, { small: true }),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { hAlign: 'center' },
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(Warning, null))),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
            React.createElement(Hbox.Item, { hAlign: 'center' },
                React.createElement(Body, { center: true, noMargin: true }, strings.message)),
            React.createElement(Hbox.Separator, { size: Spacing.Small })),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(InfoCard, { content: strings.infoCard }),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { hAlign: 'center' },
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { noGrow: true },
                        React.createElement(Button, { variant: 'secondary', onClick: props.onClose, expanded: true }, strings.close)),
                    React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
                    React.createElement(Hbox.Item, { noGrow: true },
                        React.createElement(Button, { variant: 'primary', to: whatsappRedirectLink, target: '_blank' }, strings.contactUs)),
                    React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
                    React.createElement(Hbox.Item, { noGrow: true },
                        React.createElement(Button, { variant: 'primary', onClick: handleClick, expanded: true }, strings.accept)))))));
};
