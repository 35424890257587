import React from 'react';
import { InfoCard } from '@app/components/atm.info-card';
import { useMatchMedia } from '@atomic/atm.match-media';
import { mobileDisclaimerStrings as strings } from './mobile-disclaimer.strings';
import { MobileDisclaimerWrapper } from './mobile-disclaimer.styled';
export var MobileDisclaimer = function () {
    var isMobile = useMatchMedia('md').matchBreakpoint;
    return isMobile ? (React.createElement(MobileDisclaimerWrapper, null,
        React.createElement(InfoCard, { content: strings.content }))) : null;
};
