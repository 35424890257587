import { isCnpjPatternValid, isCnsValid, isCpfPatternValid, isDateValid, isDDValid, isEmailValid, isHeightValid, isNameValid, isPasswordValid, isPhoneValid, isSocialSecurityNumberValid, isWeightValid, isZipCodeValid, maxDate, } from './regex-validator';
import { defaultMessages } from './validators.string';
var Validators = /** @class */ (function () {
    function Validators() {
    }
    Validators.MaxLength = function (length, message) {
        return {
            error: {
                name: 'MaxLength',
                message: message,
            },
            validationFn: function (value) {
                return !value || value.toLocaleString().length <= length;
            },
        };
    };
    Validators.LettersOnly = function (acceptSpace, message) {
        return {
            error: {
                name: 'LettersOnly',
                message: message,
            },
            validationFn: function (value) {
                return value === null || value === void 0 ? void 0 : value.match(acceptSpace ? /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\s]*$/ : /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]*$/);
            },
        };
    };
    Validators.MinLength = function (length, message) {
        return {
            error: {
                name: 'MinLength',
                message: message,
            },
            validationFn: function (value) {
                return !value || value.toLocaleString().length >= length;
            },
        };
    };
    Validators.HeightRegex = function (message) {
        return {
            error: {
                name: 'HeightRegex',
                message: message,
            },
            validationFn: function (value) {
                return isHeightValid(value);
            },
        };
    };
    Validators.WeightRegex = function (message) {
        return {
            error: {
                name: 'WeightRegex',
                message: message,
            },
            validationFn: function (value) {
                return isWeightValid(value);
            },
        };
    };
    Validators.Required = function (message) {
        if (message === void 0) { message = defaultMessages.required; }
        return {
            error: {
                name: 'Required',
                message: message,
            },
            validationFn: function (value) {
                if (!(value === null || value === void 0 ? void 0 : value.length)) {
                    return false;
                }
                return (value && Object.keys(value).length > 0) || (value && !isNaN(value)) || value instanceof Date;
            },
        };
    };
    Validators.EmailRegex = function (message) {
        if (message === void 0) { message = defaultMessages.email; }
        return {
            error: {
                name: 'EmailRegex',
                message: message,
            },
            validationFn: function (value) {
                return isEmailValid(value);
            },
        };
    };
    Validators.CpfPatternRegex = function (message) {
        return {
            error: {
                name: 'Cpf pattern regex',
                message: message,
            },
            validationFn: function (value) {
                return isCpfPatternValid(value);
            },
        };
    };
    Validators.CnpjPatternRegex = function (message) {
        return {
            error: {
                name: 'Cnpj pattern regex',
                message: message,
            },
            validationFn: function (value) {
                return isCnpjPatternValid(value);
            },
        };
    };
    Validators.ZipCodeRegex = function (message) {
        return {
            error: {
                name: 'Zip code regex',
                message: message,
            },
            validationFn: function (value) {
                return isZipCodeValid(value);
            },
        };
    };
    Validators.PhoneRegex = function (message) {
        return {
            error: {
                name: 'PhoneRegex',
                message: message,
            },
            validationFn: function (value) {
                return isPhoneValid(value);
            },
        };
    };
    Validators.DDRegex = function (message) {
        return {
            error: {
                name: 'DDRegex',
                message: message,
            },
            validationFn: function (value) {
                return isDDValid(value);
            },
        };
    };
    Validators.PasswordRegex = function (message) {
        if (message === void 0) { message = defaultMessages.password; }
        return {
            error: {
                name: 'PasswordRegex',
                message: message,
            },
            validationFn: function (value) {
                return isPasswordValid(value);
            },
        };
    };
    Validators.FullNameRegex = function (message) {
        return {
            error: {
                name: 'FullNameRegex',
                message: message,
            },
            validationFn: function (value) {
                return isNameValid(value);
            },
        };
    };
    Validators.DateRegex = function (message) {
        return {
            error: {
                name: 'DateRegex',
                message: message,
            },
            validationFn: function (value) {
                return isDateValid(value);
            },
        };
    };
    Validators.MaxDate = function (max, message) {
        return {
            error: {
                name: 'MaxDate',
                message: message,
            },
            validationFn: function (value) {
                return !value || maxDate(max, value);
            },
        };
    };
    Validators.IsNotEqualToField = function (valueToCompare, message) {
        return {
            error: {
                name: 'IsNotEqualToField',
                message: message,
            },
            validationFn: function (value) {
                return value === valueToCompare;
            },
        };
    };
    // Validates Brazilian social security number
    Validators.SocialSecurityNumberRegex = function (message) {
        return {
            error: {
                name: 'SocialSecurityNumberRegex',
                message: message,
            },
            validationFn: function (value) {
                return isSocialSecurityNumberValid(value);
            },
        };
    };
    Validators.CnsRegex = function (message) {
        return {
            error: {
                name: 'CnsRegex',
                message: message,
            },
            validationFn: function (value) {
                return !value || isCnsValid(value);
            },
        };
    };
    return Validators;
}());
export { Validators };
