var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { AgDashAgroclimateEvolutionChart } from '@app/components/mol.ag-dash-agroclimate-evolution-chart';
import { AgDashChartPlaceholder, AgDashChartLoading, } from '@app/components/mol.ag-dash-agroclimate-evolution-chart/ag-dash-chart-placeholder.component';
import { AgDashFiltersSection } from '@app/components/mol.ag-dash-filters-section';
import { AgDashProductivityChart } from '@app/components/mol.ag-dash-productivity-chart';
import { AgDashVideoModal } from '@app/components/mol.ag-dash-video-modal/ag-dash-video-modal.component';
import { ContactUsCard } from '@app/components/mol.contact-us-card';
import { ExportPdfWarningModal } from '@app/components/mol.export-pdf-warning-modal';
import { LimitCallsModal } from '@app/components/mol.limit-calls-modal/limit-calls-modal.component';
import { ZarcModal } from '@app/components/mol.zarc-modal';
import { ExportPDF } from '@app/components/obj.export-pdf';
import { AgDashCardsSection } from '@app/components/org.ag-dash-cards-section';
import { useAgDash } from '@app/domain';
import { useValidateZarc } from '@app/domain/ag-dash/validate-zarc.use-case';
import { Button } from '@atomic/atm.button';
import { H1 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { useModalState } from '@atomic/obj.modal/modal.component.hook';
import { strings } from './ag-dash.strings';
export var AgDashPage = function () {
    var _a, _b, _c;
    var _d = React.useState(), agDashData = _d[0], setAgDashData = _d[1];
    var _e = React.useState(), filtersData = _e[0], setFiltersData = _e[1];
    var _f = React.useState(false), readyToExport = _f[0], setReadyToExport = _f[1];
    var _g = useModalState(), openModal = _g.openModal, closeModal = _g.closeModal, opened = _g.opened;
    var _h = useModalState(), openVideoModal = _h.openModal, closeVideoModal = _h.closeModal, openedVideoModal = _h.opened;
    var _j = useModalState(), openZarcModal = _j.openModal, closeZarcModal = _j.closeModal, openedZarcModal = _j.opened;
    var _k = useModalState(), openLimitCallsModal = _k.openModal, closeLimitCallsModal = _k.closeModal, openedLimitCallsModal = _k.opened;
    var _l = useAgDash({
        onCompleted: function (data) { return setAgDashData(data); },
        onError: function () { return openLimitCallsModal(); },
    }), getAgDash = _l[0], loading = _l[1].loading;
    var _m = useValidateZarc({
        onCompleted: function () { return handleAgDashRequest(); },
        onError: function () { return openZarcModal(); },
    }), validateZarc = _m[0], _o = _m[1], validateZarcLoading = _o.loading, validateZarcError = _o.error;
    var handleZarcRequest = function () {
        validateZarc({
            data: {
                idCulture: filtersData === null || filtersData === void 0 ? void 0 : filtersData.idCulture,
                idCycle: filtersData === null || filtersData === void 0 ? void 0 : filtersData.idCycle,
                latitude: filtersData === null || filtersData === void 0 ? void 0 : filtersData.latitude,
                longitude: filtersData === null || filtersData === void 0 ? void 0 : filtersData.longitude,
                sowingDate: filtersData === null || filtersData === void 0 ? void 0 : filtersData.sowingDate,
            },
        });
    };
    var handleAgDashRequest = function () {
        getAgDash({
            data: {
                idCulture: filtersData === null || filtersData === void 0 ? void 0 : filtersData.idCulture,
                idCycle: filtersData === null || filtersData === void 0 ? void 0 : filtersData.idCycle,
                latitude: filtersData === null || filtersData === void 0 ? void 0 : filtersData.latitude,
                longitude: filtersData === null || filtersData === void 0 ? void 0 : filtersData.longitude,
                sowingDate: filtersData === null || filtersData === void 0 ? void 0 : filtersData.sowingDate,
            },
        });
    };
    React.useEffect(function () {
        if (validateZarcLoading && agDashData) {
            setAgDashData(null);
        }
    }, [agDashData, validateZarcLoading]);
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, null,
                        React.createElement(H1, null, strings.title)),
                    React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                        React.createElement(Button, { variant: 'primary', onClick: openVideoModal }, strings.videoModal))),
                React.createElement(ExportPdfWarningModal, { opened: opened, onClose: closeModal }),
                React.createElement(AgDashVideoModal, { opened: openedVideoModal, onClose: closeVideoModal, small: true }),
                React.createElement(ZarcModal, { opened: openedZarcModal, onClose: closeZarcModal, small: true, onContinueRequest: handleAgDashRequest }),
                React.createElement(LimitCallsModal, { opened: openedLimitCallsModal, onClose: closeLimitCallsModal, continueRequest: handleAgDashRequest }),
                React.createElement(AgDashFiltersSection, { onFiltersHandleChange: setFiltersData, onExportPDF: setReadyToExport, disableExport: !agDashData, onGetData: handleZarcRequest, exportLoading: readyToExport, errorMessage: validateZarcError && strings.zarcInfoMessage }),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(ExportPDF, { readyToExport: readyToExport, exportType: 'agDash', onReadyToExport: setReadyToExport, openModal: openModal, filename: 'ag-dash-chart' },
                    React.createElement(AgDashCardsSection, __assign({}, (_a = agDashData === null || agDashData === void 0 ? void 0 : agDashData.agDash) === null || _a === void 0 ? void 0 : _a.result, { loading: loading || validateZarcLoading })),
                    React.createElement(LoadingState, { data: !!(agDashData === null || agDashData === void 0 ? void 0 : agDashData.agDash), loading: loading || validateZarcLoading },
                        React.createElement(LoadingState.Shimmer, null,
                            React.createElement(AgDashChartLoading, null)),
                        React.createElement(LoadingState.NoData, null,
                            React.createElement(AgDashChartPlaceholder, null)),
                        React.createElement(AgDashProductivityChart, { data: (_b = agDashData === null || agDashData === void 0 ? void 0 : agDashData.agDash) === null || _b === void 0 ? void 0 : _b.productivity }),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(AgDashAgroclimateEvolutionChart, { agricultureClimate: (_c = agDashData === null || agDashData === void 0 ? void 0 : agDashData.agDash) === null || _c === void 0 ? void 0 : _c.agricultureClimate }))),
                React.createElement(Separator, null),
                React.createElement(ContactUsCard, null),
                React.createElement(Separator, { size: Spacing.Large })))));
};
