import React from 'react';
import { useWhatsappUrlRedirect } from '@app/utils/whatsapp.redirect';
import { ChatBubblesIcon } from '@assets/icons/chat-bubbles-icon';
import { GreenChatBubbleIcon } from '@assets/icons/green-chat-bubble.icon';
import ArrowRight from '@assets/icons/right-arrow.icon.svg';
import ContactUsBackground from '@assets/img/contact-us-background.png';
import ContactUsIcon from '@assets/img/contact-us-icon.png';
import ContactUsMobileBackground from '@assets/img/contact-us-mobile-background.png';
import { Button } from '@atomic/atm.button';
import { useMatchMedia } from '@atomic/atm.match-media';
import { H4, HDisplay } from '@atomic/atm.typography';
import { AbsoluteBox } from '@atomic/obj.absolute-box';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing, ZIndex } from '@atomic/obj.constants';
import { contactUsCardStrings as strings } from './contact-us-card.strings';
import { ArrowIconStyled, ContactUsCardWrapper, ContentWrapper, LargeStyledIcon, StyledIcon, } from './contact-us-card.style';
export var ContactUsCard = function (props) {
    var matchBreakpoint = useMatchMedia('lg').matchBreakpoint;
    var isXxl = useMatchMedia('xxl').matchBreakpoint;
    var redirectLink = useWhatsappUrlRedirect();
    return (React.createElement(ContactUsCardWrapper, { large: props.large },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true }, props.large ? (React.createElement(React.Fragment, null,
                React.createElement(LargeStyledIcon, { src: ContactUsBackground, alt: strings.alt.contactUsIcon }),
                React.createElement(AbsoluteBox, { zIndex: ZIndex.first, top: matchBreakpoint ? CHAT_BUBLLES_POSITION.mobile.top : CHAT_BUBLLES_POSITION.desktop.top, left: matchBreakpoint ? CHAT_BUBLLES_POSITION.mobile.left : CHAT_BUBLLES_POSITION.desktop.left },
                    React.createElement(ChatBubblesIcon, null)))) : isXxl ? (React.createElement(React.Fragment, null,
                React.createElement(LargeStyledIcon, { src: ContactUsMobileBackground, alt: strings.alt.contactUsIcon, width: MOBILE_BACKGROUND_SIZE.width, height: MOBILE_BACKGROUND_SIZE.height }),
                React.createElement(AbsoluteBox, { zIndex: ZIndex.first },
                    React.createElement(GreenChatBubbleIcon, null)))) : (React.createElement(StyledIcon, { src: ContactUsIcon, alt: strings.alt.contactUsIcon }))),
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                props.large ? (React.createElement(ContentWrapper, null,
                    React.createElement(HDisplay, { noMargin: true }, strings.title),
                    React.createElement(Separator, { size: Spacing.Small }),
                    React.createElement(Hbox, null,
                        React.createElement(Hbox.Item, { hAlign: 'flex-end' },
                            React.createElement(Button, { variant: 'primary', to: redirectLink, target: '_blank' }, strings.link))))) : (React.createElement(H4, { noMargin: true }, strings.title)),
                isXxl && !props.large && (React.createElement(React.Fragment, null,
                    React.createElement(Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                        React.createElement(Button, { variant: 'link', to: redirectLink, target: '_blank' },
                            React.createElement(Hbox, null,
                                React.createElement(Hbox.Item, { noGrow: true }, strings.link),
                                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                                React.createElement(Hbox.Item, { noGrow: true },
                                    React.createElement(ArrowIconStyled, { alt: strings.alt.rightArrowIcon, src: ArrowRight })))))))),
            !props.large && !isXxl && (React.createElement(React.Fragment, null,
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                    React.createElement(Button, { variant: 'link', to: redirectLink, target: '_blank' },
                        React.createElement(Hbox, null,
                            React.createElement(Hbox.Item, { noGrow: true }, strings.link),
                            React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                            React.createElement(Hbox.Item, { noGrow: true },
                                React.createElement(ArrowIconStyled, { alt: strings.alt.rightArrowIcon, src: ArrowRight }))))))))));
};
var CHAT_BUBLLES_POSITION = {
    mobile: {
        top: '115px',
        left: '24px',
    },
    desktop: {
        top: '140px',
        left: '50px',
    },
};
var MOBILE_BACKGROUND_SIZE = {
    width: '240px',
    height: '200px',
};
