export var strings = {
    tableHead: {
        name: 'Nome',
        date: 'Data',
        config: 'Configurações',
    },
    button: {
        update: 'Atualizar',
        delete: 'Excluir',
    },
    updateSpreadsheetSuccess: 'Sua planilha foi atualizada com sucesso!',
};
