import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PageLayout } from '@app/components/mol.page-layout';
import { GuardElement } from '@app/core/route/guard-element.component';
import { AccountPage } from '@app/modules/account';
import { AccountRoutes } from '@app/modules/account/account.routes';
import { AgDashPage } from '@app/modules/ag-dash';
import { ForgotPasswordPage } from '@app/modules/auth/forgot-password';
import { LoginPage } from '@app/modules/auth/login';
import { PasswordRecoveryPage } from '@app/modules/auth/password-recovery';
import { AuthRoutes } from '@app/modules/auth/routes';
import { SignUpPage } from '@app/modules/auth/signup';
import { SignUpConfirmPage } from '@app/modules/auth/signup-confirm';
import { BigActPage } from '@app/modules/big-act';
import { BigActRoutes } from '@app/modules/big-act/big-act.routes';
import { BigDataPage } from '@app/modules/big-data';
import { HelpCenterPage } from '@app/modules/help-center';
import { HelpCenterRoutes } from '@app/modules/help-center/help-center.routes';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { SpreadsheetRoutes } from '@app/modules/spreadsheet/routes';
import { SpreadsheetAdminPage } from '@app/modules/spreadsheet/spreadsheet-admin';
import { UploadSpreadsheetPage } from '@app/modules/spreadsheet/upload-spreadsheet';
import { useAuthorized } from '@app/route-guard/useAuthGuard.hook';
import { useSpreadsheetSubmittedGuard } from '@app/route-guard/useSpreadsheetSubmittedGuard.hook';
export var RootRouter = function () {
    var isLogged = useAuthorized();
    return (React.createElement(Suspense, { fallback: React.createElement("div", null, "Loading...") },
        React.createElement(Routes, null,
            React.createElement(Route, { element: React.createElement(GuardElement, { useGuard: function () { return !isLogged; }, redirectPath: HomeRoutes.baseRoot }) },
                React.createElement(Route, { path: AuthRoutes.login, element: React.createElement(LoginPage, null) }),
                React.createElement(Route, { path: AuthRoutes.signup, element: React.createElement(SignUpPage, null) }),
                React.createElement(Route, { path: AuthRoutes.forgotPassword, element: React.createElement(ForgotPasswordPage, null) }),
                React.createElement(Route, { path: AuthRoutes.passwordRecovery, element: React.createElement(PasswordRecoveryPage, null) }),
                React.createElement(Route, { path: AuthRoutes.signupConfirm, element: React.createElement(SignUpConfirmPage, null) })),
            React.createElement(Route, { path: HomeRoutes.baseRoot, element: React.createElement(GuardElement, { useGuard: useAuthorized, redirectPath: AuthRoutes.login }) },
                React.createElement(Route, { path: SpreadsheetRoutes.uploadSpreadsheet, element: React.createElement(UploadSpreadsheetPage, null) }),
                React.createElement(Route, { path: HomeRoutes.baseRoot, element: React.createElement(GuardElement, { useGuard: useSpreadsheetSubmittedGuard, redirectPath: SpreadsheetRoutes.uploadSpreadsheet }) },
                    React.createElement(Route, { path: HomeRoutes.baseRoot, element: React.createElement(PageLayout, null) },
                        React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: SpreadsheetRoutes.spreadshhetAdmin }) }),
                        React.createElement(Route, { path: BigActRoutes.bigAct, element: React.createElement(BigActPage, null) }),
                        React.createElement(Route, { path: HomeRoutes.bigData, element: React.createElement(BigDataPage, null) }),
                        React.createElement(Route, { path: HomeRoutes.agDash, element: React.createElement(AgDashPage, null) }),
                        React.createElement(Route, { path: SpreadsheetRoutes.spreadshhetAdmin, element: React.createElement(SpreadsheetAdminPage, null) }),
                        React.createElement(Route, { path: AccountRoutes.account, element: React.createElement(AccountPage, null) }),
                        React.createElement(Route, { path: HelpCenterRoutes.base, element: React.createElement(HelpCenterPage, null) })))))));
};
