var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { components } from 'react-select';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Form } from '@atomic/obj.form';
import { SelectStyle, SelectIconStyled, BaseSelectStyled, LoadingIndicatorWrapper, } from './controlled-select.component.style';
export var ControlledSelect = function (props) {
    var _a = React.useState(''), value = _a[0], setValue = _a[1];
    var handleSelectChange = function (option) {
        setValue(option.value);
    };
    return (React.createElement(Form.Field, { label: props.label, name: props.name, value: value, onValueChange: props.onValueChange },
        React.createElement(BaseSelectStyled, { isDisabled: props.disabled, options: props.options, onChange: handleSelectChange, components: { DropdownIndicator: DropdownIndicator, LoadingIndicator: customLoadingIndicator }, styles: SelectStyle(props.controlSize), placeholder: props.loading ? '' : props.placeholder, isLoading: props.loading, defaultValue: props.initialValue })));
};
var DropdownIndicator = function (props) {
    return (React.createElement(components.DropdownIndicator, __assign({}, props),
        React.createElement(SelectIconStyled, { "$disabled": props.isDisabled })));
};
var customLoadingIndicator = function () { return (React.createElement(LoadingIndicatorWrapper, null,
    React.createElement(ActivityIndicator, { type: 'spinner' }))); };
