export var strings = {
    title: 'Redefinir sua senha',
    button: { submit: 'Enviar', back: 'Voltar para o Login' },
    formLabel: {
        email: 'Email',
        code: 'Código',
        password: 'Nova senha',
        confirmPassword: 'Confirmar-senha',
    },
    validator: {
        isNotEqual: 'As senhas não são iguais!',
    },
    expiredButton: 'Solicitar o envio do email novamente',
    expiredTitle: 'Seu código expirou!!',
};
