import React from 'react';
import { ImageBackground } from '@app/components/atm.image-background';
import { LoginForm } from '@app/components/mol.login-form';
import BackgroundLoginImage from '@assets/img/background-login.jpg';
import { Col, Grid, Row } from '@atomic/obj.grid';
export var LoginPage = function () {
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, { nogutter: true },
            React.createElement(Col, { md: 6 },
                React.createElement(Row, { justify: 'center', align: 'center', "$allHeight": true },
                    React.createElement(Col, { md: 10, lg: 8, xxl: 6 },
                        React.createElement(LoginForm, null)))),
            React.createElement(Col, { md: 6 },
                React.createElement(ImageBackground, { src: BackgroundLoginImage })))));
};
