var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Color } from '@atomic/obj.constants';
export var DropzoneWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border: ", " dashed ", ";\n  border-radius: ", ";\n  width: 100%;\n  cursor: pointer;\n  ", "\n\n  &: hover {\n    background: ", ";\n  }\n\n  & > p {\n    pointer-events: none;\n  }\n\n  ", "\n"], ["\n  background: ", ";\n  border: ", " dashed ", ";\n  border-radius: ", ";\n  width: 100%;\n  cursor: pointer;\n  ", "\n\n  &: hover {\n    background: ", ";\n  }\n\n  & > p {\n    pointer-events: none;\n  }\n\n  ", "\n"])), Color.GrayXXLight, Border.Color, Border.Width, Border.SmallRadius, function (_a) {
    var large = _a.large;
    return large &&
        "\n  height: ".concat(DROPZONE_HEIGHT_IN_ADMIN_PAGE, "vh; \n  display:flex;\n  align-items: center;\n  ");
}, Color.GrayXLight, function (_a) {
    var isDragging = _a.isDragging;
    return isDragging && "background: ".concat(Color.GrayLight, ";");
});
var DROPZONE_HEIGHT_IN_ADMIN_PAGE = 25;
var templateObject_1;
