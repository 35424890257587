var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';
export var ColorPallet = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  width: ", ";\n  height: ", ";\n"], ["\n  background-color: ", ";\n  width: ", ";\n  height: ", ";\n"])), function (_a) {
    var bgColor = _a.bgColor;
    return bgColor;
}, Spacing.XSmall, Spacing.XSmall);
var templateObject_1;
