import React, { useState } from 'react';
import { BigActChart } from '@app/components/mol.big-act-chart';
import { BigActFilter } from '@app/components/mol.big-act-filter';
import { ContactUsCard } from '@app/components/mol.contact-us-card';
import { ExportPdfWarningModal } from '@app/components/mol.export-pdf-warning-modal';
import { LimitCallsModal } from '@app/components/mol.limit-calls-modal/limit-calls-modal.component';
import { ExportPDF } from '@app/components/obj.export-pdf';
import { BigActCardsSection } from '@app/components/org.big-act-cards-section';
import { useBigActResult } from '@app/domain/big-act/big-act-result.use.case';
import { H1 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { useModalState } from '@atomic/obj.modal/modal.component.hook';
import { strings } from './big-act.page.strings';
export var BigActPage = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = useModalState(), openExportWarningModal = _j.openModal, closeExportWarningModal = _j.closeModal, openedExportWarningModal = _j.opened;
    var _k = useModalState(), openLimitCallsModal = _k.openModal, closeLimitCallsModal = _k.closeModal, openedLimitCallsModal = _k.opened;
    var _l = useState(false), readyToExport = _l[0], setReadyToExport = _l[1];
    var _m = useState(), filtersData = _m[0], setFiltersData = _m[1];
    var _o = useBigActResult({
        onError: function () { return handleError(); },
    }), getResult = _o[0], _p = _o[1], bigActResult = _p.data, resultLoading = _p.loading;
    var handleError = function () {
        openLimitCallsModal();
    };
    var handleGetResult = function () {
        getResult({
            data: {
                cycle: filtersData === null || filtersData === void 0 ? void 0 : filtersData.cycle,
                idCounty: filtersData === null || filtersData === void 0 ? void 0 : filtersData.idCounty,
                idCulture: filtersData === null || filtersData === void 0 ? void 0 : filtersData.idCulture,
                sowingDate: filtersData === null || filtersData === void 0 ? void 0 : filtersData.sowingDate,
            },
        });
    };
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(Separator, { size: Spacing.XXSmall }),
                React.createElement(H1, null, strings.title),
                React.createElement(ExportPdfWarningModal, { opened: openedExportWarningModal, onClose: closeExportWarningModal }),
                React.createElement(LimitCallsModal, { opened: openedLimitCallsModal, onClose: closeLimitCallsModal, continueRequest: handleGetResult }),
                React.createElement(BigActFilter, { onFiltersHandleChange: setFiltersData, onDataChange: handleGetResult, onExportPDF: setReadyToExport, exportLoading: readyToExport, hasBigActResult: !!((_b = (_a = bigActResult === null || bigActResult === void 0 ? void 0 : bigActResult.bigActResult) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.length) && !resultLoading }),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(ExportPDF, { exportType: 'bigAct', openModal: openExportWarningModal, onReadyToExport: setReadyToExport, readyToExport: readyToExport, filename: 'big-act-chart' },
                    React.createElement(BigActCardsSection, { mediaProdutivity: (_d = (_c = bigActResult === null || bigActResult === void 0 ? void 0 : bigActResult.bigActResult) === null || _c === void 0 ? void 0 : _c.productivityMedia) === null || _d === void 0 ? void 0 : _d.productivityMedia, predominantSoil: (_f = (_e = bigActResult === null || bigActResult === void 0 ? void 0 : bigActResult.bigActResult) === null || _e === void 0 ? void 0 : _e.productivityMedia) === null || _f === void 0 ? void 0 : _f.soil, riskScore: (_h = (_g = bigActResult === null || bigActResult === void 0 ? void 0 : bigActResult.bigActResult) === null || _g === void 0 ? void 0 : _g.productivityMedia) === null || _h === void 0 ? void 0 : _h.riskIndicator, loading: resultLoading }),
                    React.createElement(BigActChart, { data: bigActResult, loading: resultLoading })),
                React.createElement(Separator, null),
                React.createElement(ContactUsCard, null),
                React.createElement(Separator, null)))));
};
