export var bigActCardsSectionStrings = {
    averageProduction: {
        title: 'Produtividade Média',
        tooltip: {
            highlighted: 'Produtividade Média: ',
            base: 'a produtividade média indica o valor médio de produtividade, em sc/ha, da simulação consultada do município eleito.',
        },
        units: 'sc/ha',
        description: 'Quantidade de sacas por hectares esperadas para esse ciclo.',
    },
    predominantSoil: {
        title: 'Solo Predominante',
        tooltip: {
            highlighted: 'Solo: ',
            base: 'avaliamos os tipos de solo presentes dentro do município e apontamos como predominante aquele que aparece com maior frequência.',
        },
        units: 'mm/cm',
        description: 'Solo de maior frequência no município e água disponível.',
    },
    imbrScore: {
        title: 'Score IMBR',
        tooltip: {
            highlighted: 'Score IMBR: ',
            base: 'o score IMBR é o indicador de risco da IMBR Agro. Seu cálculo é baseado no histórico de produtividade atingível da simulação consultada.',
        },
        description: 'Valor varia de 0 (maior risco) até 100 (menor risco).',
    },
};
