var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { PasswordHiddenIcon, PasswordShowIcon, TextFieldDismissButtonStyled, TextFieldIconStyled, TextFieldIconWrapperStyled, TextFieldMaskedStyled, TextFieldStyled, TextFieldWrapperStyled, } from './text-field.component.style';
var TextField = /** @class */ (function (_super) {
    __extends(TextField, _super);
    function TextField(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChangeText = function (eventOrText) {
            var _a, _b;
            var value;
            if (typeof eventOrText === 'string') {
                value = eventOrText;
            }
            else {
                value = eventOrText.currentTarget.value;
            }
            if (((_a = _this.state) === null || _a === void 0 ? void 0 : _a.value) === value) {
                return;
            }
            if (_this.props.onValueChange) {
                (_b = _this.props) === null || _b === void 0 ? void 0 : _b.onValueChange(value);
            }
            if (_this.isControlled()) {
                return;
            }
            if (_this.formFieldConsumer) {
                _this.formFieldConsumer.onValueChange(value, null);
            }
            else {
                _this.setState({ value: value });
            }
        };
        _this.handleInput = function (event) {
            var nextValue = event.currentTarget.value;
            var currentValue = _this.getCurrentValue();
            // HACK: "not fired by keyboard" means that who is calling onInput
            // is not the user by typing letters on the keyboard.
            // For instance, it can be fired by pasting some value or
            // by using form auto-complete.
            // Why is this necessary? auto-complete doesn't fire onChange event
            // but it fires onInput.
            // If you don't handle onInput, some bugs may appear if you use
            // auto-complete on Chrome iOS
            var notFiredByKeyboardTyping = (nextValue.length ? nextValue.length : 0) - (currentValue ? currentValue.length : 0) > 1;
            if (notFiredByKeyboardTyping) {
                event.preventDefault();
                _this.handleChangeText(nextValue);
            }
        };
        _this.handleFocus = function (event) {
            var _a, _b, _c;
            (_a = _this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.onFocusChange(true);
            (_c = (_b = _this.props).onFocus) === null || _c === void 0 ? void 0 : _c.call(_b, event);
        };
        _this.handleBlur = function (event) {
            var _a, _b, _c;
            (_a = _this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.onFocusChange(false);
            (_c = (_b = _this.props).onBlur) === null || _c === void 0 ? void 0 : _c.call(_b, event);
        };
        _this.handleDismiss = function () {
            _this.handleChangeText('');
        };
        _this.isControlled = function () {
            return _this.props.value !== undefined;
        };
        _this.getCurrentValue = function () {
            var _a;
            if (_this.isControlled()) {
                return _this.props.value;
            }
            return ((_a = _this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.value) ? _this.formFieldConsumer.value : _this.state.value;
        };
        _this.state = {
            value: props.initialValue || props.value,
            passwordHidden: true,
        };
        return _this;
    }
    TextField.prototype.render = function () {
        var _this = this;
        /* eslint-disable @typescript-eslint/no-unused-vars */
        var _a = this.props, autoComplete = _a.autoComplete, onValueChange = _a.onValueChange, variant = _a.variant, givenType = _a.type, icon = _a.icon, onChange = _a.onChange, value = _a.value, options = _a.options, dismissable = _a.dismissable, initialValue = _a.initialValue, onBlur = _a.onBlur, onFocus = _a.onFocus, inputLabel = _a.inputLabel, hasIconEnd = _a.hasIconEnd, other = __rest(_a, ["autoComplete", "onValueChange", "variant", "type", "icon", "onChange", "value", "options", "dismissable", "initialValue", "onBlur", "onFocus", "inputLabel", "hasIconEnd"]);
        /* eslint-enable @typescript-eslint/no-unused-vars */
        var type = givenType;
        var handleIconClick;
        if (type === 'password') {
            handleIconClick = function () {
                return _this.setState(function (prev) { return ({
                    passwordHidden: !prev.passwordHidden,
                }); });
            };
            if (!this.state.passwordHidden) {
                type = 'text';
            }
        }
        return (React.createElement(FormFieldContext.Consumer, null, function (formFieldConsumer) {
            var _a, _b, _c, _d;
            _this.formFieldConsumer = formFieldConsumer;
            var val = _this.getCurrentValue();
            var hasError = Boolean((_b = (_a = _this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length);
            var filled = ((_c = _this.formFieldConsumer) === null || _c === void 0 ? void 0 : _c.value) &&
                ((_d = _this.formFieldConsumer) === null || _d === void 0 ? void 0 : _d.value) !== '' &&
                !hasError &&
                !_this.props.disabled &&
                !_this.formFieldConsumer.focus;
            return (React.createElement(React.Fragment, null,
                icon && (React.createElement(TextFieldIconWrapperStyled, null,
                    React.createElement(TextFieldIconStyled, null))),
                variant === 'normal' ? (React.createElement(TextFieldWrapperStyled, null,
                    React.createElement(TextFieldStyled, __assign({ value: val !== null && val !== void 0 ? val : '', onChange: _this.handleChangeText, icon: icon, autoComplete: autoComplete, onInput: _this.handleInput, onBlur: _this.handleBlur, onFocus: _this.handleFocus, inputLabel: inputLabel, invalid: hasError, filled: filled, type: type }, other)),
                    dismissable && (val === null || val === void 0 ? void 0 : val.length) > 0 && React.createElement(TextFieldDismissButtonStyled, { onClick: _this.handleDismiss }),
                    hasIconEnd && (React.createElement(TextFieldIconWrapperStyled, null, _this.state.passwordHidden ? (React.createElement(PasswordShowIcon, { onClick: handleIconClick })) : (React.createElement(PasswordHiddenIcon, { onClick: handleIconClick })))))) : (React.createElement(TextFieldWrapperStyled, null,
                    React.createElement(TextFieldMaskedStyled, __assign({ kind: variant, options: options, value: val !== null && val !== void 0 ? val : '', onChangeText: _this.handleChangeText, icon: icon, autoComplete: autoComplete, onInput: _this.handleInput, onBlur: _this.handleBlur, onFocus: _this.handleFocus, inputLabel: inputLabel, invalid: hasError, filled: filled }, other)),
                    dismissable && val && val.length > 0 && (React.createElement(TextFieldDismissButtonStyled, { onClick: _this.handleDismiss }))))));
        }));
    };
    TextField.prototype.componentDidMount = function () {
        if (this.isControlled() && this.props.initialValue !== undefined) {
            throw new Error('Use either the initialValue prop, or the value prop, but not both');
        }
        if (this.formFieldConsumer) {
            if (this.isControlled() || this.props.initialValue !== undefined) {
                if (this.formFieldConsumer.value) {
                    throw new Error('Please, use either value props in <TextField> or <Form.Field> component.');
                }
                this.formFieldConsumer.onValueChange(this.state.value, null);
            }
        }
    };
    TextField.prototype.componentDidUpdate = function (prevProps) {
        var _a, _b, _c;
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.value);
            (_c = this.formFieldConsumer) === null || _c === void 0 ? void 0 : _c.onValueChange(this.props.value, null);
            this.setState({ value: this.props.value });
        }
    };
    TextField.defaultProps = {
        variant: 'normal',
    };
    return TextField;
}(React.Component));
export { TextField };
