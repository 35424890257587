var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import CountUp from 'react-countup';
import { Tooltip } from '@app/components/atm.tooltip';
import { BodySecondary, H3, HDisplay } from '@atomic/atm.typography';
import { ThumbShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Hbox, Separator } from '@atomic/obj.box';
import { Shadow, Spacing } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { BigNumberCardStyled, UnitsTypography } from './big-number-card.style';
var NO_DATA_BIG_NUMBER = '00,00';
var COUNT_UP_DURATION = 1.5;
var DECIMAL_PLACES = 2;
export var BigNumberCard = function (props) { return (React.createElement(BigNumberCardStyled, { allHeight: true, shadow: Shadow.Medium },
    React.createElement(Hbox, null,
        React.createElement(Hbox.Item, null,
            React.createElement(H3, { noMargin: true }, props.title)),
        React.createElement(Hbox.Item, { noGrow: true },
            React.createElement(Tooltip, __assign({}, props.toolTip, { right: props.rightTooltip })))),
    React.createElement(Separator, { size: Spacing.XSmall }),
    React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { noGrow: true },
            React.createElement(LoadingState, { loading: props.loading, data: !!props.bigNumber },
                React.createElement(LoadingState.Shimmer, null,
                    React.createElement(BigNumberShimmer, null)),
                React.createElement(LoadingState.NoData, null,
                    React.createElement(HDisplay, { color: 'Primary', noData: true }, NO_DATA_BIG_NUMBER)),
                React.createElement(HDisplay, { color: 'Primary' },
                    React.createElement(CountUp, { duration: COUNT_UP_DURATION, end: props.bigNumber, decimalPlaces: DECIMAL_PLACES, decimals: DECIMAL_PLACES, decimal: ',' })))),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        props.units && (React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
            React.createElement(UnitsTypography, null, props.units)))),
    React.createElement(Separator, { size: Spacing.XSmall }),
    React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { noGrow: true },
            React.createElement(BodySecondary, null, props.description))))); };
var BigNumberShimmer = function () { return React.createElement(ThumbShimmerBoxStyled, { width: '150px', height: '40px' }); };
