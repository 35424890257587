import React from 'react';
import { BackArrow } from '@assets/icons/back-arrow';
import { Button } from '@atomic/atm.button';
import { AbsoluteBox } from '@atomic/obj.absolute-box';
import { Hbox } from '@atomic/obj.box';
import { ZIndex } from '@atomic/obj.constants';
export var BackButton = function (props) { return (React.createElement(AbsoluteBox, { zIndex: ZIndex.top, top: MARGIN.top, left: MARGIN.left },
    React.createElement(Button, { variant: 'link', to: props.to },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(BackArrow, null)),
            React.createElement(Hbox.Item, null, props.text))))); };
var MARGIN = {
    top: '40px',
    left: '24px',
};
