import React from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartTooltipStyled } from '@app/components/atm.chart-tooltip-styled';
import { ColorPallet } from '@app/components/atm.color-pallet';
import { InfoCard } from '@app/components/atm.info-card';
import { Card } from '@atomic/atm.card';
import { ControlledSelect } from '@atomic/atm.controlled-select';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color, Shadow, Spacing } from '@atomic/obj.constants';
import { agDashProductivityChartStrings as strings } from './ag-dash-productivity-chart.strings';
export var AgDashProductivityChart = function (props) {
    var _a = React.useState(), harvestYears = _a[0], setHarvestYear = _a[1];
    var harvestYearsIndex = props.data.findIndex(function (_a) {
        var harvest = _a.harvest;
        return harvest === harvestYears;
    });
    var slicedData = harvestYears
        ? props.data.slice(harvestYearsIndex, harvestYearsIndex + NUMBER_OF_HARVESTS)
        : props.data.slice(-NUMBER_OF_HARVESTS);
    return (React.createElement(Card, { shadow: Shadow.Medium },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Separator, { size: Spacing.Large }),
            React.createElement(Hbox.Item, null,
                React.createElement(H3, null, strings.title)),
            props.data.length > NUMBER_OF_HARVESTS && (React.createElement(Hbox.Item, { noGrow: true, hAlign: 'flex-end' },
                React.createElement(ControlledSelect, { options: mapHarvestOptions(props.data), placeholder: strings.harvest, name: 'harvest', onValueChange: function (value) { return setHarvestYear(value.toString()); } })))),
        React.createElement(ResponsiveContainer, { width: '100%', height: CONTAINER_HEIGHT },
            React.createElement(ComposedChart, { data: slicedData, barGap: 2 },
                React.createElement(CartesianGrid, { strokeDasharray: '3 3' }),
                React.createElement(XAxis, { dataKey: 'harvest', interval: 0, height: X_AXIS_HEIGHT, tick: React.createElement(CustomAxisTick, null) }),
                React.createElement(YAxis, null),
                React.createElement(Legend, { content: React.createElement(CustomLabel, null) }),
                React.createElement(Tooltip, { content: React.createElement(CustomTooltip, null) }),
                React.createElement(Bar, { dataKey: 'yeldGap', fill: Color.CallToActionLight, fillOpacity: 0.5, barSize: 30 }),
                React.createElement(Line, { type: 'linear', dataKey: 'potentialProductivity', stroke: Color.AccessoryYellow, strokeWidth: 4 }),
                React.createElement(Line, { type: 'linear', dataKey: 'attainableProductivity', stroke: Color.AccessoryMagenta, strokeWidth: 4 }),
                React.createElement(Legend, null))),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(InfoCard, { content: strings.infoCard })));
};
export var CustomLabel = function () {
    return (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(Hbox, null,
                React.createElement(ColorPallet, { bgColor: CUSTOM_LABEL_GREEN }),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                    React.createElement(Body, null,
                        " ",
                        strings.legend.yeldGap)),
                React.createElement(Hbox.Separator, null),
                React.createElement(ColorPallet, { bgColor: Color.AccessoryYellow }),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                    React.createElement(Body, null, strings.legend.potentialProductivity)),
                React.createElement(Hbox.Separator, null),
                React.createElement(ColorPallet, { bgColor: Color.AccessoryMagenta }),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                    React.createElement(Body, null, strings.legend.attainableProductivity))))));
};
var CustomTooltip = function (_a) {
    var active = _a.active, payload = _a.payload;
    if (active && payload && payload.length) {
        var dataPoint = payload[0].payload;
        return (React.createElement(ChartTooltipStyled, { noGutter: true },
            React.createElement(Body, { center: true }, dataPoint.harvest),
            React.createElement(Body, null,
                strings.tooltip.yeldGap,
                " ",
                dataPoint.yeldGap.toFixed(2)),
            React.createElement(Body, null,
                strings.tooltip.potentialProductivity,
                " ",
                dataPoint.potentialProductivity.toFixed(2)),
            React.createElement(Body, null,
                strings.tooltip.attainableProductivity,
                " ",
                dataPoint.attainableProductivity.toFixed(2))));
    }
    return null;
};
var CustomAxisTick = function (_a) {
    var x = _a.x, y = _a.y, payload = _a.payload;
    return (React.createElement("g", { transform: "translate(".concat(x - CUSTOM_AXIS_POSITION_TRANSFORM.x, ", ").concat(y + CUSTOM_AXIS_POSITION_TRANSFORM.y, ")") },
        React.createElement("text", { x: 0, y: 0, dy: 16, textAnchor: 'end', fill: '#666', transform: 'rotate(-90)' }, payload.value)));
};
var mapHarvestOptions = function (data) {
    var harvestsToSlice = 17;
    var slicedData = data.slice(0, -harvestsToSlice);
    return slicedData.map(function (_a) {
        var harvest = _a.harvest;
        return {
            label: harvest,
            value: harvest,
        };
    });
};
var NUMBER_OF_HARVESTS = 18;
var X_AXIS_HEIGHT = 116;
var CONTAINER_HEIGHT = 716;
var CUSTOM_AXIS_POSITION_TRANSFORM = {
    x: 10,
    y: 5,
};
var CUSTOM_LABEL_GREEN = '#D1ECAB';
