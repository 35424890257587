var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Card } from '@atomic/atm.card';
import { Color, FontFamily, FontSize, FontWeight, LineHeight, TransitionDuration, ZIndex } from '@atomic/obj.constants';
var TRANSFORM_SCALE = '1.03';
export var UnitsTypography = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  line-height: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  line-height: ", ";\n  font-weight: ", ";\n"])), Color.Primary, FontSize.XSmall, FontFamily.Secondary, LineHeight.Small, FontWeight.Medium);
export var BigNumberCardStyled = styled(Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  transition: all ", " ease-in-out;\n\n  &:hover {\n    transform: scale(", ");\n    z-index: ", ";\n  }\n"], ["\n  transition: all ", " ease-in-out;\n\n  &:hover {\n    transform: scale(", ");\n    z-index: ", ";\n  }\n"])), TransitionDuration, TRANSFORM_SCALE, ZIndex.second);
var templateObject_1, templateObject_2;
