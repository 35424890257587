import React, { useRef } from 'react';
import { SpreadsheetSmallIcon } from '@assets/icons/spreadsheet-small.icon';
import { TBodyTypography, TButton, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { strings } from './spreadsheets-table-row.string';
import { FileFieldInvisible } from './spreadsheets-table-row.style';
export var SpreadsheetTableRow = function (_a) {
    var item = _a.item, index = _a.index, updateLoading = _a.updateLoading, deleteLoading = _a.deleteLoading, onFileSelect = _a.onFileSelect, onClickDeleteButton = _a.onClickDeleteButton;
    var fileInputRef = useRef();
    var handleClickUpdateButton = function () {
        fileInputRef.current.click();
    };
    return (React.createElement(TR, { isGrey: index % 2 !== 0 },
        React.createElement(TD, { contentStart: true },
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, { noGrow: true },
                    React.createElement(SpreadsheetSmallIcon, null)),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { vAlign: 'center' },
                    React.createElement(TBodyTypography, null, item.name)))),
        React.createElement(TD, { contentStart: true },
            React.createElement(TBodyTypography, null, item.createdAt)),
        React.createElement(TD, { contentStart: true },
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, null,
                    React.createElement(TButton, { onClick: handleClickUpdateButton, variant: 'link', loading: updateLoading }, strings.button.update),
                    React.createElement(FileFieldInvisible, { ref: fileInputRef, type: 'file', onChange: function (file) { return onFileSelect(file, item.id); }, accept: '.xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv' })),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { vAlign: 'center' },
                    React.createElement(TBodyTypography, null, "|")),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, null,
                    React.createElement(TButton, { variant: 'link', alert: true, onClick: function () { return onClickDeleteButton(item.id); }, loading: deleteLoading }, strings.button.delete))))));
};
