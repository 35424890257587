var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import TextInputMask from 'react-masked-text';
import styled, { css } from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputPlaceholderCss } from '@atomic/atm.typography';
import { Color, FieldHeight, Spacing, ZIndex } from '@atomic/obj.constants';
import { fieldBorderCss, fieldCss, infieldTopAlignedLabeldCss, } from '@atomic/obj.form/field.component.styled';
var FIELD_WITH_ICON_LEFT_PADDING = 'calc(' + Spacing.Medium + ' * 3)';
var ICON_HEIGHT = '18px';
export var TextFieldStyled = styled.input.withConfig({
    shouldForwardProp: function (prop, defaultValidatorFn) { return defaultValidatorFn(prop) || ['options'].includes(prop); },
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n\n  ", ";\n  text-overflow: ellipsis;\n\n  ::placeholder {\n    ", "\n    opacity: 0.5;\n  }\n"], ["\n  ", "\n  ", "\n\n  ", ";\n  text-overflow: ellipsis;\n\n  ::placeholder {\n    ", "\n    opacity: 0.5;\n  }\n"])), fieldBorderCss, function (props) { return (props.inputLabel ? infieldTopAlignedLabeldCss : fieldCss); }, function (props) { return (props.icon ? 'padding-left: ' + FIELD_WITH_ICON_LEFT_PADDING : null); }, InputPlaceholderCss);
var DismissButtonSize = 16;
export var TextFieldDismissButtonStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  width: ", "px;\n  height: ", "px;\n  border-radius: ", "px;\n  background-color: ", ";\n  text-align: center;\n\n  &::before,\n  &::after {\n    content: '';\n    position: absolute;\n    height: 2px;\n    width: 10px;\n    top: 50%;\n    left: 3px;\n    background: ", ";\n  }\n  &::before {\n    transform: rotate(45deg);\n    margin-top: -1px;\n  }\n  &::after {\n    transform: rotate(-45deg);\n    margin-top: -1px;\n  }\n"], ["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  width: ", "px;\n  height: ", "px;\n  border-radius: ", "px;\n  background-color: ", ";\n  text-align: center;\n\n  &::before,\n  &::after {\n    content: '';\n    position: absolute;\n    height: 2px;\n    width: 10px;\n    top: 50%;\n    left: 3px;\n    background: ", ";\n  }\n  &::before {\n    transform: rotate(45deg);\n    margin-top: -1px;\n  }\n  &::after {\n    transform: rotate(-45deg);\n    margin-top: -1px;\n  }\n"])), Spacing.Large, Spacing.Small, DismissButtonSize, DismissButtonSize, DismissButtonSize / 2, Color.Gray, Color.White);
export var TextFieldWrapperStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var TextFieldMaskedStyled = TextFieldStyled.withComponent(TextInputMask);
export var TextFieldIconWrapperStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var TextFieldIconStyled = styled(FaIcon.Search)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 1;\n  top: ", ";\n  left: ", ";\n"], ["\n  position: absolute;\n  z-index: 1;\n  top: ", ";\n  left: ", ";\n"])), 'calc((' + FieldHeight + ' - ' + ICON_HEIGHT + ') / 2)', Spacing.Medium);
var ICON_NEGATIVE_MARGIN = -34;
export var PasswordShowIcon = styled(FaIcon.Eye)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  z-index: ", ";\n  top: ", "px;\n  right: ", ";\n  ", ";\n"], ["\n  position: absolute;\n  z-index: ", ";\n  top: ", "px;\n  right: ", ";\n  ", ";\n"])), ZIndex.top, ICON_NEGATIVE_MARGIN, Spacing.XSmall, function (_a) {
    var onClick = _a.onClick;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    ", "\n  "], ["\n    ", "\n  "])), onClick && "cursor: pointer;");
});
export var PasswordHiddenIcon = styled(FaIcon.EyeSlash)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  z-index: ", ";\n  ", ";\n"], ["\n  position: absolute;\n  z-index: ", ";\n  ", ";\n"])), ZIndex.top, function (_a) {
    var onClick = _a.onClick;
    return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    ", "\n\n    top: ", "px;\n    right: ", ";\n  "], ["\n    ", "\n\n    top: ", "px;\n    right: ", ";\n  "])), onClick && "cursor: pointer;", ICON_NEGATIVE_MARGIN, Spacing.XSmall);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
