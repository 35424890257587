var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { ButtonStyled } from '@atomic/atm.button/button.component.style';
import { Border, Color, Spacing } from '@atomic/obj.constants';
export var PaginationStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
var PAGE_SIZE = 35;
export var PageStyled = styled(ButtonStyled)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  content: ", ";\n  border-radius: ", ";\n  width: ", "px;\n  height: ", "px;\n  text-align: center;\n  margin-right: ", ";\n  line-height: 0;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  content: ", ";\n  border-radius: ", ";\n  width: ", "px;\n  height: ", "px;\n  text-align: center;\n  margin-right: ", ";\n  line-height: 0;\n"])), function (props) { return (props.active ? Color.Primary : 'transparent'); }, function (props) { return (props.active ? Color.White : Color.Black); }, function (props) { return (props.first ? 'true' : 'false'); }, Border.SmallRadius, PAGE_SIZE, PAGE_SIZE, Spacing.XXSmall);
export var PageDisabledStyled = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", ";\n  border-width: 0;\n"], ["\n  padding: ", ";\n  border-width: 0;\n"])), Spacing.Medium);
var templateObject_1, templateObject_2, templateObject_3;
