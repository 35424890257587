export var navbarMenuStrings = {
    options: {
        title: 'Módulos de consulta',
        bigAct: 'Big Act',
        bigData: 'Big Data',
        agDash: 'Ag Dash',
        spreadsheets: 'Planilhas',
    },
    userDropdown: {
        title: 'Minha conta',
        editUser: 'Editar conta',
        helpCenter: 'Central de ajuda',
        logout: 'Sair',
    },
};
