import { useState } from 'react';
export var useModalState = function (initialValue) {
    if (initialValue === void 0) { initialValue = false; }
    var _a = useState(initialValue), opened = _a[0], setOpened = _a[1];
    var openModal = function () { return setOpened(true); };
    var closeModal = function () { return setOpened(false); };
    return {
        opened: opened,
        openModal: openModal,
        closeModal: closeModal,
    };
};
