import React from 'react';
import { useLogout } from '@app/domain/user/logout.use-case';
import { AccountRoutes } from '@app/modules/account/account.routes';
import { HelpCenterRoutes } from '@app/modules/help-center/help-center.routes';
import { useUserStore } from '@app/stores';
import { BodySecondary, H4 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { navbarMenuStrings as strings } from './navbar-menu.strings';
import { UserDropdownOption, UserDropdownStyled } from './user-dropdown.styled';
export var UserDropdown = function (props) {
    var user = useUserStore()[0];
    var logout = useLogout().logout;
    return (React.createElement(UserDropdownStyled, { innerRef: props.innerRef, isOpened: props.isOpened, noGutter: true },
        React.createElement(Separator, { size: Spacing.XXSmall }),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Separator, { size: Spacing.Large }),
            React.createElement(Hbox.Item, { hAlign: 'flex-end', vAlign: 'center' },
                React.createElement(H4, { noMargin: true }, strings.userDropdown.title)),
            React.createElement(Hbox.Separator, { size: Spacing.XSmall })),
        React.createElement(UserDropdownOption, { to: AccountRoutes.account },
            React.createElement(BodySecondary, { noMargin: true }, strings.userDropdown.editUser)),
        React.createElement(UserDropdownOption, { to: HelpCenterRoutes.base },
            React.createElement(BodySecondary, { noMargin: true }, strings.userDropdown.helpCenter)),
        React.createElement(UserDropdownOption, { to: '#', onClick: function () { return logout({ data: { retoken: user.retoken } }); }, "$borderRadius": true },
            React.createElement(BodySecondary, { noMargin: true }, strings.userDropdown.logout))));
};
