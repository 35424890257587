import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
export function useResetPasswod(params) {
    var _a = useMutation('ResetPasswordMutation', params), resetPassword = _a[0], _b = _a[1], data = _b.data, error = _b.error, loading = _b.loading;
    return {
        resetPassword: resetPassword,
        data: data,
        error: error,
        loading: loading,
    };
}
