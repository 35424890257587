var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { CardStyled } from '@atomic/atm.card/card.component.style';
import { Hbox } from '@atomic/obj.box';
import { Border, Color, Spacing } from '@atomic/obj.constants';
export var DropdownWrapper = styled(CardStyled)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), Spacing.XSmall);
export var UploadCardStateWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  ", ";\n"], ["\n  padding: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  ", ";\n"])), Spacing.XSmall, function (props) { return (props.hasError ? Color.AlertLight : Color.CallToActionXLight); }, Border.SmallRadius, function (props) { return !props.show && " display:none"; });
export var InfoCardSeparator = styled(Hbox.Separator)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 4px;\n"], ["\n  width: 4px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
