var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Scroll = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n  width: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n"], ["\n  height: ", ";\n  width: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n"])), function (_a) {
    var height = _a.height;
    return (height ? height : '100%');
});
var templateObject_1;
