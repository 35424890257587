import * as React from 'react';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import { Form } from '@atomic/obj.form';
import { DatePickerSelect } from './date-picker-select.component';
import { DatePickerHeaderContent, DatePickerStyled } from './date-picker.component.style';
// eslint-disable-next-line import/no-unassigned-import
import './date-picker.css';
export var DatePicker = function (props) {
    var _a, _b, _c;
    var _d = React.useState(), value = _d[0], setValue = _d[1];
    var _e = React.useState(), startDate = _e[0], setStartDate = _e[1];
    var _f = React.useState(), endDate = _f[0], setEndDate = _f[1];
    var initialDate = (_a = props.initialDateValue) !== null && _a !== void 0 ? _a : new Date();
    React.useEffect(function () {
        registerLocale('pt-br', ptBR);
    }, []);
    var handleDateChange = function (date) {
        var _a;
        if (props.endSelect) {
            if (!props.endDate) {
                setEndDate(date);
            }
        }
        else {
            if (!props.startDate) {
                setStartDate(date);
            }
        }
        (_a = props.onValueChange) === null || _a === void 0 ? void 0 : _a.call(props, date);
        setValue(date);
    };
    var handleFocusChange = function (focused) { return function () {
        var _a;
        (_a = props.onFocusChange) === null || _a === void 0 ? void 0 : _a.call(props, focused);
    }; };
    var years = Array.from({ length: new Date().getUTCFullYear() - (props.firstYear ? props.firstYear - 1 : 1989) }, function (_, index) { return (props.firstYear ? props.firstYear : 1990) + index; });
    var months = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ];
    return (React.createElement(Form.Field, { label: props.label, name: props.name, value: value, onValueChange: props.onValueChange },
        React.createElement(DatePickerStyled, { locale: 'pt-br', dateFormat: 'dd/MM/yyyy', onChange: handleDateChange, startDate: (_b = props.startDate) !== null && _b !== void 0 ? _b : startDate, endDate: (_c = props.endDate) !== null && _c !== void 0 ? _c : endDate, selected: props.endSelect ? endDate : startDate, onFocus: handleFocusChange(true), onBlur: handleFocusChange(false), placeholderText: props.placeholder, showTimeSelect: props.showTimeSelect, disabled: props.disabled, selectsStart: !props.endSelect, selectsEnd: props.endSelect, includeDates: props.enableDates, excludeDateIntervals: props.excludeDateInterval, formatWeekDay: function (nameOfDay) { return nameOfDay.toUpperCase().substring(0, 1); }, renderCustomHeader: function (_a) {
                var changeYear = _a.changeYear, changeMonth = _a.changeMonth;
                return (React.createElement(DatePickerHeaderContent, null,
                    React.createElement(DatePickerSelect, { placeholder: 'Ano', options: years.map(function (option) { return ({ value: option, label: "".concat(option) }); }), onValueChange: function (newValue) { return changeYear(+newValue.value); }, initialvalue: !value
                            ? {
                                label: "".concat(initialDate.getUTCFullYear()),
                                value: initialDate.getUTCFullYear(),
                            }
                            : {
                                label: "".concat(value === null || value === void 0 ? void 0 : value.getUTCFullYear()),
                                value: value === null || value === void 0 ? void 0 : value.getUTCFullYear(),
                            } }),
                    React.createElement(DatePickerSelect, { placeholder: 'M\u00EAs', options: months.map(function (option, index) { return ({ value: index, label: "".concat(option) }); }), onValueChange: function (newValue) { return changeMonth(+newValue.value); }, initialvalue: !value
                            ? {
                                label: months[initialDate.getUTCMonth()],
                                value: months[initialDate.getUTCMonth()],
                            }
                            : {
                                label: months[value === null || value === void 0 ? void 0 : value.getUTCMonth()],
                                value: months[value === null || value === void 0 ? void 0 : value.getUTCMonth()],
                            } })));
            } })));
};
