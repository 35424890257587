var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import CalendarDisabledIcon from '@assets/icons/calendar-disabled.icon.svg';
import CalendarIcon from '@assets/icons/calendar.icon.svg';
import { Color } from '@atomic/obj.constants';
import { fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';
// eslint-disable-next-line import/no-unassigned-import
import 'react-datepicker/dist/react-datepicker.css';
var BACKGROUND_POSITION = '95% 50%';
export var DatePickerStyled = styled(DatePicker)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  ", "\n  background: url(", ");\n  background-repeat: no-repeat;\n  background-position: ", ";\n"], ["\n  ", "\n  ", "\n  ", "\n  background: url(", ");\n  background-repeat: no-repeat;\n  background-position: ", ";\n"])), fieldBorderCss, fieldCss, function (props) { return props.disabled && "background-color: ".concat(Color.GrayXLight, "; "); }, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? CalendarDisabledIcon : CalendarIcon);
}, BACKGROUND_POSITION);
export var DatePickerHeaderContent = styled.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
var templateObject_1, templateObject_2;
