export var BrandColor;
(function (BrandColor) {
    BrandColor["GreenLight"] = "#B2E6CD";
    BrandColor["Green"] = "#056939";
    BrandColor["GreenDark"] = "#034224";
    BrandColor["GreenXDark"] = "#022B18";
})(BrandColor || (BrandColor = {}));
var NeutralColor;
(function (NeutralColor) {
    NeutralColor["White"] = "#FFFFFF";
    NeutralColor["GrayXXLight"] = "#F8F8F8";
    NeutralColor["GrayXLight"] = "#EBEBEB";
    NeutralColor["GrayLight"] = "#E3E3E3";
    NeutralColor["Gray"] = "#B5B5B5";
    NeutralColor["GrayDark"] = "#626262";
    NeutralColor["GrayXDark"] = "#313131";
    NeutralColor["GrayXXDark"] = "#121212";
    NeutralColor["Black"] = "#292B33;";
})(NeutralColor || (NeutralColor = {}));
var FeedbackColor;
(function (FeedbackColor) {
    FeedbackColor["GreenLight"] = "#DAF1E2";
    FeedbackColor["Green"] = "#379559";
    FeedbackColor["GreenDark"] = "#153822";
    FeedbackColor["GoldenLight"] = "#FEF3CD";
    FeedbackColor["Golden"] = "#DFB623";
    FeedbackColor["GoldenDark"] = "#735A02";
    FeedbackColor["RedLight"] = "#F8D3D2";
    FeedbackColor["Red"] = "#D42F28";
    FeedbackColor["RedDark"] = "#7C1B18";
})(FeedbackColor || (FeedbackColor = {}));
var AccessoryColor;
(function (AccessoryColor) {
    AccessoryColor["Green"] = "#1E5B2B";
    AccessoryColor["Magenta"] = "#B51B76";
    AccessoryColor["Red"] = "#F11427";
    AccessoryColor["Blue"] = "#1BB5A3";
    AccessoryColor["BlueDark"] = "#169284";
    AccessoryColor["BlueXDark"] = "#116F64";
    AccessoryColor["Orange"] = "#FF9029";
    AccessoryColor["Yellow"] = "#FFBD2E";
})(AccessoryColor || (AccessoryColor = {}));
// We used class rather than enums since enums don't support computed values
var Color = /** @class */ (function () {
    function Color() {
    }
    Color.Black = NeutralColor.Black;
    Color.White = NeutralColor.White;
    Color.GrayXXLight = NeutralColor.GrayXXLight;
    Color.GrayXLight = NeutralColor.GrayXLight;
    Color.GrayLight = NeutralColor.GrayLight;
    Color.Gray = NeutralColor.Gray;
    Color.GrayDark = NeutralColor.GrayDark;
    Color.GrayXDark = NeutralColor.GrayXDark;
    Color.GrayXXDark = NeutralColor.GrayXXDark;
    Color.PrimaryLight = BrandColor.GreenLight;
    Color.Primary = BrandColor.Green;
    Color.PrimaryDark = BrandColor.GreenDark;
    Color.PrimaryXDark = BrandColor.GreenXDark;
    Color.AccessoryGreen = AccessoryColor.Green;
    Color.AccessoryMagenta = AccessoryColor.Magenta;
    Color.AccessoryRed = AccessoryColor.Red;
    Color.AccessoryBlue = AccessoryColor.Blue;
    Color.AccessoryBlueDark = AccessoryColor.BlueDark;
    Color.AccessoryBlueXDark = AccessoryColor.BlueXDark;
    Color.AccessoryOrange = AccessoryColor.Orange;
    Color.AccessoryYellow = AccessoryColor.Yellow;
    Color.CallToActionXXLight = '#FEFFFC';
    Color.CallToActionXLight = '#F3F9EB';
    Color.CallToActionLight = '#A3DA58';
    Color.CallToAction = '#80B735';
    Color.CallToActionDark = '#598025';
    Color.SuccessLight = FeedbackColor.GreenLight;
    Color.Success = FeedbackColor.Green;
    Color.SuccessDark = FeedbackColor.GreenDark;
    Color.WarningLight = FeedbackColor.GoldenLight;
    Color.Warning = FeedbackColor.Golden;
    Color.WarningDark = FeedbackColor.GoldenDark;
    Color.AlertLight = FeedbackColor.RedLight;
    Color.Alert = FeedbackColor.Red;
    Color.AlertDark = FeedbackColor.RedDark;
    return Color;
}());
export { Color };
export var FontFamily;
(function (FontFamily) {
    FontFamily["Primary"] = "Spartan";
    FontFamily["Secondary"] = "League-Spartan";
})(FontFamily || (FontFamily = {}));
export var FontWeight;
(function (FontWeight) {
    FontWeight["Bold"] = "bold";
    FontWeight[FontWeight["SemiBold"] = 600] = "SemiBold";
    FontWeight[FontWeight["Medium"] = 500] = "Medium";
    FontWeight["Normal"] = "normal";
    FontWeight[FontWeight["Lighter"] = 300] = "Lighter";
})(FontWeight || (FontWeight = {}));
export var FontSize;
(function (FontSize) {
    FontSize["XXSmall"] = "14px";
    FontSize["XSmall"] = "16px";
    FontSize["Small"] = "18px";
    FontSize["Medium"] = "20px";
    FontSize["Large"] = "24px";
    FontSize["XLarge"] = "28px";
    FontSize["XXLarge"] = "40px";
})(FontSize || (FontSize = {}));
export var LineHeight;
(function (LineHeight) {
    LineHeight["Small"] = "100%";
    LineHeight["Medium"] = "120%";
    LineHeight["Large"] = "150%";
})(LineHeight || (LineHeight = {}));
export var Spacing;
(function (Spacing) {
    Spacing["XXSmall"] = "8px";
    Spacing["XSmall"] = "16px";
    Spacing["Small"] = "24px";
    Spacing["Medium"] = "32px";
    Spacing["Large"] = "40px";
    Spacing["XLarge"] = "100px";
})(Spacing || (Spacing = {}));
export var HeaderHeight = {
    Mobile: '64px',
    Desk: '80px',
};
export var IconSize;
(function (IconSize) {
    IconSize["Small"] = "24px";
    IconSize["Medium"] = "30px";
    IconSize["Large"] = "40px";
})(IconSize || (IconSize = {}));
export var FaIconSize;
(function (FaIconSize) {
    FaIconSize["XSmall"] = "xs";
    FaIconSize["Large"] = "lg";
    FaIconSize["Small"] = "sm";
    FaIconSize["X1"] = "1x";
    FaIconSize["X2"] = "2x";
    FaIconSize["X3"] = "3x";
    FaIconSize["X4"] = "4x";
    FaIconSize["X5"] = "5x";
    FaIconSize["X6"] = "6x";
    FaIconSize["X7"] = "7x";
    FaIconSize["X8"] = "8x";
    FaIconSize["X9"] = "9x";
    FaIconSize["X10"] = "10x";
})(FaIconSize || (FaIconSize = {}));
export var DrawerWidth = '256px';
export var Navbar = {
    a: '1',
};
export var FieldHeight = '48px';
export var TransitionDuration = '.3s';
export var Border = {
    Color: Color.GrayLight,
    Width: '2px',
    SmallRadius: '8px',
    Radius: '12px',
    RadiusLarge: '24px',
    ShimmerRadius: '50px',
    Circular: '100%',
};
// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export var PasswordLength;
(function (PasswordLength) {
    PasswordLength[PasswordLength["Max"] = 18] = "Max";
    PasswordLength[PasswordLength["Min"] = 7] = "Min";
})(PasswordLength || (PasswordLength = {}));
export var AspectRatio;
(function (AspectRatio) {
    AspectRatio[AspectRatio["Square"] = 1] = "Square";
})(AspectRatio || (AspectRatio = {}));
export var Breakpoint = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
};
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["ground"] = 0] = "ground";
    ZIndex[ZIndex["first"] = 1] = "first";
    ZIndex[ZIndex["second"] = 2] = "second";
    ZIndex[ZIndex["third"] = 3] = "third";
    ZIndex[ZIndex["top"] = 4] = "top";
    ZIndex[ZIndex["flashMessage"] = 5] = "flashMessage";
})(ZIndex || (ZIndex = {}));
export var Shadow;
(function (Shadow) {
    Shadow["Small"] = "1px 1px 30px 30px rgba(0, 0, 0, 0.02)";
    Shadow["Medium"] = "2px 2px 32px 16px rgba(0, 0, 0, 0.03)";
})(Shadow || (Shadow = {}));
export var ControlSizes;
(function (ControlSizes) {
    ControlSizes["XSmall"] = "135px";
    ControlSizes["Small"] = "172px";
    ControlSizes["Medium"] = "200px";
    ControlSizes["Large"] = "266px";
})(ControlSizes || (ControlSizes = {}));
