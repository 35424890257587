import React from 'react';
import { useDeleteAccount } from '@app/domain/user/delete-account.use-case';
import { DeleteAccountIcon } from '@assets/icons/delete-accont.icon';
import { DeleteAccountBackground } from '@assets/icons/delete-account.background';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { AbsoluteBox } from '@atomic/obj.absolute-box';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing, ZIndex } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { strings } from './delete-account-modal.strings';
export var DeleteAccountModal = function (props) {
    var _a = useDeleteAccount(), deleteAccountMutation = _a.deleteAccountMutation, loading = _a.loading;
    var handleDelete = function () {
        deleteAccountMutation();
    };
    return (React.createElement(Modal, { opened: props.opened, onClose: props.onCloseModal, small: true },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { hAlign: 'center' },
                React.createElement(DeleteAccountBackground, null),
                React.createElement(AbsoluteBox, { zIndex: ZIndex.top },
                    React.createElement(DeleteAccountIcon, null)))),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(H2, { center: true }, strings.title),
        React.createElement(Body, { center: true }, strings.description),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { hAlign: 'center' },
                React.createElement(Button, { expanded: true, variant: 'secondary', onClick: props.onCloseModal }, strings.buttons.close)),
            React.createElement(Hbox.Separator, { size: Spacing.Small }),
            React.createElement(Hbox.Item, { hAlign: 'center' },
                React.createElement(Button, { expanded: true, variant: 'primary', alert: true, loading: loading, onClick: handleDelete }, strings.buttons.delete)))));
};
