var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Storages } from '@app/core/storage/storages';
import { BaseStore, IStore } from './base-store';
var LocalStore = /** @class */ (function (_super) {
    __extends(LocalStore, _super);
    function LocalStore(localStorageKey) {
        var _this = _super.call(this) || this;
        _this.localStorageKey = localStorageKey;
        _this.setState = function (stateOrFn) {
            if (IStore.isSetter(stateOrFn)) {
                _this.state = stateOrFn(_this.state);
            }
            else {
                _this.state = stateOrFn;
            }
            Storages.local.put(_this.localStorageKey, _this.state);
            _this.publish();
        };
        _this.getState = function () {
            if (_this.state === undefined) {
                _this.state = Storages.local.get(_this.localStorageKey);
            }
            return _this.state;
        };
        _this.refreshState = function () {
            _this.state = Storages.local.get(_this.localStorageKey);
            _this.publish();
        };
        _this.remove = function () {
            if (Storages.local.get(_this.localStorageKey)) {
                _this.state = null;
                Storages.local.remove(_this.localStorageKey);
                _this.publish();
            }
        };
        return _this;
    }
    return LocalStore;
}(BaseStore));
export { LocalStore };
