var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
import { InputDisabledCss, InputValueCss } from '@atomic/atm.typography';
import { Border, Breakpoint, Color, FieldHeight, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';
export var fieldBorderCss = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: ", ";\n  overflow: hidden;\n  border: ", " solid;\n  border-color: ", ";\n\n  :focus {\n    border-color: ", ";\n  }\n\n  @media all and (min-width: ", "px) {\n    :hover {\n      border-color: ", ";\n    }\n  }\n"], ["\n  border-radius: ", ";\n  overflow: hidden;\n  border: ", " solid;\n  border-color: ", ";\n\n  :focus {\n    border-color: ", ";\n  }\n\n  @media all and (min-width: ", "px) {\n    :hover {\n      border-color: ", ";\n    }\n  }\n"])), Border.Radius, Border.Width, function (props) { return (props.invalid ? Color.Alert : props.filled ? Color.Primary : Color.GrayLight); }, Color.Gray, Breakpoint.md, Color.Gray);
export var fieldTypographyCss = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  font-family: ", ";\n  font-size: ", ";\n  padding-left: ", ";\n"], ["\n  ", "\n  font-family: ", ";\n  font-size: ", ";\n  padding-left: ", ";\n"])), function (props) { return (props.disabled ? InputDisabledCss : InputValueCss); }, FontFamily.Primary, FontSize.Small, Spacing.XXSmall);
export var fieldCss = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  width: 100%;\n  height: ", ";\n  align-self: stretch;\n\n  background-color: ", ";\n  box-sizing: border-box;\n\n  :focus {\n    outline: none;\n  }\n\n  /* https://stackoverflow.com/a/38573257/3670829 */\n  -webkit-appearance: none;\n\n  transition: all 0.3s;\n"], ["\n  ", "\n  width: 100%;\n  height: ", ";\n  align-self: stretch;\n\n  background-color: ", ";\n  box-sizing: border-box;\n\n  :focus {\n    outline: none;\n  }\n\n  /* https://stackoverflow.com/a/38573257/3670829 */\n  -webkit-appearance: none;\n\n  transition: all 0.3s;\n"])), fieldTypographyCss, FieldHeight, function (props) { return (props.disabled ? Color.GrayXXLight : Color.White); });
export var infieldTopAlignedLabeldCss = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  padding-bottom: 0;\n"], ["\n  ", "\n  padding-bottom: 0;\n"])), fieldCss);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
