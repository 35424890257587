var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Color, Spacing } from '@atomic/obj.constants';
export var InfoMessageBanner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n"], ["\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n"])), Color.GrayXLight, Spacing.XXSmall, Border.SmallRadius);
var ALL_HEGIHT_PAGE = '100vh';
export var UploadSpreadSheetPageWrapper = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: ", ";\n"])), ALL_HEGIHT_PAGE);
var templateObject_1, templateObject_2;
