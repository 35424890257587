var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Border, Breakpoint, Color, Spacing, ZIndex } from '@atomic/obj.constants';
export var ModalStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  visibility: ", ";\n  z-index: ", ";\n"], ["\n  position: absolute;\n  visibility: ", ";\n  z-index: ", ";\n"])), function (props) { return (props.opened ? 'visible' : 'hidden'); }, ZIndex.top);
var slideDown = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    transform: translate(-50%, -100%);\n  }\n"], ["\n  0% {\n    transform: translate(-50%, -100%);\n  }\n"])));
var fadeIn = keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"], ["\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"])));
export var ModalOpacityStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background-color: ", ";\n  opacity: ", ";\n  transition: all 0.2s ease-out;\n  visibility: ", ";\n"], ["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background-color: ", ";\n  opacity: ", ";\n  transition: all 0.2s ease-out;\n  visibility: ", ";\n"])), Color.Black, function (props) { return (props.opened ? '0.5' : '0'); }, function (props) { return (props.opened ? 'visible' : 'hidden'); });
export var ModalBoxStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: fixed;\n  top: 80px;\n  left: 50%;\n  ", ";\n  transform: translate(-50%, 0);\n  width: 90%;\n  max-width: ", ";\n  max-height: 80%;\n  overflow: hidden;\n  overscroll-behavior: contain;\n  background-color: ", ";\n  border-radius: ", ";\n  animation-duration: 0.3s;\n  animation-timing-function: ease-out;\n  animation-name: ", ";\n\n  @media (prefers-reduced-motion: no-preference) {\n    animation-name: ", ";\n    animation-name: ", ";\n  }\n\n  @media all and (min-width: ", "px) {\n    width: ", ";\n  }\n"], ["\n  position: fixed;\n  top: 80px;\n  left: 50%;\n  ", ";\n  transform: translate(-50%, 0);\n  width: 90%;\n  max-width: ", ";\n  max-height: 80%;\n  overflow: hidden;\n  overscroll-behavior: contain;\n  background-color: ", ";\n  border-radius: ", ";\n  animation-duration: 0.3s;\n  animation-timing-function: ease-out;\n  animation-name: ", ";\n\n  @media (prefers-reduced-motion: no-preference) {\n    animation-name: ", ";\n    animation-name: ", ";\n  }\n\n  @media all and (min-width: ", "px) {\n    width: ", ";\n  }\n"])), function (props) { return !props.noGutter && "padding: ".concat(Spacing.Medium); }, function (_a) {
    var maxWidth = _a.maxWidth;
    return (maxWidth ? maxWidth : '90%');
}, Color.White, Border.Radius, fadeIn, fadeIn, slideDown, Breakpoint.md, function (props) { return (props.small ? '50%' : '70%'); });
export var ModalCloseStyled = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: ", ";\n  color: ", ";\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: ", ";\n  color: ", ";\n"])), function (props) { return (props.noGutter ? Spacing.Medium : Spacing.Large); }, Color.GrayDark);
export var CloseIconStyled = styled(FaIcon.Close)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export var ModalBoxBodyStyled = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-height: 75vh;\n  overflow-y: auto;\n  padding: ", ";\n"], ["\n  max-height: 75vh;\n  overflow-y: auto;\n  padding: ", ";\n"])), Spacing.Large);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
