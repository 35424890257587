import React from 'react';
import { ImageBackground } from '@app/components/atm.image-background';
import { Scroll } from '@app/components/atm.scroll';
import { SignUpForm } from '@app/components/mol.sign-up-form';
import { useDocs } from '@app/domain/docs';
import BackgroundLoginImage from '@assets/img/background-login.jpg';
import { Col, Grid, Row } from '@atomic/obj.grid';
export var SignUpPage = function () {
    var _a, _b;
    var data = useDocs().data;
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, { nogutter: true },
            React.createElement(Col, { md: 6 },
                React.createElement(Scroll, { height: '100vh' },
                    React.createElement(Row, { justify: 'center', align: 'center', "$allHeight": true },
                        React.createElement(Col, { xs: 11, md: 10, lg: 8, xxl: 6 },
                            React.createElement(SignUpForm, { termsOfUseUrl: (_a = data === null || data === void 0 ? void 0 : data.docs) === null || _a === void 0 ? void 0 : _a.termsOfUse, privacyPolicyUrl: (_b = data === null || data === void 0 ? void 0 : data.docs) === null || _b === void 0 ? void 0 : _b.privacyPolicy }))))),
            React.createElement(Col, { md: 6 },
                React.createElement(ImageBackground, { src: BackgroundLoginImage })))));
};
