import AcceptDirectCommunicationMutation from './mutation/accept-direct-communication.mutation.graphql';
import AlterPasswordMutation from './mutation/alter-password.mutation.graphql';
import ConfirmSignUpMutation from './mutation/confirm-signup.mutation.graphql';
import CreateExportMutation from './mutation/create-export.mutation.graphql';
import CreateUserMutation from './mutation/create-user.mutation.graphql';
import DeleteAccountMutation from './mutation/delete-account.mutation.graphql';
import DeleteSpreadsheetMutation from './mutation/delete-spreadsheet.mutation.graphql';
import DemoSpreadsheetMutation from './mutation/demo-spreadsheet.mutation.graphql';
import ForgotPasswordMutation from './mutation/forgot-password.mutation.graphql';
import LoginMutation from './mutation/login.mutation.graphql';
import LogoutMutation from './mutation/logout.mutation.graphql';
import ResetPasswordMutation from './mutation/reset-password.mutation.graphql';
import RetokenMutation from './mutation/retoken.mutation.graphql';
import SendConfirmationEmailMutation from './mutation/send-confirmation-email.mutation.graphql';
import UpdateSpreadsheetMutation from './mutation/update-spreadsheet.mutation.graphql';
import UpdateUserMutation from './mutation/update-user.mutation.graphql';
import UploadSpreadsheetMutation from './mutation/upload-spreadsheet.mutation.graphql';
import AgDashQuery from './query/ag-dash.query.graphql';
import BigActFinalSelectionQuery from './query/big-act-final-selection.query.graphql';
import BigActInitialSelectionQuery from './query/big-act-initial-selection.query.graphql';
import BigActResult from './query/big-act-result.query.graphql';
import BigDataQuery from './query/big-data.query.graphql';
import DocsQuery from './query/docs.query.graphql';
import ExportBigDataSpreadsheetQuery from './query/export-big-data-spreadsheet.query.graphql';
import SpreadsheetQuery from './query/spreadsheet.query.graphql';
import SpreadsheetsQuery from './query/spreadsheets.query.graphql';
import UserQuery from './query/user.query.graphql';
import ValidateZarcQuery from './query/validate-zarc.query.graphql';
export var Mutation = {
    CreateUserMutation: CreateUserMutation,
    LoginMutation: LoginMutation,
    ForgotPasswordMutation: ForgotPasswordMutation,
    ResetPasswordMutation: ResetPasswordMutation,
    ConfirmSignUpMutation: ConfirmSignUpMutation,
    SendConfirmationEmailMutation: SendConfirmationEmailMutation,
    UploadSpreadsheetMutation: UploadSpreadsheetMutation,
    DemoSpreadsheetMutation: DemoSpreadsheetMutation,
    LogoutMutation: LogoutMutation,
    CreateExportMutation: CreateExportMutation,
    UpdateUserMutation: UpdateUserMutation,
    AlterPasswordMutation: AlterPasswordMutation,
    DeleteAccountMutation: DeleteAccountMutation,
    RetokenMutation: RetokenMutation,
    DeleteSpreadsheetMutation: DeleteSpreadsheetMutation,
    UpdateSpreadsheetMutation: UpdateSpreadsheetMutation,
    AcceptDirectCommunicationMutation: AcceptDirectCommunicationMutation,
};
export var Query = {
    BigActInitialSelectionQuery: BigActInitialSelectionQuery,
    BigActFinalSelectionQuery: BigActFinalSelectionQuery,
    BigActResult: BigActResult,
    UserQuery: UserQuery,
    DocsQuery: DocsQuery,
    SpreadsheetsQuery: SpreadsheetsQuery,
    SpreadsheetQuery: SpreadsheetQuery,
    BigDataQuery: BigDataQuery,
    ExportBigDataSpreadsheetQuery: ExportBigDataSpreadsheetQuery,
    AgDashQuery: AgDashQuery,
    ValidateZarcQuery: ValidateZarcQuery,
};
