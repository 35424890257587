var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { DeleteAccountModal } from '@app/components/mol.delete-account-modal';
import { useFlashMessage } from '@app/components/obj.flash-message/flash-message.hook';
import { useUpdateUser } from '@app/domain/user/update-user.use-case';
import { useUserStore } from '@app/stores';
import { TrashIcon } from '@assets/icons/trash.icon';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { TextField } from '@atomic/atm.text-field';
import { H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Shadow, Spacing } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { useModalState } from '@atomic/obj.modal/modal.component.hook';
import { strings } from './user-data-section.strings';
export var UserDataSection = function () {
    var _a = useUserStore(), currentUserData = _a[0], setUserData = _a[1];
    var showSuccess = useFlashMessage().showSuccess;
    var _b = useState(false), isEditable = _b[0], setIsEditable = _b[1];
    var _c = useState(0), key = _c[0], setKey = _c[1];
    var _d = useUpdateUser({
        onCompleted: function (data) {
            setUserData(__assign(__assign({}, currentUserData), { company: data.updateUser.company, name: data.updateUser.name }));
            showSuccess(strings.feedbackSuccess);
            setIsEditable(false);
            setKey(function (prev) { return prev + 1; });
        },
    }), updateUserMutation = _d.updateUserMutation, loading = _d.loading;
    var handleEditableChange = function () {
        setIsEditable(function (prev) {
            if (prev) {
                setKey(function (prevKey) { return prevKey + 1; });
            }
            else {
                return !prev;
            }
        });
    };
    var handleSubmit = function (formData) {
        if (formData.hasError) {
            return;
        }
        updateUserMutation({
            data: { name: formData.data.name, company: formData.data.company },
        });
    };
    var _e = useModalState(), openModal = _e.openModal, closeModal = _e.closeModal, opened = _e.opened;
    return (React.createElement(React.Fragment, null,
        React.createElement(H2, null, strings.title),
        React.createElement(Separator, { size: Spacing.XSmall }),
        React.createElement(Card, { shadow: Shadow.Medium },
            React.createElement(DeleteAccountModal, { opened: opened, onCloseModal: closeModal }),
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, { hAlign: 'flex-end' },
                    React.createElement(Button, { variant: 'link', onClick: handleEditableChange }, isEditable ? strings.buttons.noEdit : strings.buttons.edit),
                    React.createElement(Separator, { size: Spacing.Small }))),
            React.createElement(Form, { onSubmit: handleSubmit, key: "user-data-form-key-".concat(key) },
                React.createElement(Grid, { fluid: true },
                    React.createElement(Row, { nogutter: true },
                        React.createElement(Col, { md: 12, xl: 12, xxl: 5 },
                            React.createElement(Form.Field, { name: Fields.name, label: strings.label.name, initialValue: currentUserData.name, validators: [Validators.Required(), Validators.FullNameRegex(strings.validatorFullName)] },
                                React.createElement(TextField, { disabled: !isEditable })),
                            React.createElement(Separator, { size: Spacing.Small })),
                        React.createElement(Col, { md: 12, xxl: 5, offset: { md: 0, xxl: 1 } },
                            React.createElement(Form.Field, { name: Fields.email, label: strings.label.email, initialValue: currentUserData.email, validators: [Validators.Required()] },
                                React.createElement(TextField, { disabled: true })),
                            React.createElement(Separator, { size: Spacing.Small })))),
                React.createElement(Grid, { fluid: true },
                    React.createElement(Row, { nogutter: true },
                        React.createElement(Col, { md: 12, xxl: 5 },
                            React.createElement(Form.Field, { name: Fields.company, label: strings.label.company, initialValue: currentUserData.company, validators: [Validators.Required()] },
                                React.createElement(TextField, { disabled: !isEditable })),
                            React.createElement(Separator, { size: Spacing.Small })))),
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { hAlign: 'center' },
                        React.createElement(Separator, { size: Spacing.Small }),
                        React.createElement(Button, { variant: 'primary', disabled: !isEditable, type: 'submit', loading: loading }, strings.buttons.submit))))),
        React.createElement(Separator, { size: Spacing.XSmall }),
        React.createElement(Button, { variant: 'link', alert: true, onClick: openModal },
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, { noGrow: true },
                    React.createElement(TrashIcon, null),
                    React.createElement(Separator, { size: Spacing.XXSmall })),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { vAlign: 'center' }, strings.buttons.delete)))));
};
var Fields = {
    name: 'name',
    email: 'email',
    company: 'company',
};
