var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Breakpoint, Color, IconSize, Spacing } from '@atomic/obj.constants';
var CONTACT_US_ICON_SIZE = {
    md: {
        width: 20,
        height: 7,
    },
    lg: {
        width: 340,
        height: 90,
    },
    large: {
        width: 32,
        height: 20,
    },
};
export var ContactUsCardWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  background: ", ";\n  border-radius: ", ";\n  ", ";\n"], ["\n  width: 100%;\n  background: ", ";\n  border-radius: ", ";\n  ", ";\n"])), Color.CallToActionXLight, Border.Radius, function (_a) {
    var large = _a.large;
    return large &&
        "min-height: ".concat(CONTACT_US_ICON_SIZE.large.height, "vw;\n");
});
export var StyledIcon = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: ", " 0 0 ", ";\n  width: ", "vw;\n  height: ", "vw;\n\n  @media all and (min-width: ", "px) {\n    width: ", "px;\n    height: ", "px;\n  }\n"], ["\n  border-radius: ", " 0 0 ", ";\n  width: ", "vw;\n  height: ", "vw;\n\n  @media all and (min-width: ", "px) {\n    width: ", "px;\n    height: ", "px;\n  }\n"])), Border.Radius, Border.Radius, CONTACT_US_ICON_SIZE.md.width, CONTACT_US_ICON_SIZE.md.height, Breakpoint.lg, CONTACT_US_ICON_SIZE.lg.width, CONTACT_US_ICON_SIZE.lg.height);
export var ArrowIconStyled = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n width:", " \n height:", " "], ["\n width:", " \n height:", " "])), IconSize.Small, IconSize.Small);
export var LargeStyledIcon = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  border-radius: ", ";\n"], ["\n  ", ";\n  ", ";\n  border-radius: ", ";\n"])), function (_a) {
    var height = _a.height;
    return (height ? height : "min-height: ".concat(CONTACT_US_ICON_SIZE.large.height, "vw"));
}, function (_a) {
    var width = _a.width;
    return (width ? width : "width: ".concat(CONTACT_US_ICON_SIZE.large.width, "vw"));
}, Border.Radius);
export var ContentWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), Spacing.Medium);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
