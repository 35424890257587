import { hasWindow } from '@app/core/browser';
import { FakeStorage } from './fake.storage';
var StorageType;
(function (StorageType) {
    StorageType["Local"] = "localStorage";
})(StorageType || (StorageType = {}));
export var Storages = {
    local: getStorage(StorageType.Local),
};
function getStorage(type) {
    var storage = getWindowStorage(type);
    return makeStorage(storage);
}
function getWindowStorage(type) {
    if (!hasWindow() || !window[type]) {
        return new FakeStorage();
    }
    else {
        return window[type];
    }
}
function makeStorage(storage) {
    return {
        get: function (key) {
            try {
                return JSON.parse(storage.getItem(key));
            }
            catch (err) {
                return null;
            }
        },
        put: function (key, value) {
            var data = JSON.stringify(value);
            storage.setItem(key, data);
        },
        remove: function (key) {
            storage.removeItem(key);
        },
    };
}
