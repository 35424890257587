var seedStageFiltered = function (data) {
    return data.reduce(function (acc, item) {
        if (item.seedStage === 'est') {
            acc.establishmentFiltered.push(item);
        }
        else if (item.seedStage === 'des') {
            acc.vegetativeDevelopmentFiltered.push(item);
        }
        else if (item.seedStage === 'flo') {
            acc.floweringFiltered.push(item);
        }
        else if (item.seedStage === 'fru') {
            acc.fruitingFiltered.push(item);
        }
        else if (item.seedStage === 'mat') {
            acc.maturationFiltered.push(item);
        }
        return acc;
    }, {
        establishmentFiltered: [],
        vegetativeDevelopmentFiltered: [],
        floweringFiltered: [],
        fruitingFiltered: [],
        maturationFiltered: [],
    });
};
export var agDashMapper = function (data) {
    var dataFiltered = seedStageFiltered(data);
    var uniqueHarvests = new Set();
    var uniqueData = data === null || data === void 0 ? void 0 : data.filter(function (item) {
        if (!uniqueHarvests.has(item.harvest)) {
            uniqueHarvests.add(item.harvest);
            return true;
        }
        return false;
    });
    return uniqueData === null || uniqueData === void 0 ? void 0 : uniqueData.map(function (item, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40;
        var harvest = item.harvest;
        return {
            harvest: harvest,
            accumulatedPrecipitation: {
                establishment: (_a = dataFiltered.establishmentFiltered[index]) === null || _a === void 0 ? void 0 : _a.accumulatedPrecipitation,
                vegetativeDevelopment: (_b = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _b === void 0 ? void 0 : _b.accumulatedPrecipitation,
                flowering: (_c = dataFiltered.floweringFiltered[index]) === null || _c === void 0 ? void 0 : _c.accumulatedPrecipitation,
                fruiting: (_d = dataFiltered.fruitingFiltered[index]) === null || _d === void 0 ? void 0 : _d.accumulatedPrecipitation,
                maturation: (_e = dataFiltered.maturationFiltered[index]) === null || _e === void 0 ? void 0 : _e.accumulatedPrecipitation,
            },
            availableWater: {
                establishment: (_f = dataFiltered.establishmentFiltered[index]) === null || _f === void 0 ? void 0 : _f.availableWater,
                vegetativeDevelopment: (_g = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _g === void 0 ? void 0 : _g.availableWater,
                flowering: (_h = dataFiltered.floweringFiltered[index]) === null || _h === void 0 ? void 0 : _h.availableWater,
                fruiting: (_j = dataFiltered.fruitingFiltered[index]) === null || _j === void 0 ? void 0 : _j.availableWater,
                maturation: (_k = dataFiltered.maturationFiltered[index]) === null || _k === void 0 ? void 0 : _k.availableWater,
            },
            averageTemperature: {
                establishment: (_l = dataFiltered.establishmentFiltered[index]) === null || _l === void 0 ? void 0 : _l.averageTemperature,
                vegetativeDevelopment: (_m = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _m === void 0 ? void 0 : _m.averageTemperature,
                flowering: (_o = dataFiltered.floweringFiltered[index]) === null || _o === void 0 ? void 0 : _o.averageTemperature,
                fruiting: (_p = dataFiltered.fruitingFiltered[index]) === null || _p === void 0 ? void 0 : _p.averageTemperature,
                maturation: (_q = dataFiltered.maturationFiltered[index]) === null || _q === void 0 ? void 0 : _q.averageTemperature,
            },
            criticalStorage: {
                establishment: (_r = dataFiltered.establishmentFiltered[index]) === null || _r === void 0 ? void 0 : _r.criticalStorage,
                vegetativeDevelopment: (_s = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _s === void 0 ? void 0 : _s.criticalStorage,
                flowering: (_t = dataFiltered.floweringFiltered[index]) === null || _t === void 0 ? void 0 : _t.criticalStorage,
                fruiting: (_u = dataFiltered.fruitingFiltered[index]) === null || _u === void 0 ? void 0 : _u.criticalStorage,
                maturation: (_v = dataFiltered.maturationFiltered[index]) === null || _v === void 0 ? void 0 : _v.criticalStorage,
            },
            dewPointTemperature: {
                establishment: (_w = dataFiltered.establishmentFiltered[index]) === null || _w === void 0 ? void 0 : _w.dewPointTemperature,
                vegetativeDevelopment: (_x = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _x === void 0 ? void 0 : _x.dewPointTemperature,
                flowering: (_y = dataFiltered.floweringFiltered[index]) === null || _y === void 0 ? void 0 : _y.dewPointTemperature,
                fruiting: (_z = dataFiltered.fruitingFiltered[index]) === null || _z === void 0 ? void 0 : _z.dewPointTemperature,
                maturation: (_0 = dataFiltered.maturationFiltered[index]) === null || _0 === void 0 ? void 0 : _0.dewPointTemperature,
            },
            hydroStorage: {
                establishment: (_1 = dataFiltered.establishmentFiltered[index]) === null || _1 === void 0 ? void 0 : _1.hydroStorage,
                vegetativeDevelopment: (_2 = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _2 === void 0 ? void 0 : _2.hydroStorage,
                flowering: (_3 = dataFiltered.floweringFiltered[index]) === null || _3 === void 0 ? void 0 : _3.hydroStorage,
                fruiting: (_4 = dataFiltered.fruitingFiltered[index]) === null || _4 === void 0 ? void 0 : _4.hydroStorage,
                maturation: (_5 = dataFiltered.maturationFiltered[index]) === null || _5 === void 0 ? void 0 : _5.hydroStorage,
            },
            maximumTemperature: {
                establishment: (_6 = dataFiltered.establishmentFiltered[index]) === null || _6 === void 0 ? void 0 : _6.maximumTemperature,
                vegetativeDevelopment: (_7 = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _7 === void 0 ? void 0 : _7.maximumTemperature,
                flowering: (_8 = dataFiltered.floweringFiltered[index]) === null || _8 === void 0 ? void 0 : _8.maximumTemperature,
                fruiting: (_9 = dataFiltered.fruitingFiltered[index]) === null || _9 === void 0 ? void 0 : _9.maximumTemperature,
                maturation: (_10 = dataFiltered.maturationFiltered[index]) === null || _10 === void 0 ? void 0 : _10.maximumTemperature,
            },
            minimumTemperature: {
                establishment: (_11 = dataFiltered.establishmentFiltered[index]) === null || _11 === void 0 ? void 0 : _11.minimumTemperature,
                vegetativeDevelopment: (_12 = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _12 === void 0 ? void 0 : _12.minimumTemperature,
                flowering: (_13 = dataFiltered.floweringFiltered[index]) === null || _13 === void 0 ? void 0 : _13.minimumTemperature,
                fruiting: (_14 = dataFiltered.fruitingFiltered[index]) === null || _14 === void 0 ? void 0 : _14.minimumTemperature,
                maturation: (_15 = dataFiltered.maturationFiltered[index]) === null || _15 === void 0 ? void 0 : _15.minimumTemperature,
            },
            photosyntheticallyActiveRadiation: {
                establishment: (_16 = dataFiltered.establishmentFiltered[index]) === null || _16 === void 0 ? void 0 : _16.photosyntheticallyActiveRadiation,
                vegetativeDevelopment: (_17 = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _17 === void 0 ? void 0 : _17.photosyntheticallyActiveRadiation,
                flowering: (_18 = dataFiltered.floweringFiltered[index]) === null || _18 === void 0 ? void 0 : _18.photosyntheticallyActiveRadiation,
                fruiting: (_19 = dataFiltered.fruitingFiltered[index]) === null || _19 === void 0 ? void 0 : _19.photosyntheticallyActiveRadiation,
                maturation: (_20 = dataFiltered.maturationFiltered[index]) === null || _20 === void 0 ? void 0 : _20.photosyntheticallyActiveRadiation,
            },
            potentialEvotranspiration: {
                establishment: (_21 = dataFiltered.establishmentFiltered[index]) === null || _21 === void 0 ? void 0 : _21.potentialEvotranspiration,
                vegetativeDevelopment: (_22 = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _22 === void 0 ? void 0 : _22.potentialEvotranspiration,
                flowering: (_23 = dataFiltered.floweringFiltered[index]) === null || _23 === void 0 ? void 0 : _23.potentialEvotranspiration,
                fruiting: (_24 = dataFiltered.fruitingFiltered[index]) === null || _24 === void 0 ? void 0 : _24.potentialEvotranspiration,
                maturation: (_25 = dataFiltered.maturationFiltered[index]) === null || _25 === void 0 ? void 0 : _25.potentialEvotranspiration,
            },
            precipitationDiffEvapotranspiration: {
                establishment: (_26 = dataFiltered.establishmentFiltered[index]) === null || _26 === void 0 ? void 0 : _26.precipitationDiffEvapotranspiration,
                vegetativeDevelopment: (_27 = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _27 === void 0 ? void 0 : _27.precipitationDiffEvapotranspiration,
                flowering: (_28 = dataFiltered.floweringFiltered[index]) === null || _28 === void 0 ? void 0 : _28.precipitationDiffEvapotranspiration,
                fruiting: (_29 = dataFiltered.fruitingFiltered[index]) === null || _29 === void 0 ? void 0 : _29.precipitationDiffEvapotranspiration,
                maturation: (_30 = dataFiltered.maturationFiltered[index]) === null || _30 === void 0 ? void 0 : _30.precipitationDiffEvapotranspiration,
            },
            realEvotranspiration: {
                establishment: (_31 = dataFiltered.establishmentFiltered[index]) === null || _31 === void 0 ? void 0 : _31.realEvotranspiration,
                vegetativeDevelopment: (_32 = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _32 === void 0 ? void 0 : _32.realEvotranspiration,
                flowering: (_33 = dataFiltered.floweringFiltered[index]) === null || _33 === void 0 ? void 0 : _33.realEvotranspiration,
                fruiting: (_34 = dataFiltered.fruitingFiltered[index]) === null || _34 === void 0 ? void 0 : _34.realEvotranspiration,
                maturation: (_35 = dataFiltered.maturationFiltered[index]) === null || _35 === void 0 ? void 0 : _35.realEvotranspiration,
            },
            windSpeed: {
                establishment: (_36 = dataFiltered.establishmentFiltered[index]) === null || _36 === void 0 ? void 0 : _36.windSpeed,
                vegetativeDevelopment: (_37 = dataFiltered.vegetativeDevelopmentFiltered[index]) === null || _37 === void 0 ? void 0 : _37.windSpeed,
                flowering: (_38 = dataFiltered.floweringFiltered[index]) === null || _38 === void 0 ? void 0 : _38.windSpeed,
                fruiting: (_39 = dataFiltered.fruitingFiltered[index]) === null || _39 === void 0 ? void 0 : _39.windSpeed,
                maturation: (_40 = dataFiltered.maturationFiltered[index]) === null || _40 === void 0 ? void 0 : _40.windSpeed,
            },
        };
    });
};
