var BaseStore = /** @class */ (function () {
    function BaseStore() {
        var _this = this;
        // designed to use with useSyncExternalStore
        this.subscribe = function (listener) {
            _this.listeners.push(listener);
            return function () {
                _this.listeners = _this.listeners.filter(function (l) { return l !== listener; });
            };
        };
        this.publish = function () {
            _this.listeners.forEach(function (listener) { return listener(); });
        };
        this.listeners = [];
    }
    return BaseStore;
}());
export { BaseStore };
// eslint-disable-next-line @typescript-eslint/no-namespace
export var IStore;
(function (IStore) {
    function isSetter(value) {
        return typeof value === 'function';
    }
    IStore.isSetter = isSetter;
})(IStore || (IStore = {}));
