import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartTooltipStyled } from '@app/components/atm.chart-tooltip-styled';
import { OptionWrapper } from '@app/components/atm.option-wrapper';
import { TooltipBoldIcon } from '@assets/icons/tooltip-bold.icon';
import { Card } from '@atomic/atm.card';
import { ControlledSelect } from '@atomic/atm.controlled-select';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color, ControlSizes, Spacing } from '@atomic/obj.constants';
import { agDashMapper } from './ad-dash-agroclimate-evolution-chart.mapper';
import { Inputs, strings } from './ag-dash-agroclimate-evolution-chart.strings';
import { ChartInfoStyled, ChartOptionWrapper, ColorPallet } from './ag-dash-agroclimate-evolution-chart.style';
export var AgDashAgroclimateEvolutionChart = function (props) {
    var _a = useState(AGROCLIMATE_OPTIONS[INITIAL_VALUE_INDEX].value), agroclimateValue = _a[0], setAgroClimateValue = _a[1];
    var _b = useState(), harvestValue = _b[0], setHarvestValue = _b[1];
    var _c = useState(false), effectTriggered = _c[0], setEffectTriggered = _c[1];
    var dataMapped = agDashMapper(props.agricultureClimate);
    var harvestIndex = dataMapped === null || dataMapped === void 0 ? void 0 : dataMapped.findIndex(function (data) { return data.harvest === harvestValue; });
    var dataMappedSliced = dataMapped === null || dataMapped === void 0 ? void 0 : dataMapped.slice(harvestIndex, harvestIndex + 5);
    useEffect(function () {
        if (!effectTriggered && dataMapped) {
            var initialHarvestValue = dataMapped[dataMapped.length - 5].harvest;
            setHarvestValue(initialHarvestValue);
            setEffectTriggered(true);
        }
    }, [dataMapped, effectTriggered]);
    return (React.createElement(Card, null,
        React.createElement(Hbox, null,
            React.createElement(Hbox.Separator, { size: Spacing.Large }),
            React.createElement(Hbox.Item, null,
                React.createElement(H3, null, strings.titile)),
            React.createElement(Hbox.Separator, null),
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center', hAlign: 'flex-end' },
                React.createElement(ChartOptionWrapper, null,
                    React.createElement(Hbox, { "$wrap": true },
                        React.createElement(Hbox.Item, null,
                            React.createElement(ControlledSelect, { options: AGROCLIMATE_OPTIONS, placeholder: strings.placeholder.agroClimateInfo, name: Inputs.agroClimateInfo, onValueChange: function (value) { return setAgroClimateValue(value); }, controlSize: ControlSizes.Large, initialValue: AGROCLIMATE_OPTIONS[INITIAL_VALUE_INDEX] })),
                        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
                        React.createElement(Hbox.Item, null,
                            React.createElement(ControlledSelect, { options: dataMapped === null || dataMapped === void 0 ? void 0 : dataMapped.map(function (item) {
                                    return { value: item.harvest, label: item.harvest };
                                }), disabled: !(dataMapped === null || dataMapped === void 0 ? void 0 : dataMapped.length), placeholder: strings.placeholder.harvest, name: Inputs.harvest, onValueChange: function (value) { return setHarvestValue(value); } })))))),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(ResponsiveContainer, { width: '100%', height: CHART_CONTAINER_HEIGHT },
            React.createElement(BarChart, { data: dataMappedSliced },
                React.createElement(CartesianGrid, { strokeDasharray: '3 3' }),
                React.createElement(XAxis, { dataKey: 'harvest' }),
                React.createElement(YAxis, null),
                React.createElement(Tooltip, { content: React.createElement(CustomTooltip, { agroclimate: agroclimateValue }) }),
                React.createElement(Legend, { content: React.createElement(CustomLabel, null) }),
                React.createElement(Bar, { dataKey: "".concat(agroclimateValue, ".establishment"), fill: ASSESSORY_BLUE_WITH_OPACITY }),
                React.createElement(Bar, { dataKey: "".concat(agroclimateValue, ".vegetativeDevelopment"), fill: ASSESSORY_BLUE_WITH_SMALL_OPACITY }),
                React.createElement(Bar, { dataKey: "".concat(agroclimateValue, ".flowering"), fill: Color.AccessoryBlue }),
                React.createElement(Bar, { dataKey: "".concat(agroclimateValue, ".fruiting"), fill: Color.AccessoryBlueDark }),
                React.createElement(Bar, { dataKey: "".concat(agroclimateValue, ".maturation"), fill: Color.AccessoryBlueXDark }))),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(ChartInfoStyled, null,
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                    React.createElement(TooltipBoldIcon, null)),
                React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
                React.createElement(Hbox.Item, null,
                    React.createElement(Body, null, strings.chartInfo))))));
};
export var CustomLabel = function () {
    return (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { hAlign: 'center' },
            React.createElement(Separator, { size: Spacing.Small }),
            React.createElement(Hbox, { "$wrap": true },
                React.createElement(OptionWrapper, null,
                    React.createElement(ColorPallet, { bgColor: ASSESSORY_BLUE_WITH_OPACITY }),
                    React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                        React.createElement(Body, null, strings.chartLegend.establishment)),
                    React.createElement(Hbox.Separator, null)),
                React.createElement(OptionWrapper, null,
                    React.createElement(ColorPallet, { bgColor: ASSESSORY_BLUE_WITH_SMALL_OPACITY }),
                    React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                        React.createElement(Body, null, strings.chartLegend.vegetativeDevelopment)),
                    React.createElement(Hbox.Separator, null)),
                React.createElement(OptionWrapper, null,
                    React.createElement(ColorPallet, { bgColor: Color.AccessoryBlue }),
                    React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                        React.createElement(Body, null, strings.chartLegend.flowering)),
                    React.createElement(Hbox.Separator, null)),
                React.createElement(OptionWrapper, null,
                    React.createElement(ColorPallet, { bgColor: Color.AccessoryBlueDark }),
                    React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                        React.createElement(Body, null, strings.chartLegend.fruiting)),
                    React.createElement(Hbox.Separator, null)),
                React.createElement(OptionWrapper, null,
                    React.createElement(ColorPallet, { bgColor: Color.AccessoryBlueXDark }),
                    React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, { hAlign: 'center', noGrow: true },
                        React.createElement(Body, null, strings.chartLegend.maturation)))))));
};
var CustomTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, agroclimate = _a.agroclimate;
    if (active && payload && payload.length) {
        var dataPoint = payload[0].payload;
        return (React.createElement(ChartTooltipStyled, { noGutter: true },
            dataPoint[agroclimate].establishment && (React.createElement(Body, null,
                strings.tooltip.establishment,
                " ",
                dataPoint[agroclimate].establishment.toFixed(2))),
            dataPoint[agroclimate].vegetativeDevelopment && (React.createElement(Body, null,
                strings.tooltip.vegetativeDevelopment,
                " ",
                dataPoint[agroclimate].vegetativeDevelopment.toFixed(2))),
            dataPoint[agroclimate].flowering && (React.createElement(Body, null,
                strings.tooltip.flowering,
                " ",
                dataPoint[agroclimate].flowering.toFixed(2))),
            dataPoint[agroclimate].fruiting && (React.createElement(Body, null,
                strings.tooltip.fruiting,
                " ",
                dataPoint[agroclimate].fruiting.toFixed(2))),
            dataPoint[agroclimate].maturation && (React.createElement(Body, null,
                strings.tooltip.maturation,
                " ",
                dataPoint[agroclimate].maturation.toFixed(2)))));
    }
    return null;
};
var CHART_CONTAINER_HEIGHT = 600;
var AGROCLIMATE_OPTIONS = [
    { value: 'accumulatedPrecipitation', label: 'Precipitação Acumulada (mm)' },
    { value: 'availableWater', label: 'Água Disponível (mm)' },
    { value: 'averageTemperature', label: 'Temperatura Média (ºC)' },
    { value: 'criticalStorage', label: 'Armazenamento Crítico (mm)' },
    { value: 'dewPointTemperature', label: 'Temperatura Média do Ponto do Orvalho (ºC)' },
    { value: 'hydroStorage', label: 'Armazenamento Hídrico (mm)' },
    { value: 'maximumTemperature', label: 'Temperatura Máxima (ºC)' },
    { value: 'minimumTemperature', label: 'Temperatura Mínima (ºC)' },
    { value: 'photosyntheticallyActiveRadiation', label: 'Radiação Fotossinteticamente Ativa' },
    { value: 'potentialEvotranspiration', label: 'Evapotranspiração Potencial (mm)' },
    { value: 'precipitationDiffEvapotranspiration', label: 'Dif. Precipitação e Evapotranspiração Potencial (mm)' },
    { value: 'realEvotranspiration', label: 'Evapotranspiração Real (mm)' },
    { value: 'windSpeed', label: 'Velocidade do Vento (m/s)' },
];
var ASSESSORY_BLUE_WITH_SMALL_OPACITY = 'rgba(27, 181, 163, 0.8)';
var ASSESSORY_BLUE_WITH_OPACITY = 'rgba(27, 181, 163, 0.6)';
var INITIAL_VALUE_INDEX = 11;
