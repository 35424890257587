import React from 'react';
import { BigNumberCard } from '@app/components/mol.big-number-card';
import { useMatchMedia } from '@atomic/atm.match-media';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { agDashCardsSectionString as strings } from './ag-dash-cards-section.strings';
export var AgDashCardsSection = function (props) {
    var _a, _b;
    var isTablet = useMatchMedia('xxl').matchBreakpoint;
    var isSmall = useMatchMedia('lg').matchBreakpoint;
    var soilName = (_b = (_a = props.soilName) === null || _a === void 0 ? void 0 : _a.split('-')[1]) === null || _b === void 0 ? void 0 : _b.replace(' ', '');
    return (React.createElement(Hbox, { "$wrap": true },
        React.createElement(Hbox.Item, null,
            React.createElement(BigNumberCard, { title: strings.referenceProductivity.title, toolTip: strings.referenceProductivity.tooltip, description: strings.referenceProductivity.description, units: strings.referenceProductivity.units, bigNumber: props.referenceProductivity, loading: props.loading, rightTooltip: isSmall }),
            React.createElement(Separator, { size: Spacing.XSmall })),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, null,
            React.createElement(BigNumberCard, { title: strings.latestPrediction.title, toolTip: strings.latestPrediction.tooltip, description: strings.latestPrediction.description, units: strings.latestPrediction.units, bigNumber: props.latestPrediction, loading: props.loading, rightTooltip: isTablet }),
            React.createElement(Separator, { size: Spacing.XSmall })),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, null,
            React.createElement(BigNumberCard, { title: strings.predominantSoil.title, toolTip: strings.predominantSoil.tooltip, description: soilName ? strings.predominantSoil.soilDescription(soilName) : strings.predominantSoil.defaultDescription, units: strings.predominantSoil.units, bigNumber: props.predominantSoil, loading: props.loading, rightTooltip: isTablet }),
            React.createElement(Separator, { size: Spacing.XSmall })),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, null,
            React.createElement(BigNumberCard, { title: strings.imbrScore.title, toolTip: strings.imbrScore.tooltip, description: strings.imbrScore.description, bigNumber: props.riskIndicator, loading: props.loading, rightTooltip: true }),
            React.createElement(Separator, { size: Spacing.XSmall }))));
};
