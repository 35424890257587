import React from 'react';
import { useNavigate } from 'react-router-dom';
import { InputCaptionWarning } from '@app/components/atm.input-caption-warning';
import { DocsModal } from '@app/components/mol.docs-modal';
import { useCreateUser } from '@app/domain';
import { AuthRoutes } from '@app/modules/auth/routes';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { TextField } from '@atomic/atm.text-field';
import { Body, BodySecondary, H1, InputLabel } from '@atomic/atm.typography';
import { FlashMessage } from '@atomic/mol.flash-message';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Form, FormField, Validators } from '@atomic/obj.form';
import { useModalState } from '@atomic/obj.modal/modal.component.hook';
import { signUpFormStrings as strings } from './sign-up-form.strings';
import { LineStyled, StyledBox } from './sign-up-form.style';
export var SignUpForm = function (props) {
    var _a = useCreateUser({
        onCompleted: function () { return navigate("".concat(AuthRoutes.signupConfirm, "?email=").concat(encodeURIComponent(email))); },
    }), createUser = _a.createUser, requestError = _a.error, loading = _a.loading;
    var _b = React.useState(''), email = _b[0], setEmail = _b[1];
    var _c = React.useState(''), password = _c[0], setPassword = _c[1];
    var _d = React.useState(false), passwordError = _d[0], setPasswordError = _d[1];
    var navigate = useNavigate();
    var handleSubmit = function (formData) {
        if (formData.hasError) {
            return;
        }
        var data = formData.data;
        delete data.confirmPassword;
        delete data.acceptedTerms;
        createUser({ data: data });
    };
    var _e = useModalState(), openModal = _e.openModal, closeModal = _e.closeModal, opened = _e.opened;
    var _f = useModalState(), openTermsOfUse = _f.openModal, closeTermsOfUse = _f.closeModal, stateTermsOfUse = _f.opened;
    return (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { noGrow: true },
            React.createElement(Separator, null),
            React.createElement(H1, { noMargin: true, center: true }, strings.title),
            React.createElement(Separator, { size: Spacing.XXSmall }),
            React.createElement(Body, { center: true }, strings.description),
            React.createElement(Separator, null),
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement(FormField, { name: fields.name, label: strings.label.name, validators: [Validators.Required(), Validators.FullNameRegex(strings.validatorMessage.fullName)] },
                    React.createElement(InputLabel, null,
                        React.createElement(TextField, { inputLabel: true }))),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(FormField, { name: fields.email, label: strings.label.email, validators: [Validators.Required(), Validators.EmailRegex(strings.validatorMessage.email)], onValueChange: setEmail },
                    React.createElement(InputLabel, null,
                        React.createElement(TextField, { inputLabel: true, type: 'email' }))),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(FormField, { name: fields.company, label: strings.label.company },
                    React.createElement(InputLabel, null,
                        React.createElement(TextField, { inputLabel: true }))),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(FormField, { name: fields.password, label: strings.label.password, validators: [Validators.Required(), Validators.PasswordRegex()], onValueChange: setPassword, onError: function (errors) {
                        var isRequiredError = errors.some(function (error) { return error.name === 'Required'; });
                        setPasswordError(!isRequiredError);
                    } },
                    React.createElement(InputLabel, null,
                        React.createElement(TextField, { type: 'password', inputLabel: true, hasIconEnd: true })),
                    !passwordError && React.createElement(InputCaptionWarning, null, strings.captionWarning)),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(FormField, { name: fields.confirmPassword, label: strings.label.confirmPassword, validators: [
                        Validators.Required(),
                        Validators.IsNotEqualToField(password, strings.validatorMessage.confirmPassword),
                    ] },
                    React.createElement(InputLabel, null,
                        React.createElement(TextField, { type: 'password', inputLabel: true, hasIconEnd: true }))),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(FormField, { name: fields.acceptedTerms, validators: [Validators.Required()] },
                    React.createElement(Hbox, null,
                        React.createElement(Hbox.Item, { noGrow: true },
                            React.createElement(CheckboxField, { id: 'termsOfUseAndPrivacyPolicy' })),
                        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
                        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                            React.createElement(StyledBox, null,
                                React.createElement(Hbox.Item, { noGrow: true },
                                    React.createElement(Body, null, strings.label.termsOfUseAndPolicyOfPrivacy.base)),
                                React.createElement(Hbox.Item, { noGrow: true },
                                    React.createElement(Button, { variant: 'link', onClick: openTermsOfUse }, strings.label.termsOfUseAndPolicyOfPrivacy.termsOfUse)),
                                React.createElement(DocsModal, { onCloseModal: closeTermsOfUse, opened: stateTermsOfUse, title: strings.modalTitles.termsOfUse, docUrl: props.termsOfUseUrl }),
                                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                                    React.createElement(Body, null, strings.label.termsOfUseAndPolicyOfPrivacy.and)),
                                React.createElement(Hbox.Item, { noGrow: true },
                                    React.createElement(Button, { variant: 'link', onClick: openModal }, strings.label.termsOfUseAndPolicyOfPrivacy.policyOfPrivacy),
                                    React.createElement(DocsModal, { onCloseModal: closeModal, opened: opened, title: strings.modalTitles.privacyPolice, docUrl: props.privacyPolicyUrl })))))),
                React.createElement(Separator, null),
                !!requestError && (React.createElement(FlashMessage, { type: 'alert', dismissible: true, mb: true }, requestError.message)),
                React.createElement(Button, { variant: 'primary', type: 'submit', expanded: true, loading: loading }, strings.buttons.submit),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(Divider, null),
                React.createElement(Separator, { size: Spacing.XSmall }),
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, { noGrow: true, hAlign: 'center' },
                        React.createElement(Body, { color: 'GrayXDark' }, strings.loginPrefix)),
                    React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                    React.createElement(Hbox.Item, { noGrow: true, hAlign: 'center' },
                        React.createElement(Button, { variant: 'link', to: AuthRoutes.login }, strings.buttons.login)))),
            React.createElement(Separator, null))));
};
var Divider = function () { return (React.createElement(Hbox, null,
    React.createElement(Hbox.Item, { vAlign: 'center' },
        React.createElement(LineStyled, null)),
    React.createElement(Hbox.Item, { noGrow: true },
        React.createElement(BodySecondary, null, strings.dividerConnective)),
    React.createElement(Hbox.Item, { vAlign: 'center' },
        React.createElement(LineStyled, null)))); };
var fields = {
    name: 'name',
    email: 'email',
    company: 'company',
    password: 'password',
    confirmPassword: 'confirmPassword',
    acceptedTerms: 'acceptedTerms',
};
