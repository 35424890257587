export var strings = {
    titile: 'Evolução agroclimática em cada estágio da semente',
    chartInfo: "A IMBR Agro compartilha, tamb\u00E9m, as informa\u00E7\u00F5es agroclim\u00E1ticas que alimentam seu modelo de produtividade,\n              divididas pelo est\u00E1dio fenol\u00F3gico da cultura agr\u00EDcola.",
    chartLegend: {
        establishment: 'Estabelecimento',
        vegetativeDevelopment: 'Desen. Vegetativo',
        flowering: 'Floração',
        fruiting: 'Frutificação',
        maturation: 'Maturação',
    },
    placeholder: {
        agroClimateInfo: 'Informação climática',
        harvest: 'Safra',
    },
    tooltip: {
        establishment: 'Estabelecimento: ',
        vegetativeDevelopment: 'Desen. Vegetativo: ',
        flowering: 'Floração: ',
        fruiting: 'Frutificação: ',
        maturation: 'Maturação: ',
    },
    loading: 'Por favor, aguarde enquanto processamos a requisição',
};
export var Inputs = {
    agroClimateInfo: 'agroClimateInfo',
    harvest: 'harvest',
};
export var placeholderStrings = {
    title: 'Comece a visualizar seus dados:',
    instructions: {
        one: {
            title: '1',
            description: 'Escolha uma de suas planilhas importadas na plataforma.',
        },
        two: {
            title: '2',
            description: 'Aplique a Latitude/Longitude que deseja consultar.',
        },
        three: {
            title: '3',
            description: 'Aplique os filtros para começar a ver os gráficos.',
        },
    },
};
