var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Fade } from '@atomic/obj.animation/animation.component.style';
import { FlashMessageCloseStyled, FlashMessageContentStyled, FlashMessageStyled, } from './flash-message.component.style';
var timeToHide = 10 * 1000;
export var FlashMessage = function (props) {
    var _a = React.useState({
        hidden: false,
        remove: false,
    }), flashMessageState = _a[0], setFlashMessageState = _a[1];
    React.useEffect(function () {
        var timeoutList = [];
        var startCloseTimer = function () {
            if (props.autoClose) {
                var timeout = setTimeout(function () { return startRemoveFromDomAnimation(); }, timeToHide);
                timeoutList.push(timeout);
            }
        };
        var startRemoveFromDomAnimation = function () {
            setFlashMessageState({ hidden: true });
            var timeout = setTimeout(function () { return removeFromDom(); }, 300);
            timeoutList.push(timeout);
        };
        var removeFromDom = function () {
            var _a;
            setFlashMessageState({ remove: true });
            clearTimeoutList();
            (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
        };
        var clearTimeoutList = function () {
            timeoutList.forEach(function (element) { return clearTimeout(element); });
        };
        startCloseTimer();
        return clearTimeoutList;
    }, [props]);
    var children = props.children, dismissible = props.dismissible, other = __rest(props, ["children", "dismissible"]);
    return (!flashMessageState.remove && (React.createElement(Fade, { show: !flashMessageState.hidden },
        React.createElement(FlashMessageStyled, __assign({}, other),
            React.createElement(FlashMessageContentStyled, null, children),
            dismissible && (React.createElement(FlashMessageCloseStyled, __assign({ onClick: props.onClose }, other),
                React.createElement(FaIcon.Close, null)))))));
};
FlashMessage.defaultProps = {
    type: 'warning',
    dismissible: true,
    outlined: false,
    autoClose: true,
};
