import React from 'react';
import { AlterPasswordSection } from '@app/components/mol.alter-password-section';
import { ContactUsCard } from '@app/components/mol.contact-us-card';
import { UserDataSection } from '@app/components/mol.user-data-section';
import { H1 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { strings } from './account.strings';
export var AccountPage = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { fluid: true },
            React.createElement(Row, null,
                React.createElement(Col, { md: 12 },
                    React.createElement(H1, null, strings.title),
                    React.createElement(Separator, { size: Spacing.Large }))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 12, xl: 8 },
                    React.createElement(UserDataSection, null),
                    React.createElement(Separator, null)),
                React.createElement(Col, { md: 12, xl: 4 },
                    React.createElement(AlterPasswordSection, null))),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement(Separator, null),
                    React.createElement(ContactUsCard, null),
                    React.createElement(Separator, { size: Spacing.Large }))))));
};
