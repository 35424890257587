var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Border, Color, FontFamily, FontSize, FontWeight, LineHeight, Spacing } from '@atomic/obj.constants';
export var HDisplay = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"])), function (_a) {
    var _b;
    var color = _a.color;
    return (_b = Color[color]) !== null && _b !== void 0 ? _b : Color.Black;
}, FontSize.XXLarge, FontFamily.Secondary, FontWeight.Bold, LineHeight.Medium, function (_a) {
    var center = _a.center;
    return center && 'text-align: center; display: block';
}, function (_a) {
    var noData = _a.noData;
    return noData && 'opacity: 0.5';
});
HDisplay.displayName = 'HDisplay';
export var H1 = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"])), Color.Black, FontSize.XLarge, FontFamily.Secondary, FontWeight.Bold, LineHeight.Medium, function (_a) {
    var center = _a.center;
    return center && 'text-align: center';
}, function (_a) {
    var noMargin = _a.noMargin;
    return noMargin && 'margin: 0';
});
export var H2 = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  line-height: ", ";\n  font-weight: ", ";\n  ", ";\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  line-height: ", ";\n  font-weight: ", ";\n  ", ";\n  ", ";\n"])), function (_a) {
    var color = _a.color;
    return (color ? Color[color] : Color.Black);
}, FontSize.Large, FontFamily.Secondary, LineHeight.Medium, FontWeight.SemiBold, function (_a) {
    var center = _a.center;
    return center && 'text-align: center';
}, function (_a) {
    var noMargin = _a.noMargin;
    return noMargin && 'margin: 0';
});
H2.displayName = 'H2';
export var H3Height = FontSize.Medium;
export var H3 = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"])), function (_a) {
    var color = _a.color;
    return (color ? Color[color] : Color.Black);
}, H3Height, FontFamily.Secondary, FontWeight.SemiBold, LineHeight.Large, function (_a) {
    var center = _a.center;
    return center && 'text-align: center';
}, function (_a) {
    var noMargin = _a.noMargin;
    return noMargin && 'margin: 0';
});
H3.displayName = 'H3';
export var H4Height = FontSize.Small;
export var H4 = styled.h4(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"])), Color.GrayXDark, H4Height, FontFamily.Secondary, FontWeight.SemiBold, LineHeight.Large, function (_a) {
    var center = _a.center;
    return center && 'text-align: center';
}, function (_a) {
    var noMargin = _a.noMargin;
    return noMargin && 'margin: 0';
});
H4.displayName = 'H4';
export var Body = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  margin: 0;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"], ["\n  color: ", ";\n  margin: 0;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  ", ";\n"])), function (_a) {
    var color = _a.color;
    return (color ? Color[color] : Color.GrayDark);
}, FontSize.XSmall, FontFamily.Primary, FontWeight.Medium, LineHeight.Large, function (_a) {
    var center = _a.center;
    return center && 'text-align: center';
}, function (_a) {
    var noMargin = _a.noMargin;
    return noMargin && 'margin: 0';
});
Body.displayName = 'Body';
export var BodySecondary = styled.label(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  margin: 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  ", ";\n  margin: 0;\n"])), function (_a) {
    var color = _a.color;
    return (color ? Color[color] : Color.GrayDark);
}, FontSize.XXSmall, FontFamily.Primary, function (_a) {
    var strong = _a.strong;
    return (strong ? FontWeight.Bold : FontWeight.Medium);
}, LineHeight.Large, function (_a) {
    var center = _a.center;
    return center && 'text-align: center';
});
export var LinkSmall = styled.a(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", "\n  line-height: ", ";\n  margin: 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", "\n  line-height: ", ";\n  margin: 0;\n"])), Color.PrimaryDark, FontSize.XXSmall, FontFamily.Primary, LineHeight.Large);
export var LinkMedium = styled.a(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", "\n  line-height: ", ";\n  margin: 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", "\n  line-height: ", ";\n  margin: 0;\n"])), Color.PrimaryDark, FontSize.XSmall, FontFamily.Primary, LineHeight.Large);
export var InputLabel = styled.label(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  line-height: ", ";\n"], ["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  line-height: ", ";\n"])), function (props) { return (props.hasError ? Color.Alert : props.filled ? Color.Primary : Color.GrayDark); }, FontSize.Small, FontFamily.Primary, FontWeight.Normal, Spacing.XXSmall, LineHeight.Large);
export var InputValueCss = css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n"])), Color.GrayXDark, FontSize.Small, FontFamily.Primary, FontWeight.Normal, LineHeight.Large);
export var InputValue = styled.label(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), InputValueCss);
export var InputDisabledCss = css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n\n  /* https://stackoverflow.com/a/45209441/3670829 */\n  -webkit-text-fill-color: ", ";\n  opacity: 1;\n  -webkit-opacity: 1;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n\n  /* https://stackoverflow.com/a/45209441/3670829 */\n  -webkit-text-fill-color: ", ";\n  opacity: 1;\n  -webkit-opacity: 1;\n"])), Color.Gray, FontSize.Small, FontFamily.Primary, FontWeight.Normal, Color.Gray);
export var InputDisabled = styled.label(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), InputDisabledCss);
export var InputCaption = styled.label(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n  line-height: ", ";\n  display: block;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n  line-height: ", ";\n  display: block;\n"])), Color.GrayXDark, FontSize.Small, FontFamily.Primary, FontWeight.Normal, Spacing.XXSmall, Spacing.XXSmall, LineHeight.Large);
export var InputCaptionError = styled(InputCaption)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: ", ";\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n"], ["\n  color: ", ";\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n"])), Color.Alert, Color.AlertLight, Spacing.XXSmall, Border.SmallRadius);
export var InputPlaceholderCss = css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"])), Color.Gray, FontSize.Small, FontFamily.Primary, FontWeight.Normal);
export var InputPlaceholder = styled.label(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  pointer-events: none;\n  ", ";\n"], ["\n  pointer-events: none;\n  ", ";\n"])), InputPlaceholderCss);
// Utilities
export var Ellipsed = styled.span(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  max-width: 100%;\n  display: inline-block;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"], ["\n  max-width: 100%;\n  display: inline-block;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
