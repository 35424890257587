export var signUpFormStrings = {
    title: 'Cadastre-se!',
    description: 'Para entrar na plataforma é necessário entrar ou criar uma conta.',
    label: {
        name: 'Nome completo',
        email: 'Email',
        company: 'Empresa (opcional)',
        password: 'Senha',
        confirmPassword: 'Repetir senha',
        termsOfUseAndPolicyOfPrivacy: {
            base: 'Ao realizar o cadastro em nossa Plataforma, você declara que concorda com o disposto nos ',
            termsOfUse: 'Termos de Uso da IMBR Agro',
            and: 'e estou Ciente do',
            policyOfPrivacy: 'Aviso de Privacidade da IMBR Agro',
        },
    },
    validatorMessage: {
        email: 'Endereço de e-mail inválido. Por favor forneça um e-mail válido',
        confirmPassword: 'As senhas devem ter o mesmo valor.',
        fullName: 'Por favor, insira seu nome completo',
    },
    buttons: {
        submit: 'Cadastar',
        login: 'Faça login!',
    },
    captionWarning: 'A senha precisa ter 8 caracteres e 1 número',
    dividerConnective: 'OU',
    loginPrefix: 'Já possui uma conta?',
    modalTitles: {
        termsOfUse: 'Termos de Uso do Serviço IMBR Agro',
        privacyPolice: 'Política de Privacidade do Serviço IMBR Agro',
    },
};
