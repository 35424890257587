import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
export function useSendConfirmationEmailMutation(params) {
    var _a = useMutation('SendConfirmationEmailMutation', params), sendConfirmationEmailMutation = _a[0], _b = _a[1], data = _b.data, error = _b.error, loading = _b.loading;
    return {
        sendConfirmationEmailMutation: sendConfirmationEmailMutation,
        data: data,
        error: error,
        loading: loading,
    };
}
