var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Card } from '@atomic/atm.card';
import { Border, Color, Spacing, TransitionDuration, ZIndex } from '@atomic/obj.constants';
export var UserDropdownWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  margin-top: auto;\n"], ["\n  position: relative;\n  margin-top: auto;\n"])));
var DROPDOWN_POSITION = '-150px';
export var UserDropdownStyled = styled(Card)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  z-index: ", ";\n  top: ", ";\n  right: ", ";\n  transition: ", ";\n\n  ", "\n"], ["\n  position: absolute;\n  z-index: ", ";\n  top: ", ";\n  right: ", ";\n  transition: ", ";\n\n  ", "\n"])), ZIndex.top, DROPDOWN_POSITION, Spacing.Large, TransitionDuration, function (_a) {
    var isOpened = _a.isOpened;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      visibility: ", ";\n      opacity: ", ";\n    "], ["\n      visibility: ", ";\n      opacity: ", ";\n    "])), isOpened ? 'visible' : 'hidden', isOpened ? 1 : 0);
});
export var UserDropdownOption = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  padding: ", " ", ";\n  text-decoration: none;\n  ", "\n\n  & *:hover {\n    cursor: pointer;\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  padding: ", " ", ";\n  text-decoration: none;\n  ", "\n\n  & *:hover {\n    cursor: pointer;\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), Spacing.XXSmall, Spacing.XSmall, function (_a) {
    var $borderRadius = _a.$borderRadius;
    return $borderRadius && "border-radius: 0 0 ".concat(Border.Radius, " ").concat(Border.Radius, ";");
}, Color.CallToActionXLight);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
