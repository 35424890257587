import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '@app/components/atm.back-button';
import { ImageBackground } from '@app/components/atm.image-background';
import { useFlashMessage } from '@app/components/obj.flash-message/flash-message.hook';
import { useParams } from '@app/core/route/query-params-hook';
import { useResetPasswod } from '@app/domain';
import { AuthRoutes } from '@app/modules/auth/routes';
import BackgroundLoginImage from '@assets/img/background-login.jpg';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { H1, InputLabel } from '@atomic/atm.typography';
import { FlashMessage } from '@atomic/mol.flash-message';
import { Separator } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { strings } from './password-recovery.strings';
var Screen;
(function (Screen) {
    Screen[Screen["form"] = 0] = "form";
    Screen[Screen["expired"] = 1] = "expired";
})(Screen || (Screen = {}));
export var PasswordRecoveryPage = function () {
    var _a = useParams(), code = _a.code, email = _a.email, expiration = _a.expiration;
    var expirationDate = new Date(+expiration);
    var currentDate = new Date();
    var screenIsExpired = expirationDate.getTime() < currentDate.getTime();
    var screen = screenIsExpired ? Screen.expired : Screen.form;
    return (React.createElement(Grid, { fluid: true },
        React.createElement(Row, { nogutter: true },
            React.createElement(Col, { md: 6 },
                React.createElement(BackButton, { to: AuthRoutes.login, text: strings.button.back }),
                React.createElement(Row, { justify: 'center', align: 'center', "$allHeight": true },
                    React.createElement(Col, { md: 10, lg: 8, xxl: 6 }, screen === Screen.form ? React.createElement(ResetPasswordForm, { code: code, email: email }) : React.createElement(CodeExpired, null)))),
            React.createElement(Col, { md: 6 },
                React.createElement(ImageBackground, { src: BackgroundLoginImage })))));
};
var ResetPasswordForm = function (_a) {
    var code = _a.code, email = _a.email;
    var showSuccess = useFlashMessage().showSuccess;
    var navigate = useNavigate();
    var _b = useResetPasswod({
        onCompleted: function (data) {
            showSuccess(data.resetPassword);
            navigate(AuthRoutes.login);
        },
    }), resetPassword = _b.resetPassword, error = _b.error;
    var _c = React.useState(''), passwordToVerify = _c[0], setPasswordToVerify = _c[1];
    var handleSubmit = function (formData) {
        if (formData.hasError) {
            return;
        }
        resetPassword({ data: { code: code, email: email, password: formData.data.password } });
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(H1, null, strings.title),
        React.createElement(Form.Field, { name: Fields.email, label: strings.formLabel.email, validators: [Validators.Required(), Validators.EmailRegex()], initialValue: email, onValueChange: function (value) {
                setPasswordToVerify(value);
            } },
            React.createElement(InputLabel, null,
                React.createElement(TextField, { inputLabel: true, type: 'text' }))),
        React.createElement(Form.Field, { name: Fields.code, label: strings.formLabel.code, validators: [Validators.Required()], onValueChange: function (value) {
                setPasswordToVerify(value);
            }, initialValue: code },
            React.createElement(InputLabel, null,
                React.createElement(TextField, { inputLabel: true, type: 'number' }))),
        React.createElement(Form.Field, { name: Fields.password, label: strings.formLabel.password, validators: [Validators.Required(), Validators.PasswordRegex()], onValueChange: function (value) {
                setPasswordToVerify(value);
            } },
            React.createElement(InputLabel, null,
                React.createElement(TextField, { inputLabel: true, type: 'password' }))),
        React.createElement(Form.Field, { name: Fields.confirmPassword, label: strings.formLabel.confirmPassword, validators: [
                Validators.Required(),
                Validators.IsNotEqualToField(passwordToVerify, strings.validator.isNotEqual),
            ] },
            React.createElement(InputLabel, null,
                React.createElement(TextField, { inputLabel: true, type: 'password' })),
            React.createElement(Separator, null),
            !!error && (React.createElement(FlashMessage, { type: 'alert', dismissible: true, mb: true }, error.message)),
            React.createElement(Button, { variant: 'primary', type: 'submit' }, strings.button.submit))));
};
var Fields = {
    email: 'email',
    code: 'code',
    password: 'password',
    confirmPassword: 'confirmPassword',
};
var CodeExpired = function () {
    var navigate = useNavigate();
    var hundleRedirect = function () { return navigate(AuthRoutes.forgotPassword); };
    return (React.createElement(React.Fragment, null,
        React.createElement(H1, null, strings.expiredTitle),
        React.createElement(Button, { variant: 'primary', onClick: hundleRedirect }, strings.expiredButton)));
};
