import React from 'react';
import { VideoPlayer } from '@app/components/atm.video-player';
import { H2 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { agDashVideoModalStrings as strings } from './ag-dash-video-modal.strings';
export var AgDashVideoModal = function (props) {
    return (React.createElement(Modal, { opened: props.opened, onClose: props.onClose, small: props.small },
        React.createElement(H2, null, strings.title),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(VideoPlayer, { src: strings.videoUrl, title: strings.aboutVideo }),
        React.createElement(Separator, { size: Spacing.Small })));
};
