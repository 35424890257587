import React from 'react';
import { SpreadsheetTableRow } from '@app/components/atm.spreadsheet-table-row';
import { useFlashMessage } from '@app/components/obj.flash-message/flash-message.hook';
import { useUpdateSpreadsheet, useDeleteSpreadsheet } from '@app/domain';
import { SpreadsheetSmallIcon } from '@assets/icons/spreadsheet-small.icon';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { TBodyTypography, TButton, TD, TH, THead, THeadTypography, TR, Table } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { strings } from './spreadsheets-table.strings';
export var SpreadsheetsTable = function (props) {
    var _a, _b, _c;
    var _d = useFlashMessage(), showSuccess = _d.showSuccess, showError = _d.showError;
    var _e = useDeleteSpreadsheet({
        onCompleted: function (result) {
            showSuccess(result.deleteSpreadsheet), props.onRefetch();
        },
        onError: function (err) { return showError(err.message); },
    }), deleteSpreadsheet = _e.deleteSpreadsheet, deleteLoading = _e.loading;
    var _f = useUpdateSpreadsheet({
        onCompleted: function () {
            props.onRefetch();
            showSuccess(strings.updateSpreadsheetSuccess);
        },
        onError: function (err) {
            showError(err.message);
        },
    }), updateSpreadsheet = _f.updateSpreadsheet, updateLoading = _f.loading;
    var handleDeleteSpreadsheet = function (id) {
        deleteSpreadsheet({ data: { id: id } });
    };
    var handleFileSelect = function (e, id) {
        var selectedFile = e.target.files[0];
        updateSpreadsheet({ data: { id: id }, file: selectedFile });
    };
    return (React.createElement(Table, null,
        React.createElement(THead, null,
            React.createElement(TR, null,
                React.createElement(TH, { contentStart: true },
                    React.createElement(THeadTypography, null, strings.tableHead.name)),
                React.createElement(TH, { contentStart: true },
                    React.createElement(THeadTypography, null, strings.tableHead.date)),
                React.createElement(TH, { contentStart: true },
                    React.createElement(THeadTypography, null, strings.tableHead.config)))),
        React.createElement(LoadingState, { data: !!((_a = props.spreadsheets) === null || _a === void 0 ? void 0 : _a.length) && !props.loading, loading: props.loading },
            React.createElement(LoadingState.Shimmer, null,
                React.createElement(TableShimmer, { length: (_b = props.spreadsheets) === null || _b === void 0 ? void 0 : _b.length })), (_c = props.spreadsheets) === null || _c === void 0 ? void 0 :
            _c.map(function (item, index) { return (React.createElement(SpreadsheetTableRow, { key: "spreadsheets-table-".concat(item.id), item: item, index: index, deleteLoading: deleteLoading, updateLoading: updateLoading, onClickDeleteButton: handleDeleteSpreadsheet, onFileSelect: handleFileSelect })); }))));
};
var TableShimmer = function (props) {
    var _a;
    return (React.createElement(React.Fragment, null, Array.from({ length: (_a = props === null || props === void 0 ? void 0 : props.length) !== null && _a !== void 0 ? _a : 3 }, function (_, index) { return index; }).map(function (_, index) { return (React.createElement(TR, { key: "shimmer-table-".concat(index) },
        React.createElement(TD, { contentStart: true },
            React.createElement(SpreadsheetSmallIcon, null),
            React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
            React.createElement(TextShimmerBoxStyled, { width: THUMB_SHIMMER_SIZE.width, height: THUMB_SHIMMER_SIZE.height })),
        React.createElement(TD, { contentStart: true },
            React.createElement(TextShimmerBoxStyled, { width: THUMB_SHIMMER_SIZE.width, height: THUMB_SHIMMER_SIZE.height })),
        React.createElement(TD, { contentStart: true },
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, null,
                    React.createElement(TButton, { variant: 'link', disabled: true }, strings.button.update)),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, { vAlign: 'center' },
                    React.createElement(TBodyTypography, null, "|")),
                React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                React.createElement(Hbox.Item, null,
                    React.createElement(TButton, { variant: 'link', alert: true, disabled: true }, strings.button.delete)))))); })));
};
var THUMB_SHIMMER_SIZE = {
    height: '14px',
    width: '50%',
};
