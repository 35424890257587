var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Border, Color, Shadow, Spacing } from '@atomic/obj.constants';
var ClickableCardCss = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var BaseCardCss = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  border-radius: ", ";\n  padding: ", ";\n  display: block;\n  ", ";\n  ", ";\n\n  ", ";\n  box-shadow: ", ";\n\n  ", "\n"], ["\n  position: relative;\n  border-radius: ", ";\n  padding: ", ";\n  display: block;\n  ", ";\n  ", ";\n\n  ", ";\n  box-shadow: ", ";\n\n  ", "\n"])), Border.SmallRadius, function (_a) {
    var noGutter = _a.noGutter;
    return (noGutter ? '0' : Spacing.Medium);
}, function (_a) {
    var border = _a.border;
    return border && "border: ".concat(Border.Color, " ").concat(Border.Width, " solid");
}, function (_a) {
    var allHeight = _a.allHeight;
    return allHeight && 'flex: 1';
}, function (_a) {
    var maxWidth = _a.maxWidth;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    ", "\n    ", "\n  "], ["\n    ", "\n    ", "\n  "])), "background-color: ".concat(Color.White, ";"), maxWidth && "max-width: ".concat(maxWidth, "px;"));
}, function (_a) {
    var shadow = _a.shadow;
    return (shadow ? shadow : Shadow.Small);
}, function (_a) {
    var center = _a.center;
    return center && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      align-items: center;\n    "], ["\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      align-items: center;\n    "])));
});
export var CardStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), BaseCardCss);
export var ClickableCardStyled = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), BaseCardCss, ClickableCardCss);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
