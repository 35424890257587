var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { H3 } from '@atomic/atm.typography';
import { Border, Color, Spacing } from '@atomic/obj.constants';
export var NavbarOptionStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 0 ", " ", " 0;\n  cursor: pointer;\n  padding: ", " ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border-radius: 0 ", " ", " 0;\n  cursor: pointer;\n  padding: ", " ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var selected = _a.selected;
    return (selected ? Color.AccessoryGreen : Color.PrimaryDark);
}, Border.RadiusLarge, Border.RadiusLarge, Spacing.XXSmall, Spacing.Small, Color.AccessoryGreen);
export var NavbarOptionText = styled(H3)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var selected = _a.selected;
    return !selected && "filter: brightness(0.7)";
});
var templateObject_1, templateObject_2;
