export var strings = {
    title: 'Esqueceu a senha?',
    subtitle: 'Para recuperar seu acesso, precisamos do seu e-mail',
    buttons: { sendCode: 'Enviar código', back: 'Voltar para o Login' },
    verifyCode: {
        title: 'Verifique a caixa de entrada do e-mail:',
        instruction: 'Você receberá um link para redefinir sua senha. Caso não receba o e-mail, verifique a caixa de spam ou o lixo eletrônico.',
    },
    formLabel: {
        email: 'Email',
    },
};
