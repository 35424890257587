export var agDashFiltersSectionStrings = {
    placeholder: {
        spreadsheet: 'Fonte:',
        latitudeAndLongitude: 'Latiude/Longitude:',
        culture: 'Cultura:',
        cycle: 'Ciclo:',
        sowing: 'Semeadura:',
    },
    filterBy: 'Filtrar por:',
    exportButton: 'Exportar',
};
