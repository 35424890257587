import * as React from 'react';
import { createPortal } from 'react-dom';
import { CloseIconStyled, ModalBoxStyled, ModalCloseStyled, ModalOpacityStyled, ModalStyled, } from './modal.component.style';
export var Modal = function (props) {
    return createPortal(React.createElement(ModalStyled, { opened: props.opened },
        React.createElement(ModalOpacityStyled, { opened: props.opened, onClick: props.onClose }),
        props.opened && (React.createElement(ModalBoxStyled, { small: props.small, noGutter: props.noGutter, maxWidth: props.maxWidth },
            React.createElement(ModalCloseStyled, { noGutter: props.noGutter },
                React.createElement(CloseIconStyled, { onClick: props.onClose })),
            props.children))), document.getElementById('modal'));
};
