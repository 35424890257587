var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { BodySecondary } from '@atomic/atm.typography';
import { Spacing, Border, ZIndex, Color } from '@atomic/obj.constants';
var CONTENT_WIDTH = 385;
var TOOLTIP_ICON_WIDTH = 24;
export var TooltipContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n  visibility: hidden;\n  min-width: ", "px;\n  border-radius: ", ";\n\n  position: absolute;\n  bottom: 100%;\n  ", ": 50%;\n  margin-", ": -", "px;\n  z-index: ", ";\n"], ["\n  background-color: ", ";\n  padding: ", ";\n  visibility: hidden;\n  min-width: ", "px;\n  border-radius: ", ";\n\n  position: absolute;\n  bottom: 100%;\n  ", ": 50%;\n  margin-", ": -", "px;\n  z-index: ", ";\n"])), Color.GrayXDark, Spacing.XXSmall, CONTENT_WIDTH, Border.SmallRadius, function (_a) {
    var right = _a.right;
    return (right ? 'right' : 'left');
}, function (_a) {
    var right = _a.right;
    return (right ? 'right' : 'left');
}, TOOLTIP_ICON_WIDTH / 2, ZIndex.top);
export var TooltipStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n\n  &:hover * {\n    visibility: visible;\n  }\n"], ["\n  display: inline-block;\n  position: relative;\n\n  &:hover * {\n    visibility: visible;\n  }\n"])));
export var Highlight = styled(BodySecondary)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline;\n  color: ", ";\n"], ["\n  display: inline;\n  color: ", ";\n"])), Color.CallToActionLight);
var templateObject_1, templateObject_2, templateObject_3;
