import { useQuery } from '@app/core/graphql';
export function useSpreadsheets(params) {
    var _a = useQuery('SpreadsheetsQuery', {}, {
        onCompleted: params === null || params === void 0 ? void 0 : params.onCompleted,
        onError: params === null || params === void 0 ? void 0 : params.onError,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        refetch: refetch,
        data: data,
        error: error,
        loading: loading,
    };
}
