import { useSyncExternalStore } from 'react';
import { Store } from './store';
export function createStore(initialValue) {
    var store = new Store(initialValue);
    var useHook = function () { return useStore(store); };
    return [store, useHook];
}
export function useStore(store) {
    var state = useSyncExternalStore(store.subscribe, store.getState);
    return [state, store.setState, store.remove];
}
