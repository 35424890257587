var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Spacing, Border, Color } from '@atomic/obj.constants';
var BOX_SHADOW = '1px 1px 30px 30px rgba(0, 0, 0, 0.02)';
export var InfoCardStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n"], ["\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n"])), function (_a) {
    var error = _a.error;
    return (error ? Color.AlertLight : Color.GrayXLight);
}, Spacing.XXSmall, Border.SmallRadius, BOX_SHADOW);
var templateObject_1;
