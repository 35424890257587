import React from 'react';
import { VideoPlayer } from '@app/components/atm.video-player';
import { useWhatsappUrlRedirect } from '@app/utils/whatsapp.redirect';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { strings } from './welcome-modal.strings';
export var WelcomeModal = function (props) {
    return (React.createElement(Modal, { opened: props.opened, onClose: props.onCloseModal, small: props.small },
        React.createElement(H2, null, strings.title),
        React.createElement(Body, null, strings.description),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(VideoPlayer, { src: strings.videoUrl, title: strings.aboutVideo }),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { vAlign: 'center' },
                React.createElement(Button, { expanded: true, variant: 'secondary', onClick: props.onCloseModal }, strings.buttons.watchLater)),
            React.createElement(Hbox.Separator, null),
            React.createElement(Hbox.Item, null,
                React.createElement("a", { href: useWhatsappUrlRedirect(), target: '_blank', rel: 'noreferrer' },
                    React.createElement(Button, { expanded: true, variant: 'primary', onClick: props.onCloseModal }, strings.buttons.contact))))));
};
