export var strings = {
    title: 'Alterar senha',
    captionWarning: 'A senha precisa 8 caracteres e 1 número.',
    submitButton: 'Salvar alterações',
    labels: {
        currentPassword: 'Senha atual',
        newPassword: 'Nova senha',
        confirmNewPassword: 'Confirmar nova senha',
    },
    validatorMessage: {
        confirmPassword: 'As senhas devem ter o mesmo valor.',
    },
};
