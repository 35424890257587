import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { CheckboxCheckedStyled, CheckboxFieldBulletStyled, CheckboxFieldBulletWrapperStyled, CheckboxFieldStyled, CheckboxFieldTextStyled, CheckboxUncheckedStyled, } from './checkbox-field.component.style';
export var CheckboxField = function (props) {
    var _a, _b, _c;
    var _d = React.useState((_b = (_a = props.initialChecked) !== null && _a !== void 0 ? _a : props.checked) !== null && _b !== void 0 ? _b : false), checked = _d[0], setChecked = _d[1];
    var formFieldConsumer = React.useContext(FormFieldContext);
    var name = (_c = formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.name) !== null && _c !== void 0 ? _c : '';
    var fieldId = name + '_' + props.id;
    var isControlled = React.useCallback(function () {
        return props.checked !== undefined;
    }, [props.checked]);
    React.useEffect(function () {
        if (isControlled() && props.initialChecked !== undefined) {
            throw new Error('Use either the initialChecked prop, or the checked prop, but not both');
        }
        if (formFieldConsumer) {
            if (isControlled() || props.initialChecked !== undefined) {
                throw new Error('Please, use value props in <Form.Field> instead of <CheckboxField> component.');
            }
        }
    }, [formFieldConsumer, isControlled, props.initialChecked]);
    var getCurrentValue = React.useCallback(function () {
        var _a, _b;
        if (formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value) {
            return ((_b = (_a = formFieldConsumer.value) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, props.id)) > -1;
        }
        return checked;
    }, [checked, formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value, props.id]);
    React.useEffect(function () {
        if (formFieldConsumer) {
            var value = getCurrentValue();
            setChecked(value);
        }
    }, [formFieldConsumer, getCurrentValue]);
    var handlePress = function () {
        var _a, _b;
        (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, props.id);
        var checkToggle = !getCurrentValue();
        (_b = props.onValueChange) === null || _b === void 0 ? void 0 : _b.call(props, props.id, checkToggle);
        if (isControlled()) {
            return;
        }
        formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.onValueChange([props.id], checkToggle);
        setChecked(checkToggle);
    };
    return (React.createElement(CheckboxFieldStyled, { disabled: props.disabled },
        React.createElement(CheckboxFieldBulletWrapperStyled, { onClick: handlePress },
            React.createElement(CheckboxFieldBulletStyled, { id: fieldId, name: fieldId, type: 'checkbox', checked: checked, onChange: handlePress, value: props.id }),
            React.createElement(CheckboxCheckedStyled, { "aria-hidden": 'true', disabled: props.disabled }),
            React.createElement(CheckboxUncheckedStyled, { "aria-hidden": 'true', disabled: props.disabled })),
        React.createElement(CheckboxFieldTextStyled, { htmlFor: fieldId }, props.children)));
};
