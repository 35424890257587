import React from 'react';
import { TooltipIcon } from '@assets/icons/tooltip.icon';
import { BodySecondary } from '@atomic/atm.typography';
import { TooltipStyled, TooltipContent, Highlight } from './tooltip.style';
export var Tooltip = function (_a) {
    var base = _a.base, highlighted = _a.highlighted, right = _a.right;
    return (React.createElement(TooltipStyled, null,
        React.createElement(TooltipIcon, null),
        React.createElement(TooltipContent, { right: right },
            React.createElement(BodySecondary, { color: 'White' },
                highlighted && React.createElement(Highlight, null, highlighted),
                base))));
};
