import React, { useState } from 'react';
import { useLogin } from '@app/domain';
import { AuthRoutes } from '@app/modules/auth/routes';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { Body, H1, InputLabel } from '@atomic/atm.typography';
import { FlashMessage } from '@atomic/mol.flash-message';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { LineStyled } from './login-form.component.style';
import { strings } from './login-form.strings';
export var LoginForm = function () {
    var _a = useLogin(), login = _a.login, loading = _a.loading, error = _a.error;
    var _b = useState(''), email = _b[0], setEmail = _b[1];
    var handleSubmit = function (formData) {
        if (formData.hasError) {
            return;
        }
        login({ data: formData.data });
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(H1, { center: true }, strings.title),
        React.createElement(Body, { center: true }, strings.subtitle),
        React.createElement(Separator, null),
        React.createElement(Form.Field, { name: Fields.email, label: strings.formlabel.email, onValueChange: function (value) { return setEmail(value); }, validators: [Validators.Required(), Validators.EmailRegex(strings.validatorMessage.email)] },
            React.createElement(InputLabel, null,
                React.createElement(TextField, { inputLabel: true, type: 'email' }))),
        React.createElement(Form.Field, { name: Fields.password, label: strings.formlabel.password, validators: [Validators.Required()] },
            React.createElement(InputLabel, null,
                React.createElement(TextField, { inputLabel: true, type: 'password', hasIconEnd: true }))),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { hAlign: 'flex-end' },
                React.createElement(Button, { variant: 'link', to: "".concat(AuthRoutes.forgotPassword, "?email=").concat(encodeURIComponent(email)) }, strings.buttons.forgotPassword))),
        React.createElement(Separator, null),
        !!error && (React.createElement(FlashMessage, { type: 'alert', dismissible: true, mb: true }, error.message)),
        React.createElement(Button, { variant: 'primary', type: 'submit', expanded: true, loading: loading }, strings.buttons.signIn),
        React.createElement(Separator, { size: Spacing.XSmall }),
        React.createElement(Divider, null),
        React.createElement(Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(Body, { color: 'GrayXDark' }, strings.signUpPrefix)),
            React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
            React.createElement(Hbox.Item, null,
                React.createElement(Button, { variant: 'link', to: AuthRoutes.signup }, strings.buttons.signUp)))));
};
var Fields = {
    email: 'email',
    password: 'password',
};
var Divider = function () { return (React.createElement(Hbox, null,
    React.createElement(Hbox.Item, { vAlign: 'center' },
        React.createElement(LineStyled, null)),
    React.createElement(Hbox.Item, { noGrow: true },
        React.createElement(Body, null, strings.dividerConnective)),
    React.createElement(Hbox.Item, { vAlign: 'center' },
        React.createElement(LineStyled, null)))); };
