var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { InfoCard } from '@app/components/atm.info-card';
import { useSpreadsheet, useSpreadsheets } from '@app/domain';
import { FileExportIcon } from '@assets/icons/file-export';
import { Button } from '@atomic/atm.button';
import { ControlledSelect } from '@atomic/atm.controlled-select';
import { DatePicker } from '@atomic/atm.date-picker';
import { Body } from '@atomic/atm.typography';
import { Fade } from '@atomic/obj.animation/animation.component.style';
import { Hbox, Separator } from '@atomic/obj.box';
import { ControlSizes, Spacing } from '@atomic/obj.constants';
import { agDashFiltersSectionStrings as strings } from './ag-dash-filters-section.strings';
export var AgDashFiltersSection = function (props) {
    var _a = React.useState(), selectedSpreadsheetId = _a[0], setSelectedSpreadsheetId = _a[1];
    var _b = React.useState(), selectedCoordinateId = _b[0], setSelectedCoordinatesId = _b[1];
    var _c = React.useState(), selectedCulture = _c[0], setSelectedCulture = _c[1];
    var _d = React.useState(), selectedCycle = _d[0], setSelectedCycle = _d[1];
    var _e = useSpreadsheets(), spreadsheetsData = _e.data, spreadsheetsLoading = _e.loading;
    var _f = useSpreadsheet(), getSpreadsheet = _f[0], _g = _f[1], spreadsheetData = _g.data, spreadsheetLoading = _g.loading;
    var handleSpreadsheetIdChange = function (value) {
        setSelectedCoordinatesId(undefined);
        setSelectedCulture(undefined);
        setSelectedCycle(undefined);
        setSelectedSpreadsheetId(value.toString());
    };
    var handleCoordinateIdChange = function (value) {
        var coordinateId = value.toString();
        setSelectedCulture(undefined);
        setSelectedCycle(undefined);
        setSelectedCoordinatesId(coordinateId);
        var latitude = parseFloat(spreadsheetData.spreadsheet.spreadsheetInfos.find(function (spreadsheet) { return spreadsheet.coordinateId === coordinateId; })
            .latitude);
        var longitude = parseFloat(spreadsheetData.spreadsheet.spreadsheetInfos.find(function (spreadsheet) { return spreadsheet.coordinateId === coordinateId; })
            .longitude);
        props.onFiltersHandleChange(function () {
            return { latitude: latitude, longitude: longitude };
        });
    };
    var handleCultureChange = function (value) {
        setSelectedCycle(undefined);
        setSelectedCulture(value);
        props.onFiltersHandleChange(function (prev) {
            return {
                latitude: prev.latitude,
                longitude: prev.longitude,
                idCulture: value,
            };
        });
    };
    var handleCycleChange = function (value) {
        setSelectedCycle(value);
        props.onFiltersHandleChange(function (prev) {
            return {
                latitude: prev.latitude,
                longitude: prev.longitude,
                idCulture: prev.idCulture,
                idCycle: value,
            };
        });
    };
    var handleSowingDateChange = function (date) {
        var day = date.getUTCDate();
        var month = date.toLocaleString('pt-BR', { month: 'short' }).toLowerCase().replace('.', '');
        props.onFiltersHandleChange(function (prev) {
            return __assign(__assign({}, prev), { sowingDate: "".concat(day, "-").concat(month) });
        });
        props.onGetData();
    };
    React.useEffect(function () {
        if (selectedSpreadsheetId) {
            getSpreadsheet({ data: { id: selectedSpreadsheetId } });
        }
    }, [getSpreadsheet, selectedSpreadsheetId, spreadsheetData]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Hbox, { "$wrap": true },
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(ControlledSelect, { options: spreadsheetsData && mapSpreadsheets(spreadsheetsData.spreadsheets), loading: spreadsheetsLoading, placeholder: strings.placeholder.spreadsheet, name: 'spreadsheet', onValueChange: handleSpreadsheetIdChange }),
                React.createElement(Separator, null)),
            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(ControlledSelect, { options: spreadsheetData && mapSpreadsheetInfo(spreadsheetData.spreadsheet.spreadsheetInfos), disabled: !selectedSpreadsheetId, placeholder: strings.placeholder.latitudeAndLongitude, name: 'latitude-and-longitude', onValueChange: handleCoordinateIdChange, key: "ag-dash-coordinate-id-".concat(selectedSpreadsheetId), controlSize: ControlSizes.Large, loading: spreadsheetLoading }),
                React.createElement(Separator, null))),
        React.createElement(Hbox, { "$wrap": true },
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(Body, null, strings.filterBy),
                React.createElement(Separator, null)),
            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(ControlledSelect, { options: cultureOptions, disabled: !selectedCoordinateId, placeholder: strings.placeholder.culture, name: 'culture', onValueChange: handleCultureChange, key: "ag-dash-culture-".concat(selectedCoordinateId) }),
                React.createElement(Separator, null)),
            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(ControlledSelect, { options: selectedCulture && mapCycleOptions(selectedCulture), disabled: !selectedCoordinateId || !selectedCulture, placeholder: strings.placeholder.cycle, name: 'cycle', onValueChange: handleCycleChange, key: "cycle-select-".concat(selectedCulture) }),
                React.createElement(Separator, null)),
            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(DatePicker, { placeholder: strings.placeholder.sowing, disabled: !selectedCycle, name: 'sowing-date', onValueChange: handleSowingDateChange, key: "cycle-select-".concat(selectedCycle) }),
                React.createElement(Separator, null)),
            React.createElement(Hbox.Item, { hAlign: 'flex-end' },
                React.createElement(Button, { disabled: props.disableExport, variant: 'primary', onClick: function () { return props.onExportPDF(true); }, loading: props.exportLoading },
                    React.createElement(Hbox, null,
                        React.createElement(Hbox.Item, { vAlign: 'center' },
                            React.createElement(FileExportIcon, null)),
                        React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                        React.createElement(Hbox.Item, { vAlign: 'center' }, strings.exportButton))))),
        !!props.errorMessage && (React.createElement(Fade, { show: true },
            React.createElement(InfoCard, { content: props.errorMessage, error: true })))));
};
function mapSpreadsheets(data) {
    return data.map(function (spreadsheet) {
        return {
            value: spreadsheet.id,
            label: spreadsheet.name,
        };
    });
}
function mapSpreadsheetInfo(data) {
    return data.map(function (spreadsheetInfo) {
        return {
            value: spreadsheetInfo.coordinateId,
            label: spreadsheetInfo.coordinateId,
        };
    });
}
var cultureOptions = [
    { label: 'Soja', value: 1 },
    { label: 'Milho', value: 2 },
    { label: 'Milho Safrinha', value: 3 },
];
function mapCycleOptions(culture) {
    switch (culture) {
        case 1:
            return [
                { label: '110 dias', value: 1 },
                { label: '120 dias', value: 2 },
                { label: '130 dias', value: 3 },
            ];
        case 2:
        case 3:
            return [
                { label: '100 dias', value: 1 },
                { label: '120 dias', value: 2 },
                { label: '140 dias', value: 3 },
            ];
    }
}
