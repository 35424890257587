import React from 'react';
import Container from 'typedi';
import { InfoCardStyled } from '@app/components/atm.info-card/info-card.styled';
import { UploadSpreadsheetDropzone } from '@app/components/atm.upload-spreadsheet-dropzone';
import { SPREADSHEET_TEMPLATE_GCLOUD_URL } from '@app/configure-app';
import { useUploadSpreadsheet } from '@app/domain';
import { TooltipBoldIcon } from '@assets/icons/tooltip-bold.icon';
import { UploadFileIcon } from '@assets/icons/upload-file.icon';
import { UploadInvalidIcon } from '@assets/icons/upload-invalid.icon';
import { UploadedFileIcon } from '@assets/icons/uploaded-file.icon';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H2, H4, LinkMedium } from '@atomic/atm.typography';
import { Fade } from '@atomic/obj.animation/animation.component.style';
import { Hbox, Separator } from '@atomic/obj.box';
import { Shadow, Spacing } from '@atomic/obj.constants';
import { strings } from './upload-spreadsheet-section.strings';
import { DropdownWrapper, InfoCardSeparator, UploadCardStateWrapper } from './upload-spreadsheet-section.style';
export var UploadSpreadsheetSection = function (props) {
    var _a = React.useState(), selectedFile = _a[0], setSelectedFile = _a[1];
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    var _c = React.useState(false), hasError = _c[0], setHasError = _c[1];
    var _d = useUploadSpreadsheet({
        onCompleted: function () {
            props.onRefetch();
        },
        onError: function () { return setHasError(true); },
    }), uploadSpreadsheet = _d.uploadSpreadsheet, loading = _d.loading, error = _d.error;
    var closeInfoCard = function () {
        setSelectedFile(undefined);
        setHasError(false);
        setVisible(false);
    };
    var handleFileUpload = function (file) {
        setSelectedFile(file);
        setVisible(true);
        uploadSpreadsheet({ data: file });
        setTimeout(closeInfoCard, 10000);
    };
    var spreadsheetTemplateUrl = Container.get(SPREADSHEET_TEMPLATE_GCLOUD_URL);
    return (React.createElement(React.Fragment, null,
        React.createElement(H2, null, strings.title),
        React.createElement(Separator, { size: Spacing.XSmall }),
        React.createElement(DropdownWrapper, { shadow: Shadow.Medium, noGutter: true },
            React.createElement(UploadSpreadsheetDropzone, { onFileUpload: handleFileUpload, uploadedFile: selectedFile, large: true, hasError: hasError, loading: loading }),
            React.createElement(Separator, { size: Spacing.XSmall }),
            !visible && React.createElement(SpreadsheetInfoCard, null),
            React.createElement(Fade, { show: visible },
                React.createElement(UploadCardStateWrapper, { show: visible, hasError: !!error },
                    React.createElement(Hbox, null,
                        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' }, !!error ? React.createElement(UploadInvalidIcon, null) : loading ? React.createElement(UploadFileIcon, null) : React.createElement(UploadedFileIcon, null)),
                        React.createElement(Hbox.Separator, { size: Spacing.XXSmall }),
                        React.createElement(Hbox.Item, { vAlign: 'center' },
                            React.createElement(H4, { noMargin: true }, !!error ? error.message : loading ? strings.loadingTitle : strings.uploaded),
                            React.createElement(Hbox, null,
                                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                                    React.createElement(Body, null, !!error ? strings.descriptionInvalid : selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.name)),
                                !!error && (React.createElement(React.Fragment, null,
                                    React.createElement(InfoCardSeparator, null),
                                    React.createElement(Hbox.Item, { vAlign: 'center', noGrow: true },
                                        React.createElement(LinkMedium, { href: spreadsheetTemplateUrl }, strings.spreadsheetTemplateLink)),
                                    React.createElement(Hbox.Item, { vAlign: 'flex-end', noGrow: true },
                                        React.createElement(Body, null, strings.infoCard.exclamation)))))),
                        React.createElement(Hbox.Item, { hAlign: 'flex-end', noGrow: true },
                            React.createElement(FaIcon.Close, { onClick: closeInfoCard })))))),
        React.createElement(Separator, { size: Spacing.XSmall })));
};
var SpreadsheetInfoCard = function () {
    var spreadsheetTemplateUrl = Container.get(SPREADSHEET_TEMPLATE_GCLOUD_URL);
    return (React.createElement(InfoCardStyled, null,
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(TooltipBoldIcon, null)),
            React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center', hAlign: 'center' },
                React.createElement(Body, null, strings.infoCard.base)),
            React.createElement(InfoCardSeparator, null),
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center', hAlign: 'center' },
                React.createElement(LinkMedium, { href: spreadsheetTemplateUrl }, strings.spreadsheetTemplateLink)),
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'flex-end', hAlign: 'center' },
                React.createElement(Body, null, strings.infoCard.exclamation)))));
};
