import React from 'react';
import { InfoCard } from '@app/components/atm.info-card';
import { PaginationComponent } from '@atomic/atm.pagination';
import { Table, THead, TR, TD, TH, THeadTypography, TBodyTypography } from '@atomic/mol.table';
import { TableSeparator } from '@atomic/mol.table/table.component.style';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { bigDataTableStrings as strings } from './big-data-table.strings';
var SIBLING_COUNT = 3;
export var BigDataTable = function (_a) {
    var data = _a.data;
    var _b = React.useState(1), page = _b[0], setPage = _b[1];
    var paginatedData = paginateBigData(data);
    var mappedBigDataResult = React.useMemo(function () { return mapBigDataResult(paginatedData[page - 1]); }, [paginatedData, page]);
    var mappedHeader = strings.headers.map(function (title, index) { return (React.createElement(TH, { key: "header-title-".concat(index) },
        React.createElement(THeadTypography, null, title))); });
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, null,
            React.createElement(THead, null,
                React.createElement(TR, null, mappedHeader)),
            mappedBigDataResult),
        React.createElement(TableSeparator, { size: Spacing.XXSmall }),
        React.createElement(Separator, { size: Spacing.XSmall }),
        React.createElement(PaginationComponent, { current: page, onPageChange: setPage, siblingCount: SIBLING_COUNT, total: paginatedData.length }),
        React.createElement(Separator, { size: Spacing.XSmall }),
        React.createElement(InfoCard, { content: strings.infocard })));
};
function mapBigDataResult(data) {
    var decimalDigits = 2;
    return data === null || data === void 0 ? void 0 : data.map(function (row, index) {
        var isOdd = index % 2 !== 0;
        return (React.createElement(React.Fragment, { key: "table-body-row-".concat(index) },
            React.createElement(TR, { isGrey: isOdd },
                React.createElement(TD, null,
                    React.createElement(TBodyTypography, null, row.date)),
                React.createElement(TD, null,
                    React.createElement(TBodyTypography, null, row.temperatureMax.toFixed(decimalDigits))),
                React.createElement(TD, null,
                    React.createElement(TBodyTypography, null, row.temperatureMed.toFixed(decimalDigits))),
                React.createElement(TD, null,
                    React.createElement(TBodyTypography, null, row.temperatureMin.toFixed(decimalDigits))),
                React.createElement(TD, null,
                    React.createElement(TBodyTypography, null, row.par.toFixed(decimalDigits))),
                React.createElement(TD, null,
                    React.createElement(TBodyTypography, null, row.relativeHumity.toFixed(decimalDigits))),
                React.createElement(TD, null,
                    React.createElement(TBodyTypography, null, row.windVelocity.toFixed(decimalDigits))),
                React.createElement(TD, null,
                    React.createElement(TBodyTypography, null, row.accumulatedPrecipitation.toFixed(decimalDigits))))));
    });
}
var THUMB_SHIMMER_SIZE = {
    height: '14px',
    width: '50%',
};
function paginateBigData(data, limit) {
    if (limit === void 0) { limit = 10; }
    var result = [];
    for (var i = 0; i < data.length; i = i + limit) {
        result.push(data.slice(i, i + limit));
    }
    return result;
}
